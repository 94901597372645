import {
  COACHING_MODULE_PAGE,
  COACHING_PAGE,
  CLIPS_MODULE_PAGE,
  CLIPS_PAGE,
  EXPERT_TALKS_PAGE,
  HOME_PAGE,
  TRAINING_PAGE,
} from './routes';

export const VIDEO_TAB_OVERVIEW = 'overview';
export const VIDEO_TAB_DESCRIPTION = 'description';
export const VIDEO_TAB_COURSE_OVERVIEW = 'courseOverview';
export const VIDEO_TAB_DOWNLOADS = 'downloads';
export const VIDEO_TAB_NOTES = 'notes';
export const VIDEO_TAB_FAQ = 'faq';
export const VIDEO_TAB_MATERIAL = 'material';

export const VIDEO_TABS = [VIDEO_TAB_OVERVIEW, VIDEO_TAB_DOWNLOADS, VIDEO_TAB_NOTES, VIDEO_TAB_FAQ];
export const VIDEO_TABS_ZOOM = [VIDEO_TAB_OVERVIEW, VIDEO_TAB_DOWNLOADS, VIDEO_TAB_FAQ];
export const COACHING_LESSON_TABS = [VIDEO_TAB_DESCRIPTION, VIDEO_TAB_NOTES, VIDEO_TAB_MATERIAL];
export const COACHING_MODULE_TABS = [VIDEO_TAB_COURSE_OVERVIEW, VIDEO_TAB_NOTES, VIDEO_TAB_MATERIAL];

export const VIDEO_TABS_NOTES_DEFAULT_VALUES = {
  note: '',
};

export const VIDEO_TYPE_VIMEO_LIVE = 'vimeo_live';

export const VIDEO_COACHING_MODULE_TRANSLATION = 'video.coaching.details.page.breadcrumbs.oncoCoach';
export const VIDEO_COACHING_LESSON_TRANSLATION = 'video.coaching.details.page.breadcrumbs.module';
export const VIDEO_CLIPS_MODULE_TRANSLATION = 'video.clips.details.page.breadcrumbs.oncoCoach';
export const VIDEO_CLIPS_LESSON_TRANSLATION = 'video.clips.details.page.breadcrumbs.module';

export const COACHING_BREADCRUMBS = [
  { translation: 'video.coaching.details.page.breadcrumbs.homepage', url: HOME_PAGE },
  { translation: 'video.coaching.details.page.breadcrumbs.coaching', url: COACHING_PAGE },
  { translation: VIDEO_COACHING_MODULE_TRANSLATION, url: COACHING_MODULE_PAGE },
];

export const COACHING_OVERVIEW_BREADCRUMBS = [
  { translation: 'video.coaching.details.page.breadcrumbs.homepage', url: HOME_PAGE },
  { translation: 'video.coaching.details.page.breadcrumbs.coaching', url: COACHING_PAGE },
];

export const COACHING_LESSONS_BREADCRUMBS = [
  ...COACHING_BREADCRUMBS,
  { translation: VIDEO_COACHING_LESSON_TRANSLATION, url: '' },
];

export const CLIPS_BREADCRUMBS = [
  { translation: 'video.clips.details.page.breadcrumbs.homepage', url: HOME_PAGE },
  { translation: 'video.clips.details.page.breadcrumbs.clips', url: CLIPS_PAGE },
  { translation: VIDEO_CLIPS_MODULE_TRANSLATION, url: CLIPS_MODULE_PAGE },
];

export const CLIPS_OVERVIEW_BREADCRUMBS = [
  { translation: 'video.clips.details.page.breadcrumbs.homepage', url: HOME_PAGE },
  { translation: 'video.clips.details.page.breadcrumbs.clips', url: CLIPS_PAGE },
];

export const CLIPS_LESSONS_BREADCRUMBS = [
  ...CLIPS_BREADCRUMBS,
  { translation: VIDEO_CLIPS_LESSON_TRANSLATION, url: '' },
];

export const TRAINING_OVERVIEW_BREADCRUMBS = [
  { translation: 'video.coaching.details.page.breadcrumbs.homepage', url: HOME_PAGE },
  { translation: 'video.coaching.details.page.breadcrumbs.training', url: TRAINING_PAGE },
];

export const TALKS_OVERVIEW_BREADCRUMBS = [
  { translation: 'video.coaching.details.page.breadcrumbs.homepage', url: HOME_PAGE },
  { translation: 'video.coaching.details.page.breadcrumbs.talks', url: EXPERT_TALKS_PAGE },
];
