import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { VIDEO_TAB_COURSE_OVERVIEW, VIDEO_TAB_DESCRIPTION } from '../constants/video';

function useCoachingVideoTabs(isLesson) {
  const [selectedIndex, setSelectedIndex] = useState(isLesson ? VIDEO_TAB_DESCRIPTION : VIDEO_TAB_COURSE_OVERVIEW);
  const { video } = useSelector((store) => store.defaultReducer);

  return { video, selectedIndex, setSelectedIndex };
}

export default useCoachingVideoTabs;
