import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useProfileDropdown from '../hooks/useProfileDropdown';
import { PROFILE_ITEM_TYPES } from '../constants/profile';
import style from '../assets/scss/components/RegisterToEventSuccess.module.scss';

function RegisterToEventSuccess(props) {
  const { t } = useTranslation();
  const { handleRedirect } = useProfileDropdown();
  const { wrapper, link, schulungTitle } = convertKeysToCamelCase(style);

  const { title } = props;

  return (
    <div className={wrapper}>
      <h2>{t('training.page.courseDates.date.success.page.title')}</h2>
      <p>
        <span>{t('training.page.courseDates.date.success.page.description.text1')}</span>
        <span className={schulungTitle}>{title}</span>
        <span>{t('training.page.courseDates.date.success.page.description.text2')}</span>
      </p>
      <span>
        <button onClick={() => handleRedirect(PROFILE_ITEM_TYPES.myCourse)} className="primary">
          {t('training.page.courseDates.date.success.page.button')}
        </button>
      </span>
    </div>
  );
}

export default RegisterToEventSuccess;
