export const paymentPersonalInformationMapper = (data) => {
  const {
    personalFirstName,
    personalLastName,
    personalSubject,
    personalEmailAddress,
    personalEFNNumber,
    personalPhoneNumber,
    personalStreet,
    personalPostalCode,
    personalCity,
  } = data;
  return {
    personal_first_name: personalFirstName,
    personal_last_name: personalLastName,
    personal_subject: personalSubject,
    personal_email_address: personalEmailAddress,
    personal_EFN_number: personalEFNNumber,
    personal_phone_number: personalPhoneNumber,
    personal_street: personalStreet,
    personal_postal_code: personalPostalCode,
    personal_city: personalCity,
  };
};

export const paymentBillingInformationMapper = (data) => {
  const {
    billingFirstName,
    billingLastName,
    billingSubject,
    billingEFNNumber,
    billingPhoneNumber,
    billingStreet,
    billingPostalCode,
    billingCity,
    billingEmailAddress,
  } = data;
  return {
    billing_first_name: billingFirstName,
    billing_last_name: billingLastName,
    billing_subject: billingSubject,
    billing_EFN_number: billingEFNNumber,
    billing_phone_number: billingPhoneNumber,
    billing_street: billingStreet,
    billing_postal_code: billingPostalCode,
    billing_city: billingCity,
    billing_email_address: billingEmailAddress,
  };
};

export const personalInformationToBillingInformationMapper = (data) => {
  const {
    personalFirstName,
    personalLastName,
    personalSubject,
    personalEmailAddress,
    personalEFNNumber,
    personalPhoneNumber,
    personalStreet,
    personalPostalCode,
    personalCity,
  } = data;
  return {
    billingFirstName: personalFirstName,
    billingLastName: personalLastName,
    billingSubject: personalSubject,
    billingEFNNumber: personalEFNNumber,
    billingPhoneNumber: personalPhoneNumber,
    billingStreet: personalStreet,
    billingPostalCode: personalPostalCode,
    billingCity: personalCity,
    billingEmailAddress: personalEmailAddress,
  };
};
