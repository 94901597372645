import React from 'react';
import { useDispatch } from 'react-redux';
import fileDownload from 'js-file-download';

import { SET_IS_LOADING, SET_STATE } from '../constants/actionTypes';
import { downloadVideoFileApi } from '../api/videosApi';
import useGeneral from './useGeneral';

function useVideoTabDownloads() {
  const dispatch = useDispatch();
  const { showToasts } = useGeneral();

  async function handleDownload(hash, fileName) {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data, headers } = await downloadVideoFileApi(hash);

      fileDownload(data, fileName, headers['content-type']);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({
        type: SET_STATE,
        payload: { isLoginModalOpen: false, isBurgerMenuOpen: false, isLogoutModalOpen: false, isLoading: false },
      });
    }
  }

  return { handleDownload };
}

export default useVideoTabDownloads;
