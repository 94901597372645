import { useState, useEffect } from 'react';
import { socialMediaApi } from 'api/contactApi';

import useGeneral from './useGeneral';

const useSocialLinks = () => {
  const [socialLinks, setSocialLinks] = useState([]);
  const { showToasts } = useGeneral();

  useEffect(() => {
    getSocialLinks();
  }, []);

  async function getSocialLinks() {
    try {
      const { data } = await socialMediaApi();
      setSocialLinks(data);
    } catch (error) {
      showToasts(error);
    }
  }

  return {
    socialLinks,
  };
};

export default useSocialLinks;
