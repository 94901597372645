export const MODAL_STYLE = {
  content: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    width: '100%',
    height: '100vh',
    padding: '0',
  },
  overlay: {
    zIndex: '100',
  },
};

export const MODAL_STYLE_BLURY = {
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '620px',
    maxWidth: '100%',
    maxHeight: '100%',
    height: '390px',
    padding: '0px',
    borderRadius: '3px',
    overflow: 'hidden',
  },
  overlay: {
    backdropFilter: 'blur(5px) brightness(70%)',
    zIndex: '2000',
    background: 'none',
  },
};

export const MODAL_STYLE_IMAGE = {
  content: {
    position: 'absolute',
    left: '5%',
    right: '5%',
    width: 'auto',
    height: 'auto',
    padding: '0px',
    borderRadius: '3px',
    background: 'transparent',
    border: '0px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
  },
  overlay: {
    backdropFilter: 'blur(5px) brightness(70%)',
    zIndex: '2000',
    background: 'none',
  },
};
