import React from 'react';
import { useTranslation } from 'react-i18next';
import * as ReactModal from 'react-modal';

import { MODAL_STYLE_IMAGE } from '../constants/modal';
import style from '../assets/scss/components/QuizQuestion.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import fullScreenArrows from '../assets/images/fullScreenArrows.svg';

function QuizQuestion(props) {
  const { t } = useTranslation();
  const { isSelectingAnswer, currentQuestion, lastQuestion, isModalOpen, toggleModal } = props;
  const { mainTitle, secondaryTitle, small, image, arrows, imageContainer } = convertKeysToCamelCase(style);
  const question = isSelectingAnswer ? currentQuestion : lastQuestion;

  return (
    <div>
      <h3 className={mainTitle}>
        {t('quizzes.mainQuestion')}
        {question?.question}
      </h3>
      {question?.image && (
        <div className={imageContainer}>
          <img
            src={question?.image}
            className={image}
            onClick={() => {
              toggleModal();
            }}
          />
          <img src={fullScreenArrows} className={arrows} />
          <ReactModal
            isOpen={isModalOpen}
            style={MODAL_STYLE_IMAGE}
            onRequestClose={() => {
              toggleModal();
            }}
          >
            <img src={question?.image} onClick={toggleModal} />
          </ReactModal>
        </div>
      )}
      {question?.altQuestion && (
        <p className={secondaryTitle}>
          {t('quizzes.secondaryQuestion')} {question?.altQuestion}
        </p>
      )}
      <p className={small}>
        {currentQuestion?.type === 'single_select' ? t('quizzes.selectOneAnswer') : t('quizzes.selectMultipleAnswers')}
      </p>
    </div>
  );
}

export default QuizQuestion;
