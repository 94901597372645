import ConfirmTerminateBooking from 'components/ConfirmTerminateBooking';
import useTerminateBooking from 'hooks/useTerminateBooking';
import React from 'react';

import MyProfile from '../MyProfile';

function ProfilePage() {
  const { terminateBookingData } = useTerminateBooking();

  return (
    <main className="main">
      <section>
        <MyProfile />
        {terminateBookingData && (
          <ConfirmTerminateBooking isModalOpen={terminateBookingData.isOpen} onClose={() => {}} />
        )}
      </section>
    </main>
  );
}

export default ProfilePage;
