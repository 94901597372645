import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { sendPage } from 'helpers/googleAnalytics';

function useGoogleStatistics() {
  const location = useLocation();

  useEffect(() => {
    sendPage(location.pathname);
  }, [location]);
}

export default useGoogleStatistics;
