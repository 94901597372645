import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getCompanyApi } from 'api/partners';
import { SET_IS_LOADING } from 'constants/actionTypes';
import useGeneral from './useGeneral';

function useCompany(partnerId) {
  const [company, setCompany] = useState();
  const { showToasts } = useGeneral();
  const dispatch = useDispatch();

  useEffect(() => {
    getCompany();
  }, [partnerId]);

  async function getCompany() {
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const { data } = await getCompanyApi(partnerId);
      setCompany(data);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  return { company };
}

export default useCompany;
