import React from 'react';
import * as yup from 'yup';

export function getRegisterValidationSchema() {
  const schemaObj = {
    institute: yup.string('string').required('required').max(255, 'maxLength').min(3, 'minLengthInstitute'),
    firstName: yup.string('string').required('required').max(255, 'maxLength'),
    lastName: yup.string('string').required('required').max(255, 'maxLength'),
    postalCode: yup.string('string').required('required').max(255, 'maxLength'),
    city: yup.string('string').required('required').max(255, 'maxLength'),
    gender: yup
      .array()
      .required('required')
      .test('length', 'required', (items) => items.length),
    password: yup
      .string('string')
      .required('required')
      .min(6, 'minLength')
      .matches(/[a-zA-Z]/, 'letters')
      .max(255, 'maxLength'),
    email: yup.string('string').required('required').email('email').max(255, 'maxLength'),
    passwordConfirmation: yup.string('string').oneOf([yup.ref('password'), null], 'does.not.match'),
    newsletter: yup.bool().required('required'),
    coachingCode: yup.string('string').max(255, 'maxLength'),
  };

  return yup.object().shape(schemaObj);
}

export function getResetPasswordValidationSchema() {
  const schemaObj = {
    email: yup.string('string').required('required').email('email').max(255, 'maxLength'),
    password: yup
      .string('string')
      .required('required')
      .min(6, 'minLength')
      .matches(/[a-zA-Z]/, 'letters')
      .max(255, 'maxLength'),
    passwordConfirmation: yup.string('string').oneOf([yup.ref('password'), null], 'does.not.match'),
  };

  return yup.object().shape(schemaObj);
}

export function getEmailValidationSchema() {
  const schemaObj = {
    email: yup.string('string').required('required').email('email').max(255, 'maxLength'),
  };

  return yup.object().shape(schemaObj);
}

export function getNotesValidationSchema() {
  const schemaObj = {
    note: yup.string('string').required('required').max(255, 'maxLength'),
  };

  return yup.object().shape(schemaObj);
}

export function getSignInValidationSchema(forgot) {
  let schemaObj = {
    email: yup.string('string').required('required').email('email'),
  };

  if (!forgot) {
    schemaObj = {
      ...schemaObj,
      password: yup
        .string('string')
        .required('required')
        .matches(/[a-zA-Z]/, 'letters'),
    };
  }

  return yup.object().shape(schemaObj);
}

export function getDateScheduleSchema() {
  let schemaObj = {
    code: yup.string('string').required('required'),
    date: yup.string('string').required('required'),
  };

  return yup.object().shape(schemaObj);
}

export function getAskExpertValidationSchema() {
  let schemaObj = {
    question: yup.string('string').required('required').max(255, 'maxLength'),
  };

  return yup.object().shape(schemaObj);
}

export function getContactFormValidationSchema() {
  const schemaObj = {
    firstName: yup.string('string').required('required').max(255, 'maxLength'),
    lastName: yup.string('string').required('required').max(255, 'maxLength'),
    email: yup.string('string').required('required').email('email').max(255, 'maxLength'),
    message: yup.string('string').required('required').max(255, 'maxLength'),
  };

  return yup.object().shape(schemaObj);
}

export function getProfileFormValidationSchema() {
  const schemaObj = {
    firstName: yup.string('string').required('required').max(255, 'maxLength'),
    lastName: yup.string('string').required('required').max(255, 'maxLength'),
    address: yup.string('string').required('required').max(255, 'maxLength'),
    postalCode: yup.string('string').required('required').max(255, 'maxLength'),
    city: yup.string('string').required('required').max(255, 'maxLength'),
  };

  return yup.object().shape(schemaObj);
}

export function getCredentialsFormValidationSchema() {
  const schemaObj = {
    currentPassword: yup
      .string('string')
      .required('required')
      .matches(/[a-zA-Z]/, 'letters')
      .min(6, 'min6'),
    email: yup.string('string').required('required').email('email').max(255, 'maxLength'),
  };

  return yup.object().shape(schemaObj);
}

export function getPaymentProfileFormValidationSchema() {
  const schemaObj = {
    personalFirstName: yup
      .string('string')
      .max(255, 'maxLength')
      .ensure()
      .when('moreInformation', {
        is: '',
        then: yup.string('string').required('required'),
      }),
    personalLastName: yup
      .string('string')
      .max(255, 'maxLength')
      .when('moreInformation', {
        is: '',
        then: yup.string('string').required('required'),
      }),
    personalSubject: yup
      .string('string')
      .max(255, 'maxLength')
      .when('moreInformation', {
        is: '',
        then: yup.string('string').required('required'),
      }),
    personalEFNNumber: yup.string('string').max(255, 'maxLength'),
    personalEmailAddress: yup
      .string('string')
      .email('email')
      .max(255, 'maxLength')
      .when('moreInformation', {
        is: '',
        then: yup.string('string').required('required'),
      }),
    personalPhoneNumber: yup.string('string').max(255, 'maxLength'),
    personalStreet: yup
      .string('string')
      .max(255, 'maxLength')
      .when('moreInformation', {
        is: '',
        then: yup.string('string').required('required'),
      }),
    personalPostalCode: yup
      .string('string')
      .max(255, 'maxLength')
      .when('moreInformation', {
        is: '',
        then: yup.string('string').required('required'),
      }),
    personalCity: yup
      .string('string')
      .max(255, 'maxLength')
      .when('moreInformation', {
        is: '',
        then: yup.string('string').required('required'),
      }),
    moreInformation: yup.string('string').max(255, 'maxLength'),
  };
  return yup.object().shape(schemaObj);
}

export function getPaymentBillingFormValidationSchema(skipValidation) {
  if (skipValidation) {
    return yup.object().shape({});
  }
  const schemaObj = {
    billingFirstName: yup.string('string').required('required').max(255, 'maxLength'),
    billingLastName: yup.string('string').required('required').max(255, 'maxLength'),
    billingSubject: yup.string('string').required('required').max(255, 'maxLength'),
    billingEFNNumber: yup.string('string').max(255, 'maxLength'),
    billingEmailAddress: yup.string('string').required('required').email('email').max(255, 'maxLength'),
    billingPhoneNumber: yup.string('string').required('required').max(255, 'maxLength'),
    billingStreet: yup.string('string').required('required').max(255, 'maxLength'),
    billingPostalCode: yup.string('string').required('required').max(255, 'maxLength'),
    billingCity: yup.string('string').required('required').max(255, 'maxLength'),
  };
  return yup.object().shape(schemaObj);
}
