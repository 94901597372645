import React, { useEffect, useState } from 'react';
import { TOKEN_DATA_KEY } from 'constants/login';

function useIsAuthenticated() {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    getIsUserLoggedIn();
  }, []);

  function getIsUserLoggedIn() {
    if (localStorage.getItem(TOKEN_DATA_KEY)) {
      setIsUserLoggedIn(true);
      return;
    }

    setIsUserLoggedIn(false);
    return;
  }

  return { isUserLoggedIn };
}

export default useIsAuthenticated;
