const {
  REACT_APP_ENDPOINT,
  REACT_APP_API_ENDPOINT,
  REACT_APP_PAYMENT_ENDPOINT,
  REACT_APP_GOOGLE_ANALYTICS_ID,
  REACT_APP_PAYPAL_CLIENT_ID,
  REACT_APP_PAYPAL_CURRENCY,
  REACT_APP_PAYPAL_BUYER_COUNTRY,
} = process.env;

export const APP_ENDPOINT = REACT_APP_ENDPOINT || '';
export const API_ENDPOINT = REACT_APP_API_ENDPOINT || '';
export const PAYMENT_ENDPOINT = REACT_APP_PAYMENT_ENDPOINT || '';
export const GOOGLE_ANALYTICS_ENDPOINT = REACT_APP_GOOGLE_ANALYTICS_ID || '';
export const PAYPAL_CLIENT_ID = REACT_APP_PAYPAL_CLIENT_ID || '';
export const PAYPAL_CURRENCY = REACT_APP_PAYPAL_CURRENCY || 'EUR';
export const PAYPAL_BUYER_COUNTRY = REACT_APP_PAYPAL_BUYER_COUNTRY || 'DE';
