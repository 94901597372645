import React from 'react';

import style from 'assets/scss/components/BaseCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';

function BaseCard(props) {
  const { title, content, titleSize, lineHeight, omitBottomPadding } = props;
  const { wrapper, cardTitle, wrapperNoBottomPadding } = convertKeysToCamelCase(style);

  var line_height = lineHeight;
  if (!line_height) {
    line_height = 1;
  }

  return (
    <article className={omitBottomPadding ? wrapperNoBottomPadding : wrapper}>
      <h2 style={{ fontSize: titleSize, lineHeight: line_height }} className={cardTitle}>
        {title}
      </h2>
      <p>{content}</p>
    </article>
  );
}

export default BaseCard;
