import React from 'react';

import BaseCard from '../BaseCard';
import ExclusiveServiceCard from '../ExclusiveServiceCard';
import StayInformedCard from '../StayInformedCard';
import ContactFormCard from '../ContactFormCard';
import usePublicPages from '../../hooks/usePublicPages';
import { FOR_PARTNERS_ENDPOINT } from '../../constants/routes';

function ForPartnersPage() {
  const {
    mainTitle,
    mainDescription,
    sectionTitle1,
    sectionDescription1,
    sectionTitle2,
    sectionDescription2,
    sectionTitle3,
    sectionDescription3,
    sectionTitle4,
    sectionDescription4,
    newsletterTitle,
    newsletterDescription,
  } = usePublicPages(FOR_PARTNERS_ENDPOINT);

  return (
    <main className="main">
      <section className="max-width">
        <BaseCard title={mainTitle} content={mainDescription} />
      </section>
      <section className="primary">
        <ExclusiveServiceCard
          items={[
            { title: sectionTitle1, description: sectionDescription1 },
            { title: sectionTitle2, description: sectionDescription2 },
            { title: sectionTitle3, description: sectionDescription3 },
            { title: sectionTitle4, description: sectionDescription4 },
          ]}
        />
      </section>
      <section className="white">
        <StayInformedCard {...{ newsletterTitle, newsletterDescription }} />
      </section>
      <section className="secondary">
        <ContactFormCard />
      </section>
    </main>
  );
}

export default ForPartnersPage;
