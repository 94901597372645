import React from 'react';
import { useTranslation } from 'react-i18next';
import style from 'assets/scss/components/pages/RegistrationConfirmPage.module.scss';

function RegistrationConfirmPage() {
  const { wrapper } = style;
  const { t } = useTranslation();

  return (
    <main className="main">
      <div className={wrapper}>
        <h2>{t('register.confirm.thankyou')}</h2>
        <br></br>
        <p>{t('register.confirm.email')}</p>
        <p>{t('register.confirm.click')}</p>
        <p>{t('register.confirm.spam')}</p>
      </div>
    </main>
  );
}

export default RegistrationConfirmPage;
