import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import useGeneral from '../../hooks/useGeneral';
import {
  VIDEO_CLIPS_LESSON_TRANSLATION,
  VIDEO_CLIPS_MODULE_TRANSLATION,
  VIDEO_COACHING_LESSON_TRANSLATION,
  VIDEO_COACHING_MODULE_TRANSLATION,
} from '../../constants/video';
import style from '../../assets/scss/components/Breadcrumbs.module.scss';
import { COACHING_MODULE_DETAILS_PAGE, CLIPS_MODULE_DETAILS_PAGE } from '../../constants/routes';

function Breadcrumbs(props) {
  const { items, moduleId, topicId, title, moduleTitle } = props;
  const { handleRedirect } = useGeneral();
  const { t } = useTranslation();
  const { breadcrumbsWrapper, breadcrumbsItem, breadcrumbsItemLast } = convertKeysToCamelCase(style);

  return (
    <div className={breadcrumbsWrapper}>
      {items.map((item, index) => {
        const { translation, url } = item;
        const isLast = index + 1 === items.length;
        let localTranslation = translation;
        let path = url;
        if (VIDEO_COACHING_MODULE_TRANSLATION === translation) {
          localTranslation = title || '';
          path = COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', topicId);
        }
        if (VIDEO_CLIPS_MODULE_TRANSLATION == translation) {
          localTranslation = title || '';
          path = CLIPS_MODULE_DETAILS_PAGE.replace(':moduleId', topicId);
        }

        return (
          <span key={nanoid()} className={`${breadcrumbsItem} ${isLast && breadcrumbsItemLast}`}>
            <span onClick={() => (isLast ? null : handleRedirect(path))}>
              {VIDEO_COACHING_LESSON_TRANSLATION === localTranslation ||
              VIDEO_CLIPS_LESSON_TRANSLATION === localTranslation
                ? moduleTitle
                : t(localTranslation)}
            </span>
            <i className="icon icon-right-open-big" />
          </span>
        );
      })}
    </div>
  );
}

export default Breadcrumbs;
