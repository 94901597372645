import React from 'react';
import { useTranslation } from 'react-i18next';

import style from 'assets/scss/components/VideoCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useVideo from '../hooks/useVideo';
import useGeneral from '../hooks/useGeneral';
import useRegisterToEvent from '../hooks/useRegisterToEvent';
import AddToCalendar from '@culturehq/add-to-calendar';
import useHeader from '../hooks/useHeader';
import { REGISTER_TYPE } from 'constants/login';

function VideoCard(props) {
  const { t } = useTranslation();
  const { handleVideoRedirect } = useVideo();
  const { handleModalOpen } = useHeader();
  const { getCourse, showTerminateBookingModal, showTerminateBookingModalFirstvideo } = useRegisterToEvent();
  const { handleRedirect } = useGeneral();
  const {
    isVertical,
    isLive,
    title,
    generalInformation,
    thumbnail,
    date,
    isZoomMeet,
    id,
    courseId,
    courseIsLive,
    bookingStatus,
    type,
    access,
    usedOnCompanyPage,
    course,
    dashboardCourse,
    usedOnDashboard,
    label,
    isSecondary,
    buttonClass,
    hasLiveLabel,
    buttonText,
    onButtonClick,
    hasFirstVideo,
    showRegisterButton,
  } = props;
  const {
    wrapper,
    wrapperLive,
    wrapperVertical,
    wrapperHorizontal,
    detailsWrapper,
    detailsWrapperText,
    detailsWrapperTextLive,
    detailsWrapperLive,
    detailsWrapperVertical,
    detailsWrapperHorizontal,
    detailsButton,
    detailsButtonLive,
    marginTop2,
    kalendarButton,
    secondaryLabel,
    calendarModule,
  } = convertKeysToCamelCase(style);

  const showDatesButton = courseIsLive === 1 && bookingStatus === 'canBeBooked';
  const showPlayBookedCourseButton = courseIsLive === 1 && bookingStatus === 'booked';

  const showWaitingText = courseIsLive === 1 && bookingStatus === 'waiting';

  const showPlayButton = !showDatesButton && !showPlayBookedCourseButton;

  const allowRedirect = (bookingStatus !== undefined ? courseIsLive == 0 : true) && access === 'GRANTED';

  let calendarEvent = null;
  let labelDate = null;
  let canPlayVideo = false;
  let liveCourseData = course ? course : dashboardCourse;

  if (showPlayBookedCourseButton) {
    if (hasFirstVideo) {
      calendarEvent = {
        name: liveCourseData.title,
        details: liveCourseData.shortDescription,
        location: '',
        startsAt: liveCourseData.dataForLive.calendarStartTime,
        endsAt: liveCourseData.dataForLive.calendarEndTime,
      };

      labelDate = liveCourseData.dataForLive.modalDate;
      canPlayVideo = liveCourseData.dataForLive.canPlayVideo;
    } else {
      calendarEvent = {
        name: liveCourseData.title,
        details: liveCourseData.shortDescription,
        location: '',
        startsAt: liveCourseData.videos[0].dataForLive.calendarStartTime,
        endsAt: liveCourseData.videos[0].dataForLive.calendarEndTime,
      };

      labelDate = liveCourseData.videos[0].dataForLive.modalDate;
      canPlayVideo = liveCourseData.videos[0].dataForLive.canPlayVideo;
    }
  }

  function clickedOnTeilnehmen() {
    if (usedOnCompanyPage || usedOnDashboard) {
      handleRedirect('/schulung');
    }
    getCourse(courseId);
  }

  function getTerminateBookingModal() {
    if (usedOnDashboard) {
      handleRedirect('/schulung');
    }

    if (hasFirstVideo) {
      showTerminateBookingModalFirstvideo(liveCourseData, courseId);
    } else {
      showTerminateBookingModal(liveCourseData);
    }
  }

  let infoLabel = label || null;
  if (course && course.label) {
    infoLabel = course.label;
  }

  const handleClick = () => {
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    handleVideoRedirect(id, isLive || isZoomMeet || courseIsLive);
  };

  const handleRegisterClick = () => {
    handleModalOpen(true, REGISTER_TYPE, true);
  };

  return (
    <article className={`${wrapper} ${isLive ? wrapperLive : isVertical ? wrapperVertical : wrapperHorizontal}`}>
      <figure
        onClick={() => (allowRedirect ? handleVideoRedirect(id, isLive || isZoomMeet || courseIsLive, type) : null)}
      >
        {/* {isZoomMeet && <span>{date}</span>} */}
        <img src={thumbnail} alt="img" />
        {infoLabel && <span className={isSecondary ? secondaryLabel : ''}>{infoLabel || label}</span>}
      </figure>
      <div
        className={`${detailsWrapper} ${
          isLive ? detailsWrapperLive : isVertical ? detailsWrapperVertical : detailsWrapperHorizontal
        }`}
      >
        {isLive && (
          <div className={detailsButtonLive} onClick={() => handleVideoRedirect(id, isLive || isZoomMeet)}>
            <span>{t('video.card.live.button.label')}</span>
          </div>
        )}
        <div className={detailsWrapperText}>
          <h3 className="headline3">{title}</h3>
          <div>
            {hasLiveLabel && (
              <p className={`${detailsWrapperTextLive} highlight`}>{t('video.card.live.button.label.after')}</p>
            )}
            <p className="highlight">{generalInformation}</p>
          </div>
        </div>
        {showRegisterButton ? (
          <span className={detailsButton}>
            <button className={'primary'} onClick={() => handleRegisterClick()}>
              {t('general.buttons.register_now')}
            </button>
          </span>
        ) : (
          <>
            {showPlayButton && (
              <span className={detailsButton} onClick={handleClick}>
                <button className={buttonClass || 'primary'}>
                  {buttonText || t(`video.card.button${isLive ? '.live' : ''}`)}
                </button>
              </span>
            )}
            {showDatesButton && (
              <span className={detailsButton}>
                <button className={buttonClass || 'primary'} onClick={() => clickedOnTeilnehmen()}>
                  Teilnehmen
                </button>
              </span>
            )}
          </>
        )}

        {showPlayBookedCourseButton && (
          <span className={detailsButton}>
            {canPlayVideo && (
              <button
                className={buttonClass || 'primary'}
                onClick={() => handleVideoRedirect(id, isLive || isZoomMeet, type)}
              >
                {t(`video.card.button${isLive || courseIsLive ? '.live' : ''}`)}
              </button>
            )}
            {!canPlayVideo && (
              <div className={calendarModule}>
                <button className={'secondary'} onClick={() => getTerminateBookingModal()}>
                  <span>{t('video.card.terminate.booking')}</span>
                </button>

                <span style={{ mt: 20 }}>
                  <AddToCalendar event={calendarEvent} children="Im Kalender speichern" />
                </span>
              </div>
            )}
          </span>
        )}
        {showWaitingText && (
          <div className={marginTop2}>
            <h3>You are waiting to be accepted to this course.</h3>
          </div>
        )}
      </div>
    </article>
  );
}

export default VideoCard;
