import React from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

import useExtraPages from '../../hooks/useExtraPages';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import style from 'assets/scss/components/pages/ExtraPage.module.scss';
import useCookie from '../../hooks/useCookie';

function ExtraPage() {
  const { pageData, isCookiePage } = useExtraPages();
  const { handleModifyCookies } = useCookie();
  const { t } = useTranslation();
  const { wrapper, contentWrapper, cookieButton } = convertKeysToCamelCase(style);

  if (!pageData) {
    return null;
  }

  const { title, content } = pageData;

  return (
    <main className="main">
      {isCookiePage && (
        <section className="small max-width">
          <button className={`primary ${cookieButton}`} onClick={handleModifyCookies}>
            {t('cookie.policy.see.cookie.options')}
          </button>
        </section>
      )}
      <section className="small max-width">
        <article className={wrapper}>
          <h3>{title}</h3>
          <div className={contentWrapper} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
        </article>
      </section>
    </main>
  );
}

export default ExtraPage;
