import VideoTabDownloads from 'components/VideoTabDownloads';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';

function PartnerDownloadSection(props) {
  const { materialsTitle, materialsDescription, materialsToDownload } = props;

  if (!materialsTitle || materialsTitle == '' || !materialsDescription || materialsDescription == '') {
    return null;
  }
  return (
    <div className="mt110">
      <div className="partners-page-container">
        <div className="small-container">
          <div className="text-center">
            <h3>{materialsTitle}</h3>
          </div>

          <div className="mt40">
            <div className="text-center">
              <p className="copy" dangerouslySetInnerHTML={{ __html: materialsDescription }}></p>
            </div>
          </div>
        </div>

        <Container className="mb140 mt70">
          <Row align="center" className="download-links">
            {materialsToDownload.map((material) => (
              <Col sm={6} className="text-left mt15" key={material.hash}>
                <VideoTabDownloads downloads={[material]} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default PartnerDownloadSection;
