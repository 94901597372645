import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { HOME_PAGE } from '../constants/routes';
import { TOKEN_DATA_KEY } from '../constants/login';

function PrivateRoute(props) {
  const { component: Component, type, ...rest } = props;
  const token = localStorage.getItem(TOKEN_DATA_KEY);
  return (
    <Route
      {...rest}
      render={(childProps) => (token ? <Component type={type} {...childProps} /> : <Redirect to={HOME_PAGE} />)}
    />
  );
}

export default PrivateRoute;
