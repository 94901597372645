import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  SET_IS_LOGOUT_MODAL_OPEN,
  SET_IS_PROFILE_DROPDOWN_OPEN,
  SET_SELECTED_PROFILE_ITEM,
} from 'constants/actionTypes';
import { MY_PROFILE_PAGE } from 'constants/routes';
import { PROFILE_ITEMS } from 'constants/profile';
import { getAvatarLetters } from 'helpers/profile';

function useProfileDropdown() {
  const { t } = useTranslation();
  const { user, isProfileDropdownOpen } = useSelector((store) => store.defaultReducer);
  const initials = getAvatarLetters(user);
  const history = useHistory();
  const dispatch = useDispatch();

  function handleLogout() {
    dispatch({ type: SET_IS_PROFILE_DROPDOWN_OPEN, payload: false });
    dispatch({ type: SET_IS_LOGOUT_MODAL_OPEN, payload: true });
  }

  function handleRedirect(profileItem) {
    const item = PROFILE_ITEMS.find((item) => item.value === profileItem);
    item.label = t(item.label);
    dispatch({ type: SET_IS_PROFILE_DROPDOWN_OPEN, payload: false });
    dispatch({ type: SET_SELECTED_PROFILE_ITEM, payload: item });
    history.push(MY_PROFILE_PAGE);
  }

  const handleProfileDropdownOpen = () => {
    dispatch({ type: SET_IS_PROFILE_DROPDOWN_OPEN, payload: !isProfileDropdownOpen });
  };

  return { user, initials, isProfileDropdownOpen, handleRedirect, handleLogout, handleProfileDropdownOpen };
}

export default useProfileDropdown;
