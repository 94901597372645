import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { ERROR_PAGE, VIDEO_PAGE, VIDEO_PAGE_EXPERT_TALKS } from '../constants/routes';
import { getVideoApi } from '../api/videosApi';
import { SET_IS_LOADING, SET_STATE, SET_VIDEO } from '../constants/actionTypes';
import { NOT_FOUND_CODE, FORBIDDEN_CODE } from '../constants/general';
import { initialVideo } from '../redux/initialState';
import { VIDEO_TYPE_VIMEO_LIVE } from 'constants/video';
import {
  transformClipsVideoStrategy,
  transformCoachingVideo,
  transformCoachingVideoStrategy,
} from '../transformers/baseTransformer';
import { getSecondsFromString } from '../helpers/baseHelper';

function useVideo(type) {
  const { t } = useTranslation();
  const { video, currentVideoTime, startVideo } = useSelector((store) => store.defaultReducer);
  const history = useHistory();
  const dispatch = useDispatch();

  function strategyType(type) {
    switch (type) {
      case 'clips':
        return transformClipsVideoStrategy(video);
      case 'coachings':
        return transformCoachingVideoStrategy(video);
      default:
        return transformCoachingVideoStrategy(video);
    }
  }

  function handleVideoRedirect(id, isLive, type = '') {
    const path =
      isLive && type != VIDEO_TYPE_VIMEO_LIVE
        ? VIDEO_PAGE_EXPERT_TALKS
        : type === 'coaching'
        ? '/coaching/lesson/:id'
        : VIDEO_PAGE;
    history.push(path.replace(':id', id));
  }

  async function getVideo(id, endpoint) {
    dispatch({ type: SET_STATE, payload: { isLoading: true, video: initialVideo } });

    try {
      const { data } = await getVideoApi(id, endpoint);
      const transformData = transformCoachingVideo(data, t);
      dispatch({ type: SET_VIDEO, payload: transformData });
    } catch (error) {
      if (NOT_FOUND_CODE === error?.response?.status || FORBIDDEN_CODE === error?.response?.status) {
        history.replace(ERROR_PAGE);
      }
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  function handleStartVideoAt(timestamp, isSeconds) {
    const time = isSeconds ? timestamp : getSecondsFromString(timestamp);

    dispatch({
      type: SET_STATE,
      payload: {
        startVideo: {
          change: nanoid(),
          startVideoAt: time,
        },
        currentVideoTime: time,
      },
    });
  }

  return { handleVideoRedirect, getVideo, video: strategyType(type), currentVideoTime, startVideo, handleStartVideoAt };
}

export default useVideo;
