import React, { useState } from 'react';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from 'assets/scss/components/common/sections/PartnerSliderSection.module.scss';
import Slider from 'react-slick';
import { Container } from 'react-grid-system';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { PARTNER_PAGE_SINGULAR, PARTNERS_PAGE } from 'constants/routes';

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        centerMode: true,
        centerPadding: '10%',
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        centerMode: true,
        centerPadding: '10%',
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerMode: true,
        slidesToShow: 2,
        centerPadding: '80px',
      },
    },
    {
      breakpoint: 540,
      settings: {
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '80px',
      },
    },
  ],
};

const PartnerSliderSection = ({ partners }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    partnerSliderSection,
    partnerSliderSectionItem,
    partnerSliderSectionContainer,
    partnerSliderSectionLeftOverlay,
    partnerSliderSectionRightOverlay,
  } = convertKeysToCamelCase(style);

  const [mouseMoved, setMouseMoved] = useState(false);

  const handleClick = (link) => {
    if (!mouseMoved) {
      history.push(link);
    }
  };

  if (!partners) {
    return <></>;
  }
  const slidePartners = [...partners, ...partners]; //react-slick does not autoplays the partners array on wide screen

  return (
    <section className={partnerSliderSection}>
      <Container className="container-fluid">
        <h2 className="headline2">{t('common.sections.partners.title')}</h2>
        <p className="highlight">
          <Trans i18nKey="common.sections.partners.description">
            Text1
            <Link to={PARTNERS_PAGE} className="secondary">
              Link
            </Link>
            Text2
          </Trans>
        </p>
      </Container>
      <div className={partnerSliderSectionContainer}>
        <div className={partnerSliderSectionLeftOverlay} />
        <div className={partnerSliderSectionRightOverlay} />
        <Slider {...settings}>
          {slidePartners &&
            slidePartners.map((partner) => (
              <Link
                to={PARTNER_PAGE_SINGULAR.replace(':id', partner.id)}
                key={partner.id}
                onMouseMove={() => setMouseMoved(true)}
                onMouseDown={() => setMouseMoved(false)}
                onMouseUp={() => handleClick(PARTNER_PAGE_SINGULAR.replace(':id', partner.id))}
                onClick={(e) => e.preventDefault()}
              >
                <div className={partnerSliderSectionItem}>
                  <img src={partner.logo} alt="Logo" />
                </div>
              </Link>
            ))}
        </Slider>
      </div>

      <Link to={PARTNERS_PAGE}>
        <button className="primary">{t('common.sections.partners.link')}</button>
      </Link>
    </section>
  );
};

export default PartnerSliderSection;
