import React from 'react';

function PartnerMedia(props) {
  const { media, mediaType, mediaCaption } = props;

  if (!mediaType || !media) {
    return null;
  }

  return (
    <span>
      <div className="row mt80">
        <div className="col text-center">
          <figure>
            {mediaType === 'image' && <img className="company-media" src={media} alt="img" />}
            {mediaType === 'video' && (
              <div className="company-media-player" dangerouslySetInnerHTML={{ __html: media }}></div>
            )}
          </figure>
        </div>
      </div>
      {mediaCaption && (
        <div className="row mt20 text-center">
          <div className="col">
            <p>{mediaCaption}</p>
          </div>
        </div>
      )}
    </span>
  );
}

export default PartnerMedia;
