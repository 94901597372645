import React from 'react';
import { useTranslation } from 'react-i18next';

import style from 'assets/scss/components/MyProfileCard.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import useMyProfile from 'hooks/useMyProfile';

function MyProfileCard() {
  const { t } = useTranslation();
  const {
    submitCredentialsForm,
    submitProfileForm,
    credentialsRegister,
    profileRegister,
    handleCredentialsSubmit,
    handleProfileSubmit,
    errorsCredentials,
    errorsProfile,
    handleProfileDestroyModal,
  } = useMyProfile();
  const {
    title,
    subtitle,
    inputTitle,
    marginBetween,
    submitButton,
    submitButtonDestroy,
    warningPassword,
  } = convertKeysToCamelCase(style);

  return (
    <>
      <h2 className={title}>{t('dashboard.welcome.card.profile.link')}</h2>
      <form onSubmit={handleCredentialsSubmit(submitCredentialsForm)}>
        <div className={marginBetween}>
          <div className={inputTitle}>{t('contact.form.input.email.placeholder')}</div>
          <input name="email" ref={credentialsRegister} />
          {errorsCredentials?.email && (
            <span className="errors">{t(`validation.${errorsCredentials?.email?.message}`)}</span>
          )}
        </div>
        <div className={marginBetween}>
          <div className={inputTitle}>{t('register.form.password.label')}</div>
          <input name="currentPassword" ref={credentialsRegister} type="password" />
          <p className={warningPassword}>{t('profile.form.password.warning')}</p>
          {errorsCredentials?.currentPassword && (
            <span className="errors">{t(`validation.${errorsCredentials?.currentPassword?.message}`)}</span>
          )}
        </div>
        <div className={`${submitButton} ${marginBetween}`}>
          <button className="primary" type="submit">
            {t('general.buttons.save')}
          </button>
        </div>
      </form>
      <h3 className={subtitle}>{t('profile.form.title')}</h3>
      <form onSubmit={handleProfileSubmit(submitProfileForm)}>
        <div className={marginBetween}>
          <div className={inputTitle}>{t('contact.form.input.firstName.label')}</div>
          <input name="firstName" ref={profileRegister} />
          {errorsProfile?.firstName && (
            <span className="errors">{t(`validation.${errorsProfile?.firstName?.message}`)}</span>
          )}
        </div>
        <div className={marginBetween}>
          <div className={inputTitle}>{t('contact.form.input.lastName.label')}</div>
          <input name="lastName" ref={profileRegister} />
          {errorsProfile?.lastName && (
            <span className="errors">{t(`validation.${errorsProfile?.lastName?.message}`)}</span>
          )}
        </div>
        <div className={marginBetween}>
          <div className={inputTitle}>{t('profile.form.address')}</div>
          <input name="address" ref={profileRegister} />
          {errorsProfile?.address && (
            <span className="errors">{t(`validation.${errorsProfile?.address?.message}`)}</span>
          )}
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-4">
            <div className={marginBetween}>
              <div className={inputTitle}>{t('profile.form.postal.code')}</div>
              <input name="postalCode" ref={profileRegister} />
              {errorsProfile?.postalCode && (
                <span className="errors">{t(`validation.${errorsProfile?.postalCode?.message}`)}</span>
              )}
            </div>
          </div>
          <div className="col-md-12 col-lg-8">
            <div className={marginBetween}>
              <div className={inputTitle}>{t('profile.form.city')}</div>
              <input name="city" ref={profileRegister} />
              {errorsProfile?.city && <span className="errors">{t(`validation.${errorsProfile?.city?.message}`)}</span>}
            </div>
          </div>
        </div>
        <div className={submitButton}>
          <button className="primary" type="submit">
            {t('general.buttons.save')}
          </button>
        </div>
      </form>
      <div className={submitButtonDestroy}>
        <button className={`secondary-red`} onClick={() => handleProfileDestroyModal()}>
          {t('profile.destroy')}
        </button>
      </div>
    </>
  );
}

export default MyProfileCard;
