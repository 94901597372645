import creditCard from '../assets/images/creditcards.svg';
import invoice from '../assets/images/invoice.svg';
import paypal from '../assets/images/paypal.svg';

export const PROFILE_DATA_KEY = 'PROFILE_DATA_KEY';
export const BILLING_DATA_KEY = 'BILLING_DATA_KEY';

export const PAYMENT_PROFILE_FORM_DEFAULT_VALUES = {
  personalFirstName: '',
  personalLastName: '',
  personalSubject: '',
  personalEFNNumber: '',
  personalPhoneNumber: '',
  personalStreet: '',
  personalPostalCode: '',
  personalCity: '',
};
export const PAYMENT_BILLING_FORM_DEFAULT_VALUES = {
  billingFirstName: '',
  billingLastName: '',
  billingSubject: '',
  billingEFNNumber: '',
  billingPhoneNumber: '',
  billingStreet: '',
  billingPostalCode: '',
  billingCity: '',
};

export const PAYMENT_METHODS = [
  { type: 'invoice', img: invoice, titleKey: 'payment.method.invoice' },
  { type: 'paypal', img: paypal, titleKey: 'payment.method.paypal' },
  { type: 'creditCard', img: creditCard, titleKey: 'payment.method.credit' },
];
