import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useGeneral from './useGeneral';
import { SET_IS_LOADING } from '../constants/actionTypes';
import { filterRemainingQuestions } from 'helpers/quizHelper';
import { fetchQuizApi, sendResponseApi, sendQuizRetakeApi, sendDeleteQuizProgressApi } from '../api/quizzesApi';

function useQuizzes(quizId) {
  const [quizData, setQuizData] = useState(null);
  const [selected, setSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectingAnswer, setIsSelectingAnswer] = useState(true);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState({ isVisible: false, location: '' });
  const dispatch = useDispatch();
  const { showToasts } = useGeneral();

  const currentQuestion = quizData?.questions.filter((question) => question.submittedAnswers === null)[0];
  const lastQuestion = quizData?.questions.filter((question) => question.submittedAnswers != null).pop();
  const lastCorrectAnswers = lastQuestion?.possibleAnswers?.filter((possibleAnswer) => possibleAnswer.isCorrect);
  const lastWrongAnswers = lastQuestion?.submittedAnswers?.filter((submittedAnswer) => !submittedAnswer.isCorrect);
  const unSelectedGoodAnswers = lastCorrectAnswers?.filter(
    (correctAnswer) => !selected.find((id) => correctAnswer.id === id),
  );
  const selectedGoodAnswers = lastCorrectAnswers?.filter((correctAnswer) =>
    selected.find((id) => correctAnswer.id === id),
  );
  const remainingQuestions = filterRemainingQuestions(
    lastQuestion?.possibleAnswers,
    lastWrongAnswers,
    lastCorrectAnswers,
  );
  const goodAnswersCount = quizData?.questions.filter((question) => question.isAnsweredCorrectly).length;
  const answersCount = quizData?.questions.filter((question) => question.submittedAnswers != null).length;
  const answeredCount = isSelectingAnswer ? answersCount + 1 : answersCount;
  const toggleSelected = (id) => {
    if (currentQuestion.type === 'single_select') {
      setSelected([id]);

      return;
    }
    const ids = selected.includes(id) ? selected.filter((element) => element != id) : [...selected, id];
    setSelected(ids);
  };

  const deleteSelected = () => {
    setSelected([]);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleIsSelectingAnswer = () => {
    setIsSelectingAnswer(!isSelectingAnswer);
  };

  const toggleCancelModal = (data) => {
    setIsCancelModalVisible(data);
  };
  const wasCorrectAnswer = lastQuestion?.isAnsweredCorrectly;

  async function getQuizData() {
    try {
      const { data } = await fetchQuizApi(quizId);
      setQuizData(data);
    } catch (error) {
      showToasts(error);
    }
  }

  async function sendAnswer(questionId, answerId) {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      const { data } = await sendResponseApi(quizId, questionId, answerId);
      setQuizData(data);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function retakeQuiz() {
    try {
      const { data } = await sendQuizRetakeApi(quizId);
      setQuizData(data);
    } catch (error) {
      showToasts(error);
    }
  }

  async function deleteQuizProgress() {
    try {
      await sendDeleteQuizProgressApi(quizId);
    } catch (error) {
      showToasts(error);
    }
  }

  useEffect(() => {
    getQuizData();
  }, []);

  return {
    quizData,
    sendAnswer,
    selected,
    toggleSelected,
    wasCorrectAnswer,
    deleteSelected,
    retakeQuiz,
    isModalOpen,
    toggleModal,
    deleteQuizProgress,
    lastQuestion,
    currentQuestion,
    lastWrongAnswers,
    remainingQuestions,
    isSelectingAnswer,
    toggleIsSelectingAnswer,
    isCancelModalVisible,
    toggleCancelModal,
    goodAnswersCount,
    answeredCount,
    unSelectedGoodAnswers,
    selectedGoodAnswers,
  };
}

export default useQuizzes;
