import React from 'react';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import VideoCarousel from './VideoCarousel';
import style from 'assets/scss/components/VideoCarouselCard.module.scss';

function VideoCarouselCard(props) {
  const { title, items, isZoomMeet, usedOnProfilePage, usedOnCompanyPage, course, usedOnDashboard } = props;
  const { wrapper } = convertKeysToCamelCase(style);
  if (!items || !items.length) {
    return null;
  }

  return (
    <article className={wrapper}>
      <h4>{title}</h4>

      <VideoCarousel
        usedOnCompanyPage={usedOnCompanyPage}
        usedOnProfilePage={usedOnProfilePage}
        usedOnDashboard={usedOnDashboard}
        items={items}
        isZoomMeet={isZoomMeet}
        course={course}
      />
    </article>
  );
}

export default VideoCarouselCard;
