import { useDispatch, useSelector } from 'react-redux';

import { SET_COURSES, SET_FILTERS, SET_IS_LOADING, SET_SELECTED_FILTERS } from '../constants/actionTypes';
import { getSelectedFilters } from '../helpers/baseHelper';
import { getCourseFiltersApi, getCourseVideosApi } from '../api/videosApi';
import { NOT_FOUND_CODE } from '../constants/general';
import { ERROR_PAGE } from '../constants/routes';

function useTrainingFilters() {
  const { filters, selectedFilters } = useSelector((store) => store.defaultReducer);
  const dispatch = useDispatch();

  function handleChange(filters, filterType) {
    const newFilters = getSelectedFilters(filters, filterType, selectedFilters);

    dispatch({ type: SET_SELECTED_FILTERS, payload: newFilters });
  }

  async function getFilters() {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data } = await getCourseFiltersApi();

      dispatch({ type: SET_FILTERS, payload: data });
    } catch (error) {
      if (NOT_FOUND_CODE === error?.response?.status) {
        history.replace(ERROR_PAGE);
      }
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  return { filters, selectedFilters, handleChange, getFilters };
}

export default useTrainingFilters;
