import React from 'react';

import useHeader from './useHeader';
import { useDispatch, useSelector } from 'react-redux';
import { SET_IS_BURGER_MENU_OPEN } from '../constants/actionTypes';

function useBurgerMenu() {
  const { isAuthenticated, handleModalOpen, isLoginModalOpen } = useHeader();
  const { isBurgerMenuOpen } = useSelector((store) => store.defaultReducer);
  const dispatch = useDispatch();

  const handleBurgerMenuOpen = (isOpen) => {
    dispatch({ type: SET_IS_BURGER_MENU_OPEN, payload: isOpen });
  };

  return {
    isBurgerMenuOpen,
    isAuthenticated,
    isLoginModalOpen,
    buttonIsHidden: isLoginModalOpen && !isBurgerMenuOpen,
    handleModalOpen,
    handleBurgerMenuOpen,
  };
}

export default useBurgerMenu;
