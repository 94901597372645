import React from 'react';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
// import style from 'assets/scss/components/VideoDetailsPartner.module.scss';
import style from 'assets/scss/components/CourseVideoDetailsInfo.module.scss';
import VideoDetailsPartner from './VideoDetailsPartner';
import CourseVideoDetailsAccordion from './CourseVideoDetailsAccordion';

function CourseVideoDetailsPartner() {
  const { wrapper, accordionWrapper, partnerWrapper, actionsWrapper } = convertKeysToCamelCase(style);

  return (
    <div className={wrapper}>
      <div className={accordionWrapper}>
        <CourseVideoDetailsAccordion title="presentedBy" content={<VideoDetailsPartner />} />
      </div>
      <div className={actionsWrapper}>
        <div className={partnerWrapper}>
          <VideoDetailsPartner type="clips" />
        </div>
      </div>
    </div>
  );
}

export default CourseVideoDetailsPartner;
