import trophyImage from '../assets/images/trophy-icon.svg';
import chatIcon from '../assets/images/chat-icon.svg';
import diplomaIcon from '../assets/images/diploma-icon.svg';
import speedoMeter from '../assets/images/speedometer.svg';

export const COACHINGS_DETAILS_PAGE = [
  {
    titleKey: 'coachings.details.exclusive.specialization',
    descriptionKey: 'forPatients',
    image: trophyImage,
  },
  {
    titleKey: 'coachings.details.exclusive.experts',
    descriptionKey: 'renownedExperts',
    image: chatIcon,
  },
  {
    titleKey: 'coachings.details.exclusive.exchange',
    descriptionKey: 'exchange',
    image: diplomaIcon,
  },
  {
    titleKey: 'coachings.details.speed',
    descriptionKey: 'tempo',
    image: speedoMeter,
  },
];

export const COURSE_TYPE_COUCHING = 'coaching';
