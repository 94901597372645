import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import { COACHING_PAYMENT_FIRST_STEP } from 'constants/routes';
import useGeneral from 'hooks/useGeneral';
import usePayment from 'hooks/usePayment';
import style from '../assets/scss/components/BillingPersonalInformation.module.scss';
import edit from '../assets/images/edit.svg';
import { useSelector } from 'react-redux';

function BillingPersonalInformation() {
  const { line, title, imageContainer, titleContainer, wrapper } = convertKeysToCamelCase(style);
  const { profileData, billingData, coachId } = usePayment();
  const { handleRedirect } = useGeneral();
  const { t } = useTranslation();
  const { preferredPaymentMethod } = useSelector((store) => store.defaultReducer);

  if (!profileData) {
    return null;
  }

  var paymentMethod = 'Kreditkarte';
  if (preferredPaymentMethod == 'invoice') {
    paymentMethod = 'Rechnung';
  }
  if (preferredPaymentMethod == 'paypal') {
    paymentMethod = 'PayPal';
  }

  return (
    <div className={wrapper}>
      <div className={titleContainer}>
        <h2 className={title}>{t('payment.informations.overview')}</h2>
        <div className={imageContainer}>
          <img
            src={edit}
            onClick={() => {
              handleRedirect(COACHING_PAYMENT_FIRST_STEP.replace(':coachId', coachId));
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <h4>{t('payment.informations.personal.info')}</h4>
        </div>
        <div className="col-md-4">
          <div className={line}>
            <p>{`${profileData.personalFirstName} ${profileData.personalLastName}`}</p>
            <p>{profileData.personalSubject}</p>
          </div>
          <div className={line}>
            <p>{profileData.personalStreet}</p>
            <p>{`${profileData.personalPostalCode} ${profileData.personalCity}`}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <h4>{t('payment.informations.efn')}</h4>
        </div>
        <div className="col-4">
          <p className={line}>{profileData.personalEFNNumber}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <h4>{t('payment.informations.contact')}</h4>
        </div>
        <div className="col-md-4">
          <div className={line}>
            <p>{profileData.personalEmailAddress}</p>
            <p>{profileData.personalPhoneNumber}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <h4>{t('payment.informations.method')}</h4>
        </div>
        <div className="col-4">
          <p className={line}>{paymentMethod}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <h4>{t('payment.informations.billing')}</h4>
        </div>
        <div className="col-md-4">
          <div className={line}>
            <p>{`${billingData.billingFirstName} ${billingData.billingLastName}`}</p>
            <p>{billingData.billingSubject}</p>
            <p>{billingData.billingEFNNumber}</p>
          </div>
          <div className={line}>
            <p>{billingData.billingEmailAddress}</p>
            <p>{billingData.billingPhoneNumber}</p>
          </div>
          <div className={line}>
            <p>{billingData.billingStreet}</p>
            <p>{`${billingData.billingPostalCode} ${billingData.billingCity}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingPersonalInformation;
