import React from 'react';
import { useTranslation } from 'react-i18next';

import style from 'assets/scss/components/LastViewedCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import VideoCard from './VideoCard';
import { MEETING_TYPE_ZOOM } from '../constants/general';

function LastViewedCard(props) {
  const { t } = useTranslation();
  const { wrapper, wrapperLive } = convertKeysToCamelCase(style);
  const { video } = props;
  if (!video) {
    return null;
  }

  const { type } = video;
  const isLive = MEETING_TYPE_ZOOM === type;

  return (
    <article className={isLive ? wrapperLive : wrapper}>
      {!isLive && <h4>{t('dashboard.last.viewed.card.title')}</h4>}

      <VideoCard {...video} isLive={isLive} />
    </article>
  );
}

export default LastViewedCard;
