import React from 'react';

export const OncoCoach2025 = () => {
  return (
    <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      <iframe
        src="/oncocoach-2025/index.html"
        title="OncoCoach 2025"
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>
  );
};
