import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useVideo from './useVideo';

function useCourseVideoDetails() {
  const history = useHistory();
  const { video, currentVideoTime, startVideo } = useVideo();
  const { otherVideos } = video;
  const [isQuizEnabled, setIsQuizEnabled] = useState(false);

  function handleQuiz(quiz) {
    if (!quiz) {
      return;
    }

    history.push({ search: `?quizId=${quiz.id}&quizTitle=${quiz.title}` });
  }

  useEffect(() => {
    if (!otherVideos || !otherVideos.length) {
      return;
    }

    const areAllCompleted = otherVideos.every((otherVideo) => otherVideo.progress === 100);
    if (areAllCompleted) {
      setIsQuizEnabled(true);

      return;
    }

    const areCompleted = otherVideos.every((otherVideo) => otherVideo.id === video.id || otherVideo.progress === 100);
    const time = Math.round(currentVideoTime) || startVideo.startVideoAt;
    if (areCompleted && time === video?.duration) {
      setIsQuizEnabled(true);
    }

    return () => {
      setIsQuizEnabled(false);
    };
  }, [currentVideoTime, video]);

  return {
    handleQuiz,
    isQuizEnabled,
  };
}

export default useCourseVideoDetails;
