import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { ReactComponent as CheckButton } from 'assets/images/check-circle-fill.svg';
import { ReactComponent as PlayButton } from 'assets/images/play-circle-fill.svg';
import { ReactComponent as PlayModuleButton } from 'assets/images/play_module.svg';
import { ReactComponent as PlayButtonCurrent } from 'assets/images/play_continue.svg';
import style from 'assets/scss/components/CourseVideosDetailsItem.module.scss';

function CourseVideosDetailsItemProgress(props) {
  const {
    isCurrent,
    title,
    progress,
    isLesson,
    videoId,
    firstGradient,
    secondGradient,
    canWatchModule,
    itemIndex,
    videoProgress,
    handleLessonClick,
    isPaidFor,
    isWholeCoachingPurchased,
    type,
  } = props;
  const { t } = useTranslation();
  const {
    playButton,
    playButtonDisabled,
    playButtonCurrent,
    playButtonWrapper,
    playButtonWrapperCurrent,
    playButtonCompletion,
    playButtonCompletionCurrent,
    playButtonCompletionBackground,
    itemNumber,
    itemTitle,
    itemButton,
    itemDetails,
    itemPercent,
    itemUnavaliable,
  } = convertKeysToCamelCase(style);
  const localProgress = isLesson ? videoProgress : progress;

  const isModuleBooked = !isWholeCoachingPurchased && canWatchModule && isPaidFor;

  const getPlayButton = () => {
    if (isModuleBooked) {
      return <PlayModuleButton className={`${playButton}`} />;
    } else {
      return <PlayButton className={`${playButton} ${!canWatchModule && playButtonDisabled}`} />;
    }
  };

  return (
    <>
      <span
        className={itemButton}
        // onClick={() => ((!isLesson && canWatchModule) || isLesson ? handleLessonClick(videoId, isLesson) : null)}
      >
        <span className={`${playButtonWrapper} ${isCurrent && playButtonWrapperCurrent}`}>
          {(isCurrent && localProgress === 100) || progress === 100 ? (
            <CheckButton className={`${playButton} ${isCurrent && playButtonCurrent}`} />
          ) : isCurrent ? (
            <>
              <PlayButtonCurrent className={`${playButton} ${playButtonCurrent}`} />
              <span className={isCurrent ? playButtonCompletionBackground : ''} />
              <span
                className={`${playButtonCompletion} ${isCurrent && playButtonCompletionCurrent}`}
                style={{
                  backgroundImage: `conic-gradient(${firstGradient}, ${secondGradient})`,
                }}
              />
            </>
          ) : (
            getPlayButton()
          )}
        </span>
      </span>
      <span className={itemDetails}>
        <p className={itemNumber}>
          {t(`video.${type}.details.page.${isLesson ? 'lesson' : 'module'}.number`, { number: itemIndex + 1 })}
        </p>
        {isLesson && <p className={itemTitle}>{title}</p>}
        {isCurrent && !isNaN(localProgress) && (
          <p className={itemPercent}>{t('video.coaching.details.page.lesson.percent', { percent: localProgress })}</p>
        )}
        {!isLesson && !canWatchModule && (
          <p className={itemUnavaliable}>{t('video.coaching.details.page.unavaliable')}</p>
        )}
        {isModuleBooked && <p className={itemPercent}>{t('video.coaching.details.page.module.watch')}</p>}
      </span>
    </>
  );
}

export default CourseVideosDetailsItemProgress;
