import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { contactFormApi } from '../api/contactApi';
import useYupValidationResolver from './useYupValidationResolver';
import { getContactFormValidationSchema } from '../helpers/validationSchemaHelper';
import { CONTACT_FORM_DEFAULT_VALUES } from '../constants/login';
import { SET_IS_LOADING } from '../constants/actionTypes';
import useGeneral from './useGeneral';

function useContact() {
  const resolver = useYupValidationResolver(getContactFormValidationSchema());
  const { register, handleSubmit, errors, reset } = useForm({ resolver, defaultValues: CONTACT_FORM_DEFAULT_VALUES });
  const { showToasts } = useGeneral();
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(false);

  async function submitContactForm(formData) {
    if (!isChecked) {
      return;
    }

    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const response = await contactFormApi(formData);
      const { data } = response;

      toast.success(data.message);
      reset();
    } catch (error) {
      reset();
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  return { submitContactForm, register, handleSubmit, errors, reset, setIsChecked, isChecked };
}

export default useContact;
