import React from 'react';
import { useTranslation } from 'react-i18next';

import usePayment from 'hooks/usePayment';
import BackButton from 'components/BackButton';
import StepIndicator from 'components/StepIndicator';
import PaymentMethod from 'components/PaymentMethod';
import BillingDetailsForm from 'components/BillingDetailsForm';
import PaymentBaseCard from 'components/PaymentBaseCard';
import { COACHING_PAYMENT_FIRST_STEP, MODULE_PAYMENT_FIRST_STEP } from 'constants/routes';
import useCoachingDetails from 'hooks/useCoachingDetails';
import { useParams } from 'react-router-dom';

function CoachingPaymentSecondPage() {
  const params = useParams();
  const coachId = params.coachId;
  const moduleId = params.moduleId;

  const { details: coachingDetails } = useCoachingDetails(moduleId || coachId, !!moduleId);
  if (!coachingDetails) {
    return null;
  }

  return (
    <main className="main grey max-width">
      <section className="small">
        <BackButton
          to={
            moduleId
              ? MODULE_PAYMENT_FIRST_STEP.replace(':moduleId', moduleId)
              : COACHING_PAYMENT_FIRST_STEP.replace(':coachId', coachId)
          }
        />
      </section>
      <section className="small">
        <StepIndicator currentStep={2} />
      </section>
      <section className="small">
        <PaymentBaseCard title={coachingDetails.title} content={coachingDetails.paymentDescription} />
      </section>
      <section className="small">
        <PaymentMethod />
      </section>
      <section className="small">
        <BillingDetailsForm />
      </section>
    </main>
  );
}

export default CoachingPaymentSecondPage;
