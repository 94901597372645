import React from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from 'assets/scss/components/CourseVideoDetailsUpcomingTutoriums.module.scss';

function CourseVideoDetailsUpcomingTutoriums(props) {
  const { t } = useTranslation();
  const { upcomingTutoriums, handleUpcomingTutorium } = props;
  const { itemWrapper, itemTitle, itemDate, itemDescription } = convertKeysToCamelCase(style);
  if (!upcomingTutoriums || !upcomingTutoriums.length) {
    return null;
  }

  return (
    <div>
      {upcomingTutoriums.map((item) => {
        const { id, title, description, date, hours, canApply } = item;

        return (
          <div key={nanoid()} className={itemWrapper}>
            <p className={itemTitle}>{title}</p>
            <p className={itemDate}>
              <span>{`${date} |`}&nbsp;</span>
              <span>{hours}</span>
            </p>
            {canApply == 1 && (
              <button onClick={() => handleUpcomingTutorium(id)} className="secondary">
                {t('general.buttons.signIn')}
              </button>
            )}

            {!canApply && <button className="disabled">{t('general.buttons.signedIn')}</button>}
            <p className={itemDescription}>{description}</p>
          </div>
        );
      })}
    </div>
  );
}

export default CourseVideoDetailsUpcomingTutoriums;
