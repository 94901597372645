import React from 'react';

import useOverviewPage from 'hooks/useOverviewPage';
import { COACHING_OVERVIEW_BREADCRUMBS } from 'constants/video';
import ContentLayout from 'components/layout/ContentLayout';
import { useTranslation } from 'react-i18next';
import PerksSection from 'components/common/sections/PerksSection';
import NewsletterSection from 'components/common/sections/NewsletterSection';
import PartnerSliderSection from 'components/common/sections/PartnerSliderSection';
import OffersSection from 'components/common/sections/OffersSection';
import Breadcrumbs from './Breadcrumbs';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import PresentationOverview from 'components/common/sections/PresentationOverview';

function CoachingPage() {
  const { t } = useTranslation();
  /** @todo: check if we can reuse for clips */
  const { overviewPage } = useOverviewPage('coaching');
  const { isUserLoggedIn } = useIsAuthenticated();

  if (!overviewPage) {
    return null;
  }

  const {
    takeAways,
    description,
    thumbnail,
    sponsorings,
    offeringTitle,
    offeringDescription,
    content,
    newsletterTitle,
    newsletterDescription,
  } = overviewPage;

  return (
    <ContentLayout title={t('coachings.page.title')}>
      <PresentationOverview
        topComponent={<Breadcrumbs items={COACHING_OVERVIEW_BREADCRUMBS} />}
        title={t('coachings.page.title')}
        description={description}
        thumbnail={thumbnail}
        hideButton={isUserLoggedIn}
      />

      <OffersSection title={offeringTitle} description={offeringDescription} topics={content} />

      <PerksSection title="IHRE TAKEAWAYS" items={takeAways} />
      <PartnerSliderSection partners={sponsorings} />
      <NewsletterSection title={newsletterTitle} description={newsletterDescription} />
    </ContentLayout>
  );
}

export default CoachingPage;
