import React from 'react';
import style from '../assets/scss/components/PaymentBaseCard.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';

function PaymentBaseCard(props) {
  const { title, content } = props;
  const { wrapper, cardTitle, cardDescription } = convertKeysToCamelCase(style);

  return (
    <div className={wrapper}>
      <h2 className={cardTitle}>{title}</h2>
      <p className={cardDescription}>{content}</p>
    </div>
  );
}

export default PaymentBaseCard;
