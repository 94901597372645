import React from 'react';
import { useTranslation } from 'react-i18next';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from '../../../assets/scss/components/common/cards/CoachingModuleCard.module.scss';
import PropTypes from 'prop-types';
import useGeneral from 'hooks/useGeneral';
import { MODULE_PAYMENT_FIRST_STEP, COACHING_MODULE_DETAILS_PAGE, CLIPS_MODULE_DETAILS_PAGE } from 'constants/routes';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import { REGISTER_TYPE } from 'constants/login';
import useHeader from 'hooks/useHeader';
import { moduleDetailsPageByTypeAndTopicId } from 'helpers/redirectHelper';

const TopicModuleCard = ({ id, title, image, isPaidFor, price, topicId, type }) => {
  const { t } = useTranslation();
  const { isUserLoggedIn } = useIsAuthenticated();
  const { handleModalOpen } = useHeader();

  const {
    moduleCard,
    moduleCardImageWrapper,
    moduleCardImageWrapperBadge,
    moduleCardImageWrapperBadgeSecondary,
    moduleCardContentWrapper,
  } = convertKeysToCamelCase(style);
  const { handleRedirect } = useGeneral();

  const handleClick = () => {
    if (!isUserLoggedIn) {
      handleModalOpen(true, REGISTER_TYPE, true);
      return;
    }

    if (!isPaidFor) handleRedirect(MODULE_PAYMENT_FIRST_STEP.replace(':moduleId', id));
    else handleRedirect(moduleDetailsPageByTypeAndTopicId(type, topicId));
  };

  return (
    <div className={moduleCard}>
      <div className={moduleCardImageWrapper}>
        <img src={image} alt={title} />
        <span className={type === 'clips' ? moduleCardImageWrapperBadgeSecondary : moduleCardImageWrapperBadge}>
          {price ? `${price}€` : t(`${type}.list.tag`)}
        </span>
      </div>
      <div className={moduleCardContentWrapper}>
        <p className="highlight">{title}</p>
        <button className={isPaidFor ? 'primary' : 'secondary'} onClick={handleClick}>
          {isUserLoggedIn
            ? isPaidFor
              ? t('coachings.start.now')
              : t('coachings.modules.buy')
            : t('general.buttons.register_now')}
        </button>
      </div>
    </div>
  );
};

TopicModuleCard.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  image: PropTypes.string,
  isPaidFor: PropTypes.bool,
  price: PropTypes.number,
  type: PropTypes.string,
};

export default TopicModuleCard;
