import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import VideoTabDownloads from 'components/VideoTabDownloads';

function PartnerBannerSection(props) {
  const { bannerTitle, bannerContent, bannerImage, bannerFile, download } = props;

  if (!bannerTitle || bannerTitle == '' || !bannerContent || bannerContent == '') {
    return null;
  }

  return (
    <span>
      <Row className="no-margin">
        <Container>
          <Row className="mt50 mb50">
            <Col sm={6}>
              <div className="image-container">
                <img className="headline-image" src={bannerImage} />
              </div>
            </Col>

            <Col sm={6}>
              <h4>{bannerTitle}</h4>

              <div className="mt30 mb70">
                <p className="copy left-aligned" dangerouslySetInnerHTML={{ __html: bannerContent }}></p>
              </div>

              {bannerFile && <VideoTabDownloads downloads={download} noPadding />}
            </Col>
          </Row>
        </Container>
      </Row>
    </span>
  );
}

export default PartnerBannerSection;
