import React, { useEffect } from 'react';

import useGeneral from 'hooks/useGeneral';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import RegisterForm from 'components/RegisterForm';
import { TOKEN_COURSE_CODE } from 'constants/login';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import { ReactComponent as LogoWhite } from 'assets/images/logo-white.svg';
import { ReactComponent as LogoWhiteSmall } from 'assets/images/logo_text--white.svg';

import style from '../../assets/scss/components/pages/RegisterPage.module.scss';

import { HOME_PAGE, REGISTER_PAGE } from '../../constants/routes';

function RegisterPage() {
  const { t } = useTranslation();
  const { handleRedirect } = useGeneral();

  const history = useHistory();
  const { coachingCode } = useParams();

  const { wrapper, logoWrapper, logoWrapperLarge, logoWrapperSmall, backButton } = convertKeysToCamelCase(style);

  useEffect(() => {
    if (coachingCode) {
      sessionStorage.setItem(TOKEN_COURSE_CODE, coachingCode);
    } else {
      history.push(REGISTER_PAGE);
    }
  }, [history, coachingCode]);

  return (
    <main className={`${wrapper}`}>
      <div className={logoWrapper}>
        <LogoWhite className={logoWrapperLarge} onClick={() => handleRedirect(HOME_PAGE)} />
        <LogoWhiteSmall className={logoWrapperSmall} onClick={() => handleRedirect(HOME_PAGE)} />
      </div>
      <RegisterForm />
      <span onClick={() => handleRedirect(HOME_PAGE)} className={backButton}>
        {t('general.buttons.backToWebPage')}
      </span>
    </main>
  );
}

export default RegisterPage;
