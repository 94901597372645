import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Prompt } from 'react-router';

import CancelQuizModal from './CancelQuizModal';
import QuizEndingComponent from './QuizEndingComponent';
import QuizQuestion from './QuizQuestion';
import QuizAnswer from './QuizAnswer';
import { convertKeysToCamelCase } from 'helpers/baseHelper';

import { COACHING_MODULE_DETAILS_PAGE } from '../constants/routes';
import useGeneral from 'hooks/useGeneral';
import useQuizzes from 'hooks/useQuizzes';
import style from 'assets/scss/components/QuizComponent.module.scss';

function QuizComponent() {
  const { quizId } = useParams();
  const {
    quizData,
    sendAnswer,
    retakeQuiz,
    deleteQuizProgress,
    currentQuestion,
    lastQuestion,
    lastWrongAnswers,
    remainingQuestions,
    selected,
    toggleSelected,
    deleteSelected,
    isModalOpen,
    toggleModal,
    isSelectingAnswer,
    toggleIsSelectingAnswer,
    wasCorrectAnswer,
    isCancelModalVisible,
    toggleCancelModal,
    goodAnswersCount,
    answeredCount,
    unSelectedGoodAnswers,
    selectedGoodAnswers,
  } = useQuizzes(quizId);
  const { handleRedirect } = useGeneral();
  const { container, buttonContainer, small, backButton } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  if (!quizData) {
    return null;
  }

  const backElement = () => {
    return (
      <div
        onClick={() => {
          handleRedirect(COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', quizData?.coachingId));
        }}
        className={backButton}
      >
        &#8249; <p>{t('quizzes.backToModule')}</p>
      </div>
    );
  };

  if (!currentQuestion && isSelectingAnswer) {
    return (
      <div>
        {backElement()}
        <QuizEndingComponent
          onRetake={retakeQuiz}
          goodAnswers={goodAnswersCount}
          maxAnswers={quizData.questions.length}
          isSuccesfull={quizData.successRatePercentage >= 50}
          nextLessonId={quizData.nextLessonId}
          coachingId={quizData.coachingId}
        />
      </div>
    );
  }

  return (
    <div className={container}>
      <Prompt
        message={(location) => {
          if (!isCancelModalVisible.isVisible) {
            toggleCancelModal({ isVisible: !isCancelModalVisible.isVisible, location: location.pathname });
          }
          return isCancelModalVisible.isVisible;
        }}
      />
      <CancelQuizModal
        isVisible={isCancelModalVisible.isVisible}
        onRedirect={() => {
          deleteQuizProgress();
          handleRedirect(isCancelModalVisible.location);
        }}
        onClose={() => {
          toggleCancelModal({ ...isCancelModalVisible, isVisible: false });
        }}
      />
      {backElement()}
      <p className={small}>
        {t('quizzes.questionIndicator', {
          currentQuestion: answeredCount,
          maxQuestion: quizData.questions.length,
        })}
      </p>
      <QuizQuestion
        isSelectingAnswer={isSelectingAnswer}
        currentQuestion={currentQuestion}
        lastQuestion={lastQuestion}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
      />
      <QuizAnswer
        isSelectingAnswer={isSelectingAnswer}
        lastWrongAnswers={lastWrongAnswers}
        wasCorrectAnswer={wasCorrectAnswer}
        selected={selected}
        toggleSelected={toggleSelected}
        currentQuestion={currentQuestion}
        remainingQuestions={remainingQuestions}
        unSelectedGoodAnswers={unSelectedGoodAnswers}
        selectedGoodAnswers={selectedGoodAnswers}
      />
      <div className={buttonContainer}>
        <button
          className="secondary-red"
          onClick={() => {
            if (isSelectingAnswer) {
              sendAnswer(currentQuestion.id, selected);
            }
            toggleIsSelectingAnswer();
            if (!isSelectingAnswer) {
              deleteSelected();
            }
          }}
        >
          {isSelectingAnswer
            ? t('quizzes.checkAnswer')
            : wasCorrectAnswer
            ? t('quizzes.nextQuestion.correct')
            : t('quizzes.nextQuestion')}
        </button>
      </div>
    </div>
  );
}

export default QuizComponent;
