import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/ResetPasswordForm.module.scss';
import useResetPassword from '../hooks/useResetPassword';

function ResetPasswordForm() {
  const { t } = useTranslation();
  const { form, formLabel, formError, submitButton } = convertKeysToCamelCase(style);
  const { handleSubmitForm, register, errors } = useResetPassword();

  return (
    <form className={form} onSubmit={handleSubmitForm}>
      <label className={formLabel}>{t('register.form.email.label')}</label>
      <input name="email" ref={register} />
      {errors?.email && <div className={formError}>{t(`validation.${errors?.email?.message}`)}</div>}
      <label className={formLabel}>{t('register.form.password.label')}</label>
      <input name="password" ref={register} type="password" />
      {errors?.password && <div className={formError}>{t(`validation.${errors?.password?.message}`)}</div>}
      <label className={formLabel}>{t('register.form.password.confirm.label')}</label>
      <input name="passwordConfirmation" ref={register} type="password" />
      {errors?.passwordConfirmation && (
        <div className={formError}>{t(`validation.${errors?.passwordConfirmation?.message}`)}</div>
      )}
      <button className={submitButton} type="submit">
        {t('general.buttons.submit')}
      </button>
    </form>
  );
}

export default ResetPasswordForm;
