export const REGISTER_GENDERS = [
  { value: 'Male', label: 'Männlich' },
  { value: 'Female', label: 'Weiblich' },
  { value: 'Other', label: 'Divers' },
];
export const SIGN_IN_TYPE = 'signIn';
export const REGISTER_TYPE = 'register';

export const LOGIN_TABS = [SIGN_IN_TYPE, REGISTER_TYPE];
export const LOGIN_BUTTONS = [SIGN_IN_TYPE, REGISTER_TYPE];

export const RESET_PASSWORD_DEFAULT_VALUES = {
  email: '',
  password: '',
  passwordConfirmation: '',
};

export const REGISTER_FORM_DEFAULT_VALUES = {
  ...RESET_PASSWORD_DEFAULT_VALUES,
  firstName: '',
  lastName: '',
  institution: '',
  gender: [],
  newsletter: true,
  coachingCode: '',
};

export const SIGN_IN_DEFAULT_VALUES = {
  email: '',
  password: '',
};

export const ASK_EXPERT_DEFAULT_VALUES = {
  question: '',
};

export const EMAIL_DEFAULT_VALUES = {
  email: '',
};

export const CONTACT_FORM_DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
};

export const USER_DATA_KEY = 'USER_DATA_KEY';
export const TOKEN_DATA_KEY = 'TOKEN_DATA_KEY';
export const TOKEN_COURSE_CODE = 'TOKEN_COURSE_CODE';
