import React from 'react';
import AddToCalendar from '@culturehq/add-to-calendar';
import { useTranslation } from 'react-i18next';

import style from 'assets/scss/components/MyCourseCard.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import useMyProfile from 'hooks/useMyProfile';
import { nanoid } from 'nanoid';
import LastViewedCard from './LastViewedCard';
import VideoCarouselCard from './VideoCarouselCard';
import useDashboardPage from 'hooks/useDashboardPage';
import useGeneral from 'hooks/useGeneral';
import { COACHING_MODULE_DETAILS_PAGE } from 'constants/routes';

function MyCourseCard() {
  const { t } = useTranslation();
  const {
    secondaryTitle,
    courseCard,
    courseCardContent,
    courseCardLogo,
    filesButton,
    kalendarButton,
    mainButton,
    sliderWrapper,
    lastViewedSection,
  } = convertKeysToCamelCase(style);
  const {
    courses,
    coachings,
    clips,
    handleLastWatchedVideo,
    showTerminateBookingModal,
    handleCompressedFileDownload,
  } = useMyProfile();
  const { interestedInVideos, currentVideo } = useDashboardPage();
  const { handleRedirect } = useGeneral();

  return (
    <>
      {/* <h2 className={title}>{t('profile.course')}</h2> */}
      <h3 className={secondaryTitle}>{t('profile.course.registered')}</h3>
      <div className={`row ${sliderWrapper} generic-carousel generic-carousel-padding`}>
        {[...coachings, ...clips, ...courses].map((course) => {
          const {
            logo,
            title,
            shortDescription,
            isLive,
            label,
            calendarStartTime,
            calendarEndTime,
            courseType,
            hasCompressedFile,
            entity,
            canPlayVideo,
          } = course;
          const calendarEvent = {
            name: title,
            details: shortDescription,
            location: '',
            startsAt: calendarStartTime,
            endsAt: calendarEndTime,
          };

          return (
            <div key={nanoid()} className={`${courseCard} col-md-5`}>
              <div className={courseCardLogo}>
                <figure>
                  <img src={logo} alt="img" />
                  <span>{label}</span>
                </figure>
              </div>
              <div className={courseCardContent}>
                <h4 onClick={() => handleLastWatchedVideo(course)}>{title}</h4>
                <p>{shortDescription}</p>
                {entity === 'module' && course.coaching_id ? (
                  <button
                    onClick={() =>
                      handleRedirect(COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', course.coaching_id))
                    }
                    className={`secondary-red ${mainButton}`}
                  >
                    {t('profile.my.modules.details')}
                  </button>
                ) : null}
                {!isLive && entity === 'course' && (
                  <button className={`primary ${mainButton}`} onClick={() => handleLastWatchedVideo(course)}>
                    <span>{t('video.card.button')}</span>
                  </button>
                )}
                {!isLive && entity === 'coaching' && (
                  <button onClick={() => handleLastWatchedVideo(course)} className={`secondary-red ${mainButton}`}>
                    {t('profile.my.modules.details')}
                  </button>
                )}
                {(function () {
                  if (!canPlayVideo && isLive) {
                    return (
                      <span className={kalendarButton}>
                        <AddToCalendar event={calendarEvent} children="Im Kalender speichern" />
                      </span>
                    );
                  }
                })()}
                {(function () {
                  if (!canPlayVideo && isLive && courseType === 'booked') {
                    return (
                      <span>
                        <button className="secondary" onClick={() => showTerminateBookingModal(course)}>
                          <span>{t('video.card.terminate.booking')}</span>
                        </button>
                      </span>
                    );
                  }
                  if (canPlayVideo && isLive && courseType === 'booked') {
                    return (
                      <span>
                        <button className="primary" onClick={() => handleLastWatchedVideo(course)}>
                          <span>{t('video.card.button.live')}</span>
                        </button>
                      </span>
                    );
                  }
                  if (isLive && courseType === 'waiting') {
                    return (
                      <span>
                        <button className="secondary" onClick={() => showTerminateBookingModal(course)}>
                          <span>{t('video.card.terminate.waiting.list.booking')}</span>
                        </button>
                      </span>
                    );
                  }
                })()}
                {/* {hasCompressedFile && (
                  <span className={filesButton}>
                    <button className="primary" onClick={() => handleCompressedFileDownload(course)}>
                      {t('video.card.download.course.data')}
                    </button>
                  </span>
                )} */}
              </div>
            </div>
          );
        })}
      </div>
      <section className={`small no-padding ${lastViewedSection}`}>
        <LastViewedCard usedOnProfilePage={true} video={currentVideo} />
      </section>
      <div className="carouse-section">
        <VideoCarouselCard
          usedOnProfilePage={true}
          title={t('dashboard.also.interested.card.title')}
          items={interestedInVideos}
        />
      </div>
    </>
  );
}

export default MyCourseCard;
