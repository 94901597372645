import React from 'react';

import useVideo from './useVideo';
import useGeneral from './useGeneral';

function useZoomVideo() {
  const { video } = useVideo();
  const { firstName, lastName } = useGeneral();
  const { zoomId, meetingStatus, thumbnail } = video;

  return { zoomId, userName: `${firstName} ${lastName}`, isVisible: zoomId, meetingStatus, thumbnail };
}

export default useZoomVideo;
