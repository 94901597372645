import axios from './axiosConfig';
import { QUIZZES_ENDPOINT, SUBMIT_QUIZ_ANSWER, RETAKE_QUIZ, DELETE_QUIZ_PROGRESS } from '../constants/routes';

export function fetchQuizApi(quizId) {
  return axios().get(QUIZZES_ENDPOINT.replace(':quizId', quizId));
}

export function sendResponseApi(quizId, questionId, answerId) {
  return axios().post(SUBMIT_QUIZ_ANSWER.replace(':quizId', quizId).replace(':questionId', questionId), {
    answers: answerId.toString(),
  });
}

export function sendQuizRetakeApi(quizId) {
  return axios().post(RETAKE_QUIZ.replace(':quizId', quizId));
}

export function sendDeleteQuizProgressApi(quizId) {
  return axios().delete(DELETE_QUIZ_PROGRESS.replace(':quizId', quizId));
}
