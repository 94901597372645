import React from 'react';
import { useTranslation } from 'react-i18next';

import style from 'assets/scss/components/Header.module.scss';
import useHeader from 'hooks/useHeader';
import useGeneral from 'hooks/useGeneral';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import MobileNavItem from './MobileNavItem';
import useProfileDropdown from 'hooks/useProfileDropdown';
import { DASHBOARD_PAGE, PARTNERS_PAGE } from '../../constants/routes';

export const MobileNav = () => {
  const { headerLinks, isAuthenticated, initials, avatar } = useHeader();
  const { t } = useTranslation();
  const {
    headerLinksItem,
    headerLinksContainer,
    profileInitialsBurger,
    headerLinksElement,
    headerLinksLogout,
    headerLinksIndent,
  } = convertKeysToCamelCase(style);
  const { handleLogout } = useProfileDropdown();
  const { handleRedirect } = useGeneral();

  return (
    <>
      <span className={`${headerLinksContainer}`}>
        <span className={headerLinksItem} onClick={() => handleRedirect('/')}>
          {t('header.links.start')}
        </span>
      </span>
      {headerLinks.map((link) =>
        'subLinks' in link ? (
          <MobileNavItem subLinks={link.subLinks} translation={link.translation} key={link.translation} />
        ) : 'external' in link ? (
          <a
            className={`${headerLinksContainer} ${headerLinksElement}`}
            key={link.translation}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={headerLinksItem}>{t(`header.links.${link.translation}`)}</span>
          </a>
        ) : (
          <span
            key={link.translation}
            className={`${headerLinksContainer} ${headerLinksElement} ${headerLinksIndent}`}
            onClick={() => handleRedirect(PARTNERS_PAGE)}
          >
            {t(`${link.translation}`)}
          </span>
        ),
      )}
      {isAuthenticated && (
        <>
          <span
            className={`${headerLinksContainer} ${headerLinksElement} ${headerLinksIndent}`}
            onClick={() => handleRedirect(DASHBOARD_PAGE)}
          >
            {t('header.links.my.profile')}
            <div className={profileInitialsBurger}>
              {avatar ? <img src={avatar} alt="avatar" /> : <p>{initials}</p>}
            </div>
          </span>
          <span
            className={`${headerLinksContainer} ${headerLinksElement} ${headerLinksLogout} ${headerLinksIndent}`}
            onClick={() => handleLogout()}
          >
            {t('header.links.logout')}
          </span>
        </>
      )}
    </>
  );
};
