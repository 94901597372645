import { getCurrentTime, getCurrentTimeForCreate } from '../helpers/baseHelper';
import { convertStringDateToVideoCardDate } from 'helpers/expertsTalksHelper';
import { TRAINING_PAGE_FILTERS } from '../constants/general';

export function transformRegisterFormData(data, redirectTo) {
  const { gender, institute, ...rest } = data;
  const [first] = gender;

  return {
    ...rest,
    gender: first.value,
    institution: institute,
    redirectTo,
  };
}

export function transformNotes(notes) {
  if (!notes) {
    return notes;
  }

  return notes.map((note) => {
    const { timestamp } = note;
    if (!timestamp) {
      return note;
    }

    const time = timestamp.split(':');
    const [hours, minutes, seconds] = time;
    const fullTimestamp = +hours * 60 * 60 + +minutes * 60 + +seconds;

    return {
      ...note,
      timestamp: getCurrentTime(fullTimestamp),
    };
  });
}

export function mapExpertTalks(items) {
  return items.map((item) => {
    const { startTime, ...rest } = item;

    return {
      ...rest,
      date: convertStringDateToVideoCardDate(startTime),
    };
  });
}

export function transformVideoFilters(filters) {
  const newFilters = {};
  TRAINING_PAGE_FILTERS.map((key) => {
    newFilters[key] = filters[key].map((item) => {
      const { id, name } = item;

      return { label: name, value: id };
    });
  });

  return newFilters;
}

export function transformCourses(courses) {
  const newCourses = [];
  if (!courses) {
    return newCourses;
  }

  courses.forEach((course) => {
    const courseVideos = course.videos.map((video) => {
      return {
        ...video,
        courseId: course.id,
        courseIsLive: course.isLive,
        bookingStatus: course.bookingStatus,
        access: course.access,
      };
    });
    newCourses.push({ ...course, videos: courseVideos });
  });

  return newCourses;
}

export function transformDashboardVideos(videos) {
  const newVideos = [];

  videos.forEach((video) => {
    const { title, shortDescription, logo } = video;
    if (!video.firstVideo) {
      return;
    }

    newVideos.push({
      title,
      id: video.firstVideo.id,
      thumbnail: logo,
      generalInformation: shortDescription,
      access: video.access,
      courseId: video.id,
      label: video.label,
    });
  });

  return newVideos;
}

export function transformDPreviouslyWatchedVideos(videos) {
  const newVideos = [];

  videos.forEach((video) => {
    const { title, shortDescription, logo } = video;
    if (!video.firstVideo) {
      return;
    }

    newVideos.push({
      title,
      id: video.firstVideo.id,
      thumbnail: logo,
      generalInformation: shortDescription,
      access: video.access,
      courseId: video.id,
      courseObject: video,
      courseIsLive: video.isLive,
      bookingStatus: video.bookingStatus,
      label: video.label,
    });
  });

  return newVideos;
}

export function transformCoachingVideo(video, t) {
  const { coursePlan, upcomingTutoriums } = video;
  if (!coursePlan) {
    return video;
  }

  return {
    ...video,
    coursePlan: coursePlan.map((item) => ({ ...item, lessonTime: getCurrentTimeForCreate(item?.videosRuntime) })),
    upcomingTutoriums: upcomingTutoriums.map((item) => {
      const { startTime, endTime } = item;

      const [startDate, startHours] = startTime.split(' ');
      const [endDate, endHours] = endTime.split(' ');

      const [startYear, startMonth, startDay] = startDate.split('-');
      const [startHour, startMinutes] = startHours.split(':');
      const [endHour, endMinutes] = endHours.split(':');

      return {
        ...item,
        date: `${startDay}.${startMonth}.${startYear}`,
        hours: `${startHour}:${startMinutes}-${endHour}:${endMinutes} Uhr`,
      };
    }),
  };
}

export function transformPartnerBannerFile(bannerFileDescription, bannerFileName, bannerFile) {
  const download = [
    {
      id: 1,
      description: bannerFileDescription,
      name: '→ ' + bannerFileName,
      path: bannerFile,
      directDownload: true,
      nameStyle: 'download-title',
      descriptionStyle: 'download-description',
    },
  ];
  return download;
}

export function transformPartnerMaterials(materials) {
  return materials.map((material) => {
    let changedMaterial = { ...material, nameStyle: 'download-title', descriptionStyle: 'download-description' };
    changedMaterial.name = '→ ' + changedMaterial.name;
    return changedMaterial;
  });
}

export function transformCoachingVideoStrategy({ coachingId, coachingTitle, ...video }) {
  return {
    ...video,
    coachingId,
    coachingTitle,
    topicId: coachingId,
    topicTitle: coachingTitle,
  };
}

export function transformClipsVideoStrategy({ klipId, klipTitle, ...video }) {
  return {
    ...video,
    klipId,
    klipTitle,
    topicId: klipId,
    topicTitle: klipTitle,
  };
}
