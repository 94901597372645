import React from 'react';
import { useTranslation } from 'react-i18next';
import style from 'assets/scss/components/pages/RegistrationConfirmPage.module.scss';

function NewsletterConfirmPage() {
  const { wrapper } = style;
  const { t } = useTranslation();

  return (
    <main className="main">
      <div className={wrapper}>
        <h2>Vielen Dank für Ihre Anmeldung zum Newlsetter der CARE & COACH Akademie!</h2>
        <br></br>
        <p>Sie erhalten in Kürze eine E-Mail zur Bestätigung.</p>
        <p>Bitte klicken Sie auf den Link in der E-Mail um Ihre Anmeldung zu bestätigen.</p>
      </div>
    </main>
  );
}

export default NewsletterConfirmPage;
