import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from 'assets/scss/components/ContactFormCard.module.scss';
import image from '../assets/images/bubbles.svg';
import useContact from '../hooks/useContact';

function ContactFormCard() {
  const { t } = useTranslation();
  const { submitContactForm, register, handleSubmit, setIsChecked, isChecked, errors } = useContact();
  const {
    wrapper,
    wrapperBubble,
    form,
    formError,
    formHeader,
    formContent,
    formContentInputSmall,
    formContentInputLarge,
    gdprAcceptance,
    gdprCheck,
  } = convertKeysToCamelCase(style);

  return (
    <section className={`${wrapper} contact-form max-width`}>
      <div className={wrapperBubble}>
        <img src={image} alt="img" />
      </div>
      <form className={form} onSubmit={handleSubmit(submitContactForm)}>
        <div className={formHeader}>
          <h2>{t('contact.form.title')}</h2>
          <p>{t('contact.form.description')}</p>
        </div>
        <div className={formContent}>
          <div className={formContentInputSmall}>
            <span>
              <label>{t('contact.form.input.firstName.label')} *</label>
              <input placeholder={t('contact.form.input.firstName.placeholder')} name="firstName" ref={register} />
              {errors?.firstName && <span className={formError}>{t(`validation.${errors?.firstName?.message}`)}</span>}
            </span>
            <span>
              <label>{t('contact.form.input.lastName.label')} *</label>
              <input placeholder={t('contact.form.input.lastName.placeholder')} name="lastName" ref={register} />
              {errors?.lastName && <span className={formError}>{t(`validation.${errors?.lastName?.message}`)}</span>}
            </span>
          </div>
          <div className={formContentInputLarge}>
            <span>
              <label>{t('contact.form.input.email.label')} *</label>
              <input placeholder={t('contact.form.input.email.placeholder')} name="email" ref={register} />
              {errors?.email && <div className={formError}>{t(`validation.${errors?.email?.message}`)}</div>}
            </span>
            <span>
              <label>{t('contact.form.input.message.label')} *</label>
              <textarea
                placeholder={t('contact.form.input.message.placeholder')}
                rows="4"
                name="message"
                ref={register}
              />
              {errors?.message && <div className={formError}>{t(`validation.${errors?.message?.message}`)}</div>}
            </span>
          </div>
          <div className={gdprAcceptance}>
            <input
              name="gdpr"
              type="checkbox"
              onChange={() => setIsChecked(!isChecked)}
              checked={isChecked}
              value={isChecked}
            />
            <div>{t('contact.form.gdpr_text')}</div>
          </div>
          {!isChecked && <div className={gdprCheck}>{t(`validation.required`)}</div>}
          <button type="submit">{t('contact.form.button')}</button>
        </div>
      </form>
    </section>
  );
}

export default ContactFormCard;
