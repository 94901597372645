import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-dropdown-select';
import { nanoid } from 'nanoid';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from 'assets/scss/components/VideoDetailsCard.module.scss';
import useVideoCard from '../hooks/useVideoCard';
import useGeneral from '../hooks/useGeneral';
import { TRAINING_PAGE } from '../constants/routes';

function VideoDetailsCard() {
  const { t } = useTranslation();
  const { handleCourseChange, courses, selectedCourse, video } = useVideoCard();
  const { handleRedirect } = useGeneral();
  const { wrapper, coursesWrapper, coursesButton, coursesButtonIcon, coursesSelect } = convertKeysToCamelCase(style);
  if (!video || !video?.vimeoSrc) {
    return null;
  }

  return (
    <div className={wrapper}>
      <p>{t('video.details.page.videoSelect.title')}</p>
      <div className={coursesWrapper}>
        <Select
          values={selectedCourse}
          options={courses}
          onChange={(values) => handleCourseChange(values)}
          searchable={false}
          className={coursesSelect}
        />
        <button className={`primary ${coursesButton}`} onClick={() => handleRedirect(TRAINING_PAGE)}>
          <span>{t('video.details.page.videoSelect.button')}</span>
          <span className={coursesButtonIcon}>
            {Array(3)
              .fill(0)
              .map(() => (
                <span key={nanoid()}>
                  {Array(3)
                    .fill(0)
                    .map(() => (
                      <span key={nanoid()} />
                    ))}
                </span>
              ))}
          </span>
        </button>
      </div>
    </div>
  );
}

export default VideoDetailsCard;
