import axios from '../api/axiosConfig';
import {
  USER_CREDENTIALS,
  USER_PROFILE,
  USER_PROFILE_AVATAR,
  MY_COURSES,
  MY_COACHINGS,
  MY_CLIPS,
  TERMINATE_COURSE_BOOKING_ENDPOINT,
  GET_COMPRESSED_FILE_ENDPOINT,
  TERMINATE_WAITING_LIST_BOOKING_ENDPOINT,
  DESTROY_PROFILE,
} from '../constants/routes';

export function updateCredentialsApi(data) {
  return axios().put(USER_CREDENTIALS, data);
}

export function getUserProfileApi() {
  return axios().get(USER_PROFILE);
}

export function getCoursesApi() {
  return axios().get(MY_COURSES);
}

export function getCoachingsApi() {
  return axios().get(MY_COACHINGS);
}

export function getClipsApi() {
  return axios().get(MY_CLIPS);
}

export function updateUserProfileApi(data) {
  return axios().put(USER_PROFILE, data);
}

export function updateAvatarApi(files) {
  const formData = new FormData();
  const [file] = files;
  formData.append('avatar', file, file.name);
  const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

  return axios().post(USER_PROFILE_AVATAR, formData, headers);
}

export function terminateBookingApi(courseId) {
  return axios().delete(TERMINATE_COURSE_BOOKING_ENDPOINT.replace(':id', courseId));
}

export function terminateWaitingListBookingApi(courseId) {
  return axios().delete(TERMINATE_WAITING_LIST_BOOKING_ENDPOINT.replace(':id', courseId));
}

export function downloadCompressedFileApi(courseId) {
  return axios().get(GET_COMPRESSED_FILE_ENDPOINT.replace(':id', courseId), { responseType: 'blob' });
}

export function destroyProfileApi() {
  return axios().delete(DESTROY_PROFILE);
}
