import React from 'react';
import PropTypes from 'prop-types';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from 'assets/scss/components/pages/HomePage.module.scss';
import { Container, Row, Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import useHeader from 'hooks/useHeader';
import { REGISTER_TYPE } from 'constants/login';

const HeroSection = ({ title, description, image }) => {
  const { t } = useTranslation();
  const { handleModalOpen } = useHeader();
  const { heroSection } = convertKeysToCamelCase(style);
  const { isUserLoggedIn } = useIsAuthenticated();

  return (
    <section className={heroSection}>
      <Container className="container-fluid">
        <Row>
          <Col xs={12} md={6}>
            <h1 className="headline1">{title}</h1>
            <p className="highlight">{description}</p>
            {!isUserLoggedIn && (
              <button className="primary" onClick={() => handleModalOpen(true, REGISTER_TYPE)}>
                {t('general.buttons.register_now')}
              </button>
            )}
          </Col>
          <Col xs={12} md={6}>
            <img src={image} alt="Hero" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

HeroSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequireds,
};

export default HeroSection;
