import axios from './axiosConfig';

import { CLIPS_ALL, CLIPS_BOOKABLE_DETAIL } from 'constants/routes';

export function getBookableClipsApi() {
  return axios().get(CLIPS_ALL);
}

export function getBookableClipsDetailsApi(id) {
  return axios().get(CLIPS_BOOKABLE_DETAIL.replace(':klipId', id));
}
