import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import useYupValidationResolver from './useYupValidationResolver';
import { getEmailValidationSchema } from '../helpers/validationSchemaHelper';
import { EMAIL_DEFAULT_VALUES } from '../constants/login';
import { newsletterApi } from '../api/publicPagesApi';
import useGeneral from './useGeneral';
import { NEWSLETTER_CONFIRM_PAGE } from 'constants/routes';
import { useHistory } from 'react-router';

function useStayInformedCard() {
  const { t } = useTranslation();
  const { showToasts } = useGeneral();
  const resolver = useYupValidationResolver(getEmailValidationSchema());
  const { register, handleSubmit, errors, reset } = useForm({ resolver, defaultValues: EMAIL_DEFAULT_VALUES });
  const history = useHistory();

  async function handleSubmitForm(formData) {
    try {
      await newsletterApi(formData);

      toast.success(t('stay.informed.success.message'));
      reset();

      history.push(NEWSLETTER_CONFIRM_PAGE);
    } catch (error) {
      showToasts(error);
    }
  }

  return { register, handleSubmit: handleSubmit(handleSubmitForm), errors };
}

export default useStayInformedCard;
