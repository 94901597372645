export const PROFILE_FORM_DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  address: '',
  postalCode: '',
  city: '',
  institution: '',
  gender: '',
};

export const CREDENTIALS_FORM_DEFAULT_VALUES = {
  email: '',
  currentPassword: '',
};

export const PROFILE_ITEM_TYPES = {
  myProfile: 'MY_PROFILE',
  myCourse: 'MY_COURSE',
};

export const PROFILE_ITEMS = [
  {
    label: 'profile.course',
    value: PROFILE_ITEM_TYPES.myCourse,
    selected: false,
  },
  {
    label: 'dashboard.welcome.card.profile.link',
    value: PROFILE_ITEM_TYPES.myProfile,
    selected: true,
  },
];
