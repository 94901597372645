import React from 'react';
import PropTypes from 'prop-types';
import Head from './Head';

const ContentLayout = ({ children, title, description }) => {
  return (
    <main className="main">
      <Head title={title} description={description} />
      {children}
    </main>
  );
};

ContentLayout.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ContentLayout;
