import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';
import { Link } from 'react-router-dom';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import useHeader from 'hooks/useHeader';
import style from 'assets/scss/components/Header.module.scss';
import navImage from 'assets/images/element-navigation.svg';
import { useDispatch } from 'react-redux';
import { SET_IS_REGISTER_TO_EVENT_MODAL_OPEN } from 'constants/actionTypes';

const PopoverMenuItem = ({ translation, url, subtitle, onMenuClick }) => {
  const { t } = useTranslation();

  const {
    headerLinksPopoverItem,
    headerLinksPopoverItemFigure,
    headerLinksPopoverItemDetails,
    headerLinksPopoverItemDetailsHeader,
  } = convertKeysToCamelCase(style);
  const dispatch = useDispatch();

  return (
    <Link
      to={url}
      className={headerLinksPopoverItem}
      onClick={() => {
        onMenuClick();
        dispatch({ type: SET_IS_REGISTER_TO_EVENT_MODAL_OPEN, payload: false });
      }}
    >
      <img src={navImage} alt="Schulungen" className={headerLinksPopoverItemFigure} />
      <div className={headerLinksPopoverItemDetails}>
        <p className={headerLinksPopoverItemDetailsHeader}>{t(`header.links.${translation}`)}</p>
        <p>{t(`header.links.${subtitle}`)}</p>
      </div>
    </Link>
  );
};

const PopoverMenu = ({ link, onMenuClick }) => {
  const { headerLinksPopover } = convertKeysToCamelCase(style);

  if (!link) return null;

  return (
    <div className={headerLinksPopover}>
      {link.subLinks.map((subLink) => (
        <PopoverMenuItem
          onMenuClick={onMenuClick}
          translation={subLink.translation}
          url={subLink.url}
          subtitle={subLink.subtitle}
        />
      ))}
    </div>
  );
};

export const HeaderNav = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const { t } = useTranslation();
  const { headerLinks: headerLinksStyle, headerLinksItem } = convertKeysToCamelCase(style);
  const { headerLinks } = useHeader();
  const dispatch = useDispatch();

  const handleClickOutside = () => {
    setIsPopoverOpen(false);
    setSelectedLink(null);
  };

  const handleLinkClick = (link) => {
    setIsPopoverOpen(true);
    setSelectedLink(link);
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom']}
        content={<PopoverMenu link={selectedLink} onMenuClick={() => handleClickOutside()} />}
        containerStyle={{ zIndex: 1000 }}
        onClickOutside={handleClickOutside}
      >
        <div className={headerLinksStyle}>
          {headerLinks.map((link) =>
            'subLinks' in link ? (
              <span key={link.translation} onClick={() => handleLinkClick(link)}>
                <span>
                  <span className={headerLinksItem}>{t(`header.links.${link.translation}`)}</span>
                  <span></span>
                  {link.subLinks && (
                    <i
                      className={`icon icon-${
                        selectedLink && selectedLink.translation === link.translation ? 'up' : 'down'
                      }-open-big margin-left-10`}
                    />
                  )}
                </span>
              </span>
            ) : 'external' in link ? (
              <a
                className={headerLinksStyle}
                key={link.translation}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={headerLinksItem}>{t(`header.links.${link.translation}`)}</span>
              </a>
            ) : (
              <Link
                key={link.translation}
                className={headerLinksStyle}
                to={link.url}
                onClick={() => dispatch({ type: SET_IS_REGISTER_TO_EVENT_MODAL_OPEN, payload: false })}
              >
                <span className={headerLinksItem}>{t(`header.links.${link.translation}`)}</span>
              </Link>
            ),
          )}
        </div>
      </Popover>
    </>
  );
};
