import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SET_IS_LOADING, SET_STATE } from '../constants/actionTypes';
import { getResponseErrors } from '../helpers/baseHelper';

function useGeneral() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    user: { firstName, lastName },
  } = useSelector((store) => store.defaultReducer);

  const handleRedirect = (url, reload) => {
    if (reload) {
      dispatch({ type: SET_IS_LOADING, payload: true });
    }

    history.push(url);
    if (reload) {
      history.go(0);
    }

    dispatch({
      type: SET_STATE,
      payload: { isBurgerMenuOpen: false, isLoginModalOpen: false },
    });
  };

  function showToasts(error) {
    const errors = mapErrors(error, t);
    errors.forEach((error) => {
      toast.error(error);
    });
  }

  function mapErrors(error) {
    const errorData = error?.response?.data;
    if (!errorData) {
      return [t('error.response.status.general')];
    }

    return getResponseErrors(errorData);
  }

  return {
    handleRedirect,
    firstName,
    lastName,
    showToasts,
  };
}

export default useGeneral;
