import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import dashCircle from '../../../assets/images/dash-circle.svg';
import plusCircle from '../../../assets/images/plus-circle.svg';
import style from '../../../assets/scss/components/CoachingPageCollapse.module.scss';
import useGeneral from 'hooks/useGeneral';
import { MODULE_PAYMENT_FIRST_STEP, COACHING_MODULE_DETAILS_PAGE, CLIPS_MODULE_DETAILS_PAGE } from 'constants/routes';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import useHeader from 'hooks/useHeader';
import { REGISTER_TYPE } from 'constants/login';

function CoachingPageCollapse(props) {
  const { number, header, description, isPayable, isPaidFor, moduleId, topicId, type } = props;
  const {
    grayLine,
    module,
    dropLine,
    collapse,
    text,
    image,
    moduleTitle,
    moduleBook,
    collapsibleArea,
  } = convertKeysToCamelCase(style);
  const { t } = useTranslation();
  const { isUserLoggedIn } = useIsAuthenticated();
  const [isVisible, setIsVisible] = useState(false);
  const { handleRedirect } = useGeneral();
  const { handleModalOpen } = useHeader();

  const handleClick = () => {
    if (!isUserLoggedIn) {
      handleModalOpen(true, REGISTER_TYPE, true);
    } else {
      if (!isPaidFor) {
        handleRedirect(MODULE_PAYMENT_FIRST_STEP.replace(':moduleId', moduleId));
      } else {
        handleRedirect(COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', topicId));
      }
    }
  };

  return (
    <div>
      <div className={grayLine} />
      <div
        className={collapsibleArea}
        onClick={() => {
          setIsVisible(!isVisible);
        }}
      >
        <div className={dropLine}>
          <p className={`${module} small`}>
            {t('coachings.detail.module')} {number}
          </p>
          <img src={isVisible ? dashCircle : plusCircle} className={image} />
        </div>
        <h4 className={moduleTitle}>{header}</h4>
      </div>
      <Collapse isOpened={isVisible} theme={{ collapse }}>
        <p className={`${text} highlight`}>{description}</p>
      </Collapse>
      {isPayable && (
        <button className={`${moduleBook} ${isPaidFor ? 'primary' : 'secondary'}`} onClick={handleClick}>
          {isPaidFor ? t('coachings.start.now') : t('coachings.modules.book', { number })}
        </button>
      )}
      {type === 'clips' && (
        <button className="primary" onClick={handleClick}>
          {t('coachings.start.now')}
        </button>
      )}
    </div>
  );
}

export default CoachingPageCollapse;
