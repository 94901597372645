import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useRegisterToEvent from '../hooks/useRegisterToEvent';
import RegisterToEventForm from './RegisterToEventForm';
import style from '../assets/scss/components/RegisterToEvent.module.scss';
import RegisterToEventSuccess from './RegisterToEventSuccess';

function RegisterToEvent() {
  const { course, closeRegisterToEvent, isRegistrationSuccess, resetSucces } = useRegisterToEvent();

  const { t } = useTranslation();
  const { wrapper, closeWrapper, courseTitle, courseShortDescription } = convertKeysToCamelCase(style);

  useEffect(() => {
    // Reset success state when component unmounts
    return () => {
      resetSucces();
    };
  }, []);

  if (!course) {
    return null;
  }
  const { title, shortDescription } = course;

  return (
    <div className={wrapper}>
      <div>
        <p className={closeWrapper} onClick={closeRegisterToEvent}>
          {t('general.buttons.backToWebPage2')}
        </p>
        <h1 className={courseTitle}>{title}</h1>
        <p className={courseShortDescription}>{shortDescription}</p>
      </div>
      {isRegistrationSuccess ? <RegisterToEventSuccess title={title} /> : <RegisterToEventForm />}
    </div>
  );
}

export default RegisterToEvent;
