import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { forgotPasswordApi, logoutApi, registerApi, resetPasswordApi, signInApi } from '../api/loginApi';
import { SET_IS_LOADING, SET_IS_LOGOUT_MODAL_OPEN, SET_STATE } from '../constants/actionTypes';
import { SIGN_IN_TYPE } from '../constants/login';
import { transformRegisterFormData } from '../transformers/baseTransformer';
import { DASHBOARD_PAGE, HOME_PAGE, MY_PROFILE_PAGE, REGISTRATION_CONFIRMATION_PAGE } from '../constants/routes';
import useGeneral from './useGeneral';

function useLogin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { showToasts } = useGeneral();
  const { t } = useTranslation();
  const { token, isLogoutModalOpen } = useSelector((store) => store.defaultReducer);

  const handleLogin = (type) => {
    dispatch({ type: SET_STATE, payload: { isLoginModalOpen: true, loginModalType: type || SIGN_IN_TYPE } });
  };

  const handleLogoutModal = (isOpen) => {
    dispatch({ type: SET_IS_LOGOUT_MODAL_OPEN, payload: isOpen });
  };

  async function submitRegister(formData, withRedirect = false) {
    dispatch({ type: SET_IS_LOADING, payload: true });
    const redirectURL = window.location.href;
    try {
      const postData = transformRegisterFormData(formData, withRedirect ? redirectURL : undefined);
      const { data } = await registerApi(postData);

      toast.success(data?.message);
      history.push(REGISTRATION_CONFIRMATION_PAGE);
    } catch (error) {
      showToasts(error);
    } finally {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      dispatch({
        type: SET_STATE,
        payload: { isLoginModalOpen: false, isBurgerMenuOpen: false, isLogoutModalOpen: false, isLoading: false },
      });
    }
  }

  async function submitSignIn(formData) {
    dispatch({ type: SET_IS_LOADING, payload: true });

    const searchParams = new URLSearchParams(window.location.search);
    const redirectTo = searchParams.get('redirectTo');

    try {
      const { data } = await signInApi(formData);
      const { user, token } = data;

      dispatch({ type: SET_STATE, payload: { user, token } });
      toast.success(t('login.success.message'));
      if (!redirectTo) {
        history.push(DASHBOARD_PAGE);
      } else {
        window.location.href = decodeURIComponent(redirectTo);
      }
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      dispatch({
        type: SET_STATE,
        payload: { isLoginModalOpen: false, isLogoutModalOpen: false },
      });
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function submitForgotPassword(formData) {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data } = await forgotPasswordApi(formData);

      toast.success(data.message);
      history.push(DASHBOARD_PAGE);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      dispatch({
        type: SET_STATE,
        payload: { isLoginModalOpen: false, isLogoutModalOpen: false },
      });
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function submitResetPassword(formData) {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { search } = location;
      const { data } = await resetPasswordApi({ ...formData, token: search.split('token=').pop() });

      toast.success(data?.message);
      history.push(DASHBOARD_PAGE);
    } catch (error) {
      showToasts(error);
    } finally {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      dispatch({
        type: SET_STATE,
        payload: { isLoginModalOpen: false, isBurgerMenuOpen: false, isLogoutModalOpen: false, isLoading: false },
      });
    }
  }

  async function handleLogout() {
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      await logoutApi();

      toast.success(t('logout.success.message'));
      dispatch({ type: SET_STATE, payload: { user: {}, token: '' } });
      history.push(HOME_PAGE);
    } catch (error) {
      showToasts(error);
    } finally {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      dispatch({
        type: SET_STATE,
        payload: { isLoginModalOpen: false, isBurgerMenuOpen: false, isLogoutModalOpen: false, isLoading: false },
      });
    }
  }

  return {
    submitSignIn,
    submitRegister,
    submitForgotPassword,
    submitResetPassword,
    handleLogin,
    handleLogout,
    isAuthenticated: token,
    isLogoutModalOpen,
    handleLogoutModal,
  };
}

export default useLogin;
