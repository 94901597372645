import React from 'react';

import { Container } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import usePartnersByType from 'hooks/usePartnersByType';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import { PARTNERS_OVERVIEW_BREADCRUMBS } from 'constants/general';
import ContactSection from 'components/common/sections/ContactSection';

import PartnerSection from '../Partner/PartnerSection';

import style from '../../assets/scss/components/pages/Cooperations.module.scss';

import Breadcrumbs from './Breadcrumbs';

function PartnersPage() {
  const { t } = useTranslation();
  const { breadcrumbSection, headerSectionTitle, headerSection } = convertKeysToCamelCase(style);

  const { partners } = usePartnersByType();

  return (
    <main>
      <Container>
        <section className={breadcrumbSection}>
          <Breadcrumbs items={PARTNERS_OVERVIEW_BREADCRUMBS} />
        </section>
        <section className={headerSection}>
          <h1 className={`headline1 ${headerSectionTitle}`}>{t('partners.page.title')}</h1>
          <p className="highlight">{t('partners.page.description')}</p>
        </section>
        {partners &&
          Object.keys(partners)
            .filter((key) => partners[key])
            .map((key) => <PartnerSection key={key} partners={partners[key]} type={key} />)}
      </Container>
      <ContactSection />
    </main>
  );
}

export default PartnersPage;
