import React from 'react';
import * as ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { MODAL_STYLE_BLURY } from '../constants/modal';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from '../assets/scss/components/CancelQuizModal.module.scss';

function CancelQuizModal(props) {
  const { isVisible, onClose, onRedirect } = props;
  const { t } = useTranslation();
  const { wrapper, title, description } = convertKeysToCamelCase(style);

  return (
    <ReactModal isOpen={isVisible} style={MODAL_STYLE_BLURY} onRequestClose={onClose}>
      <div className={wrapper}>
        <p className={title}>{t('quizzes.cancel.modal.title')}</p>
        <p className={description}>{t('quizzes.cancel.modal.description')}</p>
        <div>
          <button className="secondary-red" onClick={onClose}>
            {t('quizzes.cancel.modal.back')}
          </button>
          <button className="secondary" onClick={onRedirect}>
            {t('quizzes.cancel.modal.quit')}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default CancelQuizModal;
