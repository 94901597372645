import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SET_IS_PROFILE_DROPDOWN_OPEN } from 'constants/actionTypes';

function useProfileDropdownCloser(ref) {
  const dispatch = useDispatch();

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch({ type: SET_IS_PROFILE_DROPDOWN_OPEN, payload: false });
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

function ProfileDropdownCloser(props) {
  const wrapperRef = useRef(null);
  useProfileDropdownCloser(wrapperRef);

  return <div ref={wrapperRef}>{props.children}</div>;
}

export default ProfileDropdownCloser;
