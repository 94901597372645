import axios from '../api/axiosConfig';
import {
  COURSE_DATES_BOOK_ENDPOINT,
  COURSE_DATES_INTERESTED_IN_ENDPOINT,
  COURSE_DATES_RESERVE_ENDPOINT,
  REQUEST_ACCESS_TO_COURSE_ENDPOINT,
} from '../constants/routes';

export function bookCourseApi(dateId, payload) {
  return axios().post(COURSE_DATES_BOOK_ENDPOINT.replace(':dateId', dateId), payload);
}

export function reserveCourseApi(dateId, payload) {
  return axios().post(COURSE_DATES_RESERVE_ENDPOINT.replace(':dateId', dateId), payload);
}

export function interestedInCourseApi(courseId, code) {
  return axios().post(COURSE_DATES_INTERESTED_IN_ENDPOINT.replace(':courseId', courseId), { code });
}

export function requestCourseAccessApi(courseId) {
  return axios().post(REQUEST_ACCESS_TO_COURSE_ENDPOINT.replace(':courseId', courseId));
}
