import React from 'react';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from 'assets/scss/components/SocialBar.module.scss';
import useSocialLinks from 'hooks/useSocialLinks';

function SocialBar() {
  const { socialWrapper, socialItems, socialItemsLogo } = convertKeysToCamelCase(style);
  const { socialLinks } = useSocialLinks();

  return (
    <div className={`${socialWrapper}`}>
      <div className={socialItems}>
        {socialLinks.map((link) => (
          <a key={link.id} href={link.url} className={socialItems}>
            <img
              src={require(`assets/images/${link.slug}Socials.svg`).default}
              className={socialItemsLogo}
              alt={link.name}
            />
          </a>
        ))}
      </div>
    </div>
  );
}

export default SocialBar;
