import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import useLogin from './useLogin';
import useYupValidationResolver from './useYupValidationResolver';
import { getSignInValidationSchema } from '../helpers/validationSchemaHelper';
import { SIGN_IN_DEFAULT_VALUES } from '../constants/login';

function useSignIn() {
  const [forgotPassword, setForgotPassword] = useState(false);
  const resolver = useYupValidationResolver(getSignInValidationSchema(forgotPassword));

  const { register, handleSubmit, errors, reset } = useForm({ resolver, defaultValues: SIGN_IN_DEFAULT_VALUES });
  const { submitSignIn, submitForgotPassword } = useLogin();

  function handleSubmitForm(data) {
    reset();
    forgotPassword ? submitForgotPassword(data) : submitSignIn(data);
  }

  return { handleSubmitForm: handleSubmit(handleSubmitForm), register, errors, forgotPassword, setForgotPassword };
}

export default useSignIn;
