import { initialState } from '../initialState';
import {
  SET_IS_LOGIN_MODAL_OPEN,
  RESET_STATE_TO_DEFAULT,
  SET_STATE,
  SET_IS_LOADING,
  SET_FILTERS,
  SET_SELECTED_FILTERS,
  SET_IS_BURGER_MENU_OPEN,
  SET_IS_LOGOUT_MODAL_OPEN,
  SET_IS_PROFILE_DESTROY_MODAL_OPEN,
  SET_CURRENT_VIDEO_TIME,
  SET_VIDEO,
  UPDATE_VIDEO_NOTES,
  SET_USER,
  SET_IS_PROFILE_DROPDOWN_OPEN,
  SET_SELECTED_PROFILE_ITEM,
  SET_START_VIDEO,
  SET_IS_COOKIE_BAR_OPEN,
  SET_IS_REGISTER_TO_EVENT_MODAL_OPEN,
  SET_COURSE,
  SET_IS_REGISTRATION_SUCCESS,
  SET_IS_CONFIRM_TERMINATE_BOOKING_MODAL_OPEN,
  SET_MY_COURSES,
  SET_VIDEO_DURATION,
  SET_COURSES,
  SHOW_CANCEL_PAYMENT_MODAL,
  SET_PREFERRED_PAYMENT_METHOD,
  OPEN_MODAL_WITH_REDIRECT,
  OPEN_MODAL_WITHOUT_REDIRECT,
} from '../../constants/actionTypes';

function redux(state = initialState, action) {
  const { type, payload, exact } = action;

  switch (type) {
    case SET_STATE: {
      return {
        ...state,
        ...payload,
      };
    }
    case SET_IS_REGISTER_TO_EVENT_MODAL_OPEN: {
      return {
        ...state,
        isRegisterToEvent: payload,
      };
    }
    case SET_IS_REGISTRATION_SUCCESS: {
      return {
        ...state,
        isRegistrationSuccess: payload,
      };
    }
    case SET_IS_CONFIRM_TERMINATE_BOOKING_MODAL_OPEN: {
      return {
        ...state,
        terminateBookingData: payload,
      };
    }
    case SET_MY_COURSES: {
      return {
        ...state,
        myCourses: payload,
      };
    }
    case SET_COURSE: {
      return {
        ...state,
        course: payload,
      };
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case SET_CURRENT_VIDEO_TIME: {
      return {
        ...state,
        currentVideoTime: payload,
      };
    }
    case SET_VIDEO_DURATION: {
      return {
        ...state,
        video: { ...state.video, duration: payload },
      };
    }
    case SET_START_VIDEO: {
      return {
        ...state,
        startVideo: payload,
      };
    }
    case UPDATE_VIDEO_NOTES: {
      return {
        ...state,
        video: {
          ...state.video,
          notes: payload,
        },
      };
    }
    case SET_IS_LOGIN_MODAL_OPEN: {
      return {
        ...state,
        isLoginModalOpen: payload,
      };
    }
    case SET_VIDEO: {
      return {
        ...state,
        video: payload,
      };
    }
    case SET_IS_BURGER_MENU_OPEN: {
      return {
        ...state,
        isBurgerMenuOpen: payload,
      };
    }
    case SET_IS_LOGOUT_MODAL_OPEN: {
      return {
        ...state,
        isLogoutModalOpen: payload,
      };
    }
    case SET_IS_PROFILE_DESTROY_MODAL_OPEN: {
      return {
        ...state,
        isProfileDestroyModalOpen: payload,
      };
    }
    case SET_FILTERS: {
      return {
        ...state,
        filters: payload,
      };
    }
    case SET_SELECTED_FILTERS: {
      return {
        ...state,
        selectedFilters: payload,
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: payload,
      };
    }
    case SET_IS_PROFILE_DROPDOWN_OPEN: {
      return {
        ...state,
        isProfileDropdownOpen: payload,
      };
    }
    case SET_SELECTED_PROFILE_ITEM: {
      return {
        ...state,
        selectedItemType: payload,
      };
    }
    case SET_IS_COOKIE_BAR_OPEN: {
      return {
        ...state,
        isCookieBarOpen: payload,
      };
    }
    case RESET_STATE_TO_DEFAULT: {
      return initialState;
    }
    case SET_COURSES: {
      return {
        ...state,
        courses: payload,
      };
    }
    case SHOW_CANCEL_PAYMENT_MODAL: {
      return {
        ...state,
        showCancelPaymentModal: payload,
      };
    }
    case SET_PREFERRED_PAYMENT_METHOD: {
      return {
        ...state,
        preferredPaymentMethod: payload,
      };
    }
    case OPEN_MODAL_WITH_REDIRECT: {
      return {
        ...state,
        modalWithRedirect: true,
      };
    }
    case OPEN_MODAL_WITHOUT_REDIRECT: {
      return {
        ...state,
        modalWithRedirect: false,
      };
    }
    default:
      return state;
  }
}

export default redux;
