import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { VIDEO_TAB_OVERVIEW } from '../constants/video';

function useVideoTabs() {
  const [selectedIndex, setSelectedIndex] = useState(VIDEO_TAB_OVERVIEW);
  const { video } = useSelector((store) => store.defaultReducer);

  return { video, selectedIndex, setSelectedIndex };
}

export default useVideoTabs;
