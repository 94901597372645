import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useVideoTabDownloads from '../hooks/useVIdeoTabDownloads';
import downloadImage from 'assets/images/download.svg';
import style from '../assets/scss/components/VideoTabDownloads.module.scss';

function VideoTabDownloads(props) {
  const { downloads, noPadding } = props;
  const { t } = useTranslation();
  const { handleDownload } = useVideoTabDownloads();
  const {
    wrapper,
    itemWrapper,
    itemWrapperNoMargin,
    itemContent,
    downloadButton,
    itemContentTitle,
  } = convertKeysToCamelCase(style);
  if (!downloads || !downloads.length) {
    return null;
  }

  return (
    <div className={wrapper}>
      {downloads && downloads[0] && !downloads[0].nameStyle ? <h4>{t('video.tabs.downloads.title')}</h4> : ''}

      {downloads.map((download) => {
        const { name, description, hash, descriptionStyle, nameStyle, directDownload, path } = download;

        return (
          <div key={nanoid()} className={`${itemWrapper} ${noPadding && itemWrapperNoMargin}`}>
            <div className={itemContent}>
              <span className={itemContentTitle}>
                {!nameStyle ? <i className="icon icon-arrow-go-to" /> : ''}
                <span className={nameStyle ? nameStyle : ''}>{name}</span>
              </span>
              <span className={`${descriptionStyle ? descriptionStyle : ''} copy `}>{description}</span>
            </div>
            {!directDownload ? (
              <button className={`primary ${downloadButton}`} onClick={() => handleDownload(hash, name)}>
                <img src={downloadImage} alt="download" />
              </button>
            ) : (
              <a href={path} target="_blank">
                <button className={`primary ${downloadButton}`}>
                  <img src={downloadImage} alt="download" />
                </button>
              </a>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default VideoTabDownloads;
