import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGeneral from 'hooks/useGeneral';
import CourseVideosDetailsItem from './CourseVideosDetailsItem';
import { ReactComponent as CheckButton } from 'assets/images/check-circle-fill.svg';
import { ReactComponent as QuizDisabled } from 'assets/images/quiz-disabled.svg';
import { ReactComponent as QuizEnabled } from 'assets/images/quiz-enabled.svg';
import style from 'assets/scss/components/CourseVideosDetails.module.scss';
import useCourseVideoDetails from '../hooks/useCourseVideoDetails';
import { CLIPS_MODULE_DETAILS_PAGE } from 'constants/routes';

function CourseVideosDetails(props) {
  const {
    topicId,
    items,
    paramId,
    isLesson,
    quiz,
    quizId,
    moduleHasQuiz,
    isWholeCoachingPurchased,
    selectedModuleId,
    setSelectedModuleId,
    type,
  } = props;
  const { t } = useTranslation();
  const { handleRedirect } = useGeneral();
  const { handleQuiz, isQuizEnabled } = useCourseVideoDetails();
  const {
    wrapper,
    element,
    contentWrapper,
    quizWrapper,
    quizButton,
    quizWrapperCurrent,
    quizButtonEnabled,
    quizDetails,
    quizTitle,
    quizDescription,
    primaryLeft,
  } = convertKeysToCamelCase(style);
  if (!items) {
    return null;
  }

  const showQuizCard = isLesson && moduleHasQuiz ? true : false;

  return (
    <div className={wrapper}>
      <h4>{t(`video.coaching.details.page.${isLesson ? 'lessons' : 'module'}.title`)}</h4>
      <div className={contentWrapper}>
        {items.map((item, itemIndex) => (
          <CourseVideosDetailsItem
            {...{
              ...item,
              isLesson,
              paramId,
              itemIndex,
              isWholeCoachingPurchased,
              selectedModuleId,
              setSelectedModuleId,
            }}
            type={type}
            key={nanoid()}
          />
        ))}
        {showQuizCard && (
          <div
            className={quizId ? quizWrapperCurrent : quizWrapper}
            onClick={() => (quiz?.passed || isQuizEnabled) && handleQuiz(quiz)}
          >
            {quiz?.passed ? (
              <CheckButton className={`${quizButton} ${quizButtonEnabled}`} onClick={() => handleQuiz(quiz)} />
            ) : isQuizEnabled ? (
              <QuizEnabled className={`${quizButton} ${quizButtonEnabled}`} onClick={() => handleQuiz(quiz)} />
            ) : (
              <QuizDisabled className={quizButton} />
            )}
            <span className={quizDetails}>
              <span className={quizTitle}>{t('video.coaching.details.page.quiz.title')}</span>
              <span className={quizDescription}>{quiz?.title}</span>
            </span>
          </div>
        )}
        {isLesson && type === 'clips' && (
          <button
            className="primary-small"
            onClick={() => handleRedirect(CLIPS_MODULE_DETAILS_PAGE.replace(':moduleId', topicId))}
          >
            {t('back.clips.module.button')}
          </button>
        )}
      </div>
    </div>
  );
}

export default CourseVideosDetails;
