import React from 'react';

import useCompany from 'hooks/useCompany';
import PartnerPageDetails from 'components/Partner/PartnerPageDetails';
import { PARTNERS_DETAIL_OVERVIEW_BREADCRUMBS } from 'constants/general';

import Breadcrumbs from './Breadcrumbs';

function PartnerDetailPage() {
  const companyId = location.href.substring(location.href.lastIndexOf('/') + 1);
  const { company } = useCompany(companyId);

  if (!company) {
    return null;
  }

  const { name } = company;

  return (
    <main className="main">
      <section className="container">
        <Breadcrumbs items={PARTNERS_DETAIL_OVERVIEW_BREADCRUMBS} title={name} />
      </section>
      <PartnerPageDetails {...company} />
    </main>
  );
}

export default PartnerDetailPage;
