import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGeneral from './useGeneral';
import { SET_IS_CONFIRM_TERMINATE_BOOKING_MODAL_OPEN } from 'constants/actionTypes';
import useMyProfile from './useMyProfile';
import { terminateBookingApi, terminateWaitingListBookingApi } from 'api/profileApi';
import { toast } from 'react-toastify';
import useTrainingFilters from './useTrainingFilters';
import useIsAuthenticated from './useIsAuthenticated';

function useTerminateBooking() {
  const dispatch = useDispatch();
  const { isUserLoggedIn } = useIsAuthenticated();
  const { showToasts } = useGeneral();
  const { terminateBookingData } = useSelector((store) => store.defaultReducer);
  const { getCourses } = useMyProfile(!isUserLoggedIn);

  const { getCourseVideos } = useTrainingFilters();

  const closeTerminateBookingModal = () => {
    dispatch({
      type: SET_IS_CONFIRM_TERMINATE_BOOKING_MODAL_OPEN,
      payload: { isOpen: false, courseId: null, type: '', date: '' },
    });
  };

  async function terminateBooking() {
    try {
      if (terminateBookingData.type === 'booked') {
        const { data } = await terminateBookingApi(terminateBookingData.courseId);
      }
      if (terminateBookingData.type === 'waiting') {
        const { data } = await terminateWaitingListBookingApi(terminateBookingData.courseId);
      }
      toast.success('Termin erfolgreich beendet!');
      getCourses();
      getCourseVideos();
      closeTerminateBookingModal();
    } catch (error) {
      showToasts(error);
    }
  }

  return {
    terminateBookingData,
    closeTerminateBookingModal,
    terminateBooking,
  };
}

export default useTerminateBooking;
