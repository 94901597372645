import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-dropdown-select';
import { Controller } from 'react-hook-form';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { REGISTER_GENDERS } from '../constants/login';
import style from '../assets/scss/components/RegisterForm.module.scss';
import useRegister from '../hooks/useRegister';
import { Link } from 'react-router-dom';
import { LEGAL_NOTICE_PAGE } from '../constants/routes';
import { useSelector } from 'react-redux';

function RegisterForm() {
  const { t } = useTranslation();
  const modalWithRedirect = useSelector((state) => state.defaultReducer.modalWithRedirect);
  const {
    submitRegisterForm,
    isTermsChecked,
    setIsTermsChecked,
    isNewsletterChecked,
    setIsNewsletterChecked,
    selectedGender,
    setSelectedGender,
    control,
    register,
    handleSubmit,
    handleLegalNotice,
    errors,
  } = useRegister(modalWithRedirect);

  const {
    form,
    formSmallInput,
    formLargeInput,
    formLabel,
    formError,
    formSelect,
    submitButton,
    termsWrapper,
    termsWrapperAcceptance,
    acceptance,
  } = convertKeysToCamelCase(style);

  return (
    <form className={`${form} register-form`} onSubmit={handleSubmit(submitRegisterForm)}>
      <div className="">
        <div className={formSmallInput}>
          <span>
            <label className={formLabel}>{t('register.form.gender.label')} *</label>
            <Controller
              render={({ onChange, value }) => {
                return (
                  <Select
                    className={formSelect}
                    onChange={onChange}
                    selected={value}
                    options={REGISTER_GENDERS}
                    values={selectedGender || []}
                    placeholder={t('register.form.gender.placeholder')}
                  />
                );
              }}
              name="gender"
              onChange={setSelectedGender}
              control={control}
            />
            {errors?.gender && <div className={formError}>{t(`validation.${errors?.gender?.message}`)}</div>}
          </span>
          <span>
            <label className={formLabel}>{t('register.form.institute.label')} *</label>
            <input name="institute" ref={register} />
            {errors?.institute && <div className={formError}>{t(`validation.${errors?.institute?.message}`)}</div>}
          </span>
          <span>
            <label className={formLabel}>{t('register.form.firstName.label')} *</label>
            <input name="firstName" ref={register} />
            {errors?.firstName && <div className={formError}>{t(`validation.${errors?.firstName?.message}`)}</div>}
          </span>
          <span>
            <label className={formLabel}>{t('register.form.lastName.label')} *</label>
            <input name="lastName" ref={register} />
            {errors?.lastName && <div className={formError}>{t(`validation.${errors?.lastName?.message}`)}</div>}
          </span>
          <span>
            <label className={formLabel}>{t('register.form.postalCode.label')} *</label>
            <input name="postalCode" ref={register} />
            {errors?.postalCode && <div className={formError}>{t(`validation.${errors?.postalCode?.message}`)}</div>}
          </span>
          <span>
            <label className={formLabel}>{t('register.form.city.label')} *</label>
            <input name="city" ref={register} />
            {errors?.city && <div className={formError}>{t(`validation.${errors?.city?.message}`)}</div>}
          </span>
        </div>
        <div className={formLargeInput}>
          <label className={formLabel}>{t('register.form.email.label')} *</label>
          <input name="email" ref={register} />
          {errors?.email && <div className={formError}>{t(`validation.${errors?.email?.message}`)}</div>}
          <label className={formLabel}>{t('register.form.password.label')} *</label>
          <input name="password" ref={register} type="password" />
          {errors?.password && <div className={formError}>{t(`validation.${errors?.password?.message}`)}</div>}
          <label className={formLabel}>{t('register.form.password.confirm.label')} *</label>
          <input name="passwordConfirmation" ref={register} type="password" />
          {errors?.passwordConfirmation && (
            <div className={formError}>{t(`validation.${errors?.passwordConfirmation?.message}`)}</div>
          )}
        </div>

        <div className={termsWrapper}>
          <div className={termsWrapperAcceptance}>
            <div className={acceptance}>
              <input
                name="terms"
                type="checkbox"
                onChange={() => setIsTermsChecked(!isTermsChecked)}
                checked={isTermsChecked}
                value={isTermsChecked}
              />
              <span>
                {`${t('register.form.terms.label.1')} `}
                <Link onClick={handleLegalNotice} to={LEGAL_NOTICE_PAGE}>
                  AGB
                </Link>
                {` ${t('register.form.terms.label.2')}`}
              </span>
            </div>
            {!isTermsChecked && <div className={formError}>{t(`validation.required`)}</div>}
            <div className={termsWrapperAcceptance}>
              <div className={acceptance}>
                <input
                  name="newsletter"
                  ref={register}
                  type="checkbox"
                  onChange={() => setIsNewsletterChecked(!isNewsletterChecked)}
                  checked={isNewsletterChecked}
                  value={isNewsletterChecked}
                />
                <span>{`${t('register.form.newsletter.label')} `}</span>
              </div>
            </div>
          </div>

          <button className={`${submitButton}`} type="submit">
            {t('general.buttons.register')}
          </button>
        </div>
      </div>
    </form>
  );
}

export default RegisterForm;
