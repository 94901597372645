import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import { MY_PROFILE_PAGE } from '../constants/routes';
import usePayment from 'hooks/usePayment';
import style from '../assets/scss/components/PaymentPersonalDataForm.module.scss';

function PaymentPersonalDataForm() {
  const {
    wrapper,
    inputField,
    plzOrtContainer,
    anchor,
    title,
    requiredInfo,
    errorText,
    plzContainer,
    ortContainer,
    moreInput,
  } = convertKeysToCamelCase(style);
  const { profileRegister, submitProfileRegister, handleProfileSubmit, profileErrors } = usePayment();
  const { t } = useTranslation();

  return (
    <div className={wrapper}>
      <h2 className={title}>{t('payment.personal.information.title')}</h2>
      <form onSubmit={handleProfileSubmit(submitProfileRegister)}>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <div className="row">
              <div className="col-sm-12 col-md-6 ">
                <div>{t('payment.personal.information.firstName')}</div>
                <input
                  name="personalFirstName"
                  ref={profileRegister}
                  className={inputField}
                  placeholder={t('payment.personal.information.firstName.placeholder')}
                />
                <div className={errorText}>
                  {profileErrors?.personalFirstName && t(`validation.${profileErrors?.personalFirstName?.message}`)}
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div>{t('payment.personal.information.lastName')}</div>
                <input
                  name="personalLastName"
                  ref={profileRegister}
                  className={inputField}
                  placeholder={t('payment.personal.information.lastName.placeholder')}
                />
                <div className={errorText}>
                  {profileErrors?.personalLastName && t(`validation.${profileErrors?.personalLastName?.message}`)}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 ">
                <div>{t('payment.personal.information.specialization')}</div>
                <input
                  name="personalSubject"
                  ref={profileRegister}
                  className={inputField}
                  placeholder={t('payment.personal.information.specialization.placeholder')}
                />
                <div className={errorText}>
                  {profileErrors?.personalSubject && t(`validation.${profileErrors?.personalSubject?.message}`)}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 ">
                <div>{t('payment.personal.information.email')}</div>
                <input
                  name="personalEmailAddress"
                  ref={profileRegister}
                  className={inputField}
                  placeholder={t('payment.personal.information.email.placeholder')}
                />
                <div className={errorText}>
                  {profileErrors?.personalEmailAddress &&
                    t(`validation.${profileErrors?.personalEmailAddress?.message}`)}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 ">
                <div>{t('payment.personal.information.address')}</div>
                <input
                  name="personalStreet"
                  ref={profileRegister}
                  className={inputField}
                  placeholder={t('payment.personal.information.address.placeholder')}
                />
                <div className={errorText}>
                  {profileErrors?.personalStreet && t(`validation.${profileErrors?.personalStreet?.message}`)}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 ">
                <div className={plzOrtContainer}>
                  <div className={plzContainer}>
                    <div>{t('payment.personal.information.postalCode')}</div>
                    <input
                      name="personalPostalCode"
                      ref={profileRegister}
                      className={inputField}
                      placeholder={t('payment.personal.information.postalCode.placeholder')}
                    />
                    <div className={errorText}>
                      {profileErrors?.personalPostalCode &&
                        t(`validation.${profileErrors?.personalPostalCode?.message}`)}
                    </div>
                  </div>
                  <div className={ortContainer}>
                    <div>{t('payment.personal.information.city')}</div>
                    <input
                      name="personalCity"
                      ref={profileRegister}
                      className={inputField}
                      placeholder={t('payment.personal.information.city.placeholder')}
                    />
                    <div className={errorText}>
                      {profileErrors?.personalCity && t(`validation.${profileErrors?.personalCity?.message}`)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 ">
                <div>{t('payment.personal.information.phone')}</div>
                <input
                  name="personalPhoneNumber"
                  className={inputField}
                  ref={profileRegister}
                  placeholder={t('payment.personal.information.phone.placeholder')}
                />
                <div className={errorText}>
                  {profileErrors?.personalPhoneNumber && t(`validation.${profileErrors?.personalPhoneNumber?.message}`)}
                </div>
              </div>

              <div className="col-sm-12 col-md-6 ">
                <div>{t('payment.personal.information.efnNumber')}</div>
                <input
                  name="personalEFNNumber"
                  className={inputField}
                  ref={profileRegister}
                  placeholder={t('payment.personal.information.efnNumber.placeholder')}
                />
                <div className={errorText}>
                  {profileErrors?.personalEFNNumber && t(`validation.${profileErrors?.personalEFNNumber?.message}`)}
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <p>
              {t('payment.personal.information.outDatedInfo')}
              <a href={MY_PROFILE_PAGE} className={anchor}>
                {t('payment.personal.information.updateInfo')}
              </a>
            </p>
          </div>
        </div>
        <h2 className={title}>{t('payment.personal.information.more.title')}</h2>
        <div className="row">
          <div className="col-12 col-md-4">
            <div>{t('payment.personal.information.more')}</div>
            <input
              name="moreInformation"
              ref={profileRegister}
              className={`${inputField} ${moreInput}`}
              placeholder={t('payment.personal.information.more.placeholder')}
            />
          </div>
        </div>
        <button className="secondary-red" type="submit">
          {t('payment.step1.forward')}
        </button>
        <div className={requiredInfo}>
          *<p>{t('payment.requiredInfo')}</p>
        </div>
      </form>
    </div>
  );
}

export default PaymentPersonalDataForm;
