import React from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import QuizAnswerCard from './QuizAnswerCard';
import style from '../assets/scss/components/QuizAnswer.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';

function QuizAnswer(props) {
  const {
    isSelectingAnswer,
    lastWrongAnswers,
    wasCorrectAnswer,
    selected,
    toggleSelected,
    currentQuestion,
    remainingQuestions,
    unSelectedGoodAnswers,
    selectedGoodAnswers,
  } = props;
  const { t } = useTranslation();
  const { correctTitle, incorrectTitle } = convertKeysToCamelCase(style);

  return (
    <>
      {!isSelectingAnswer &&
        unSelectedGoodAnswers?.map((answer) => (
          <QuizAnswerCard isUnSelected isCorrect answer={answer.answer} key={nanoid()} />
        ))}
      {!isSelectingAnswer &&
        selectedGoodAnswers?.map((answer) => (
          <QuizAnswerCard isCorrect isSelected answer={answer.answer} key={nanoid()} />
        ))}
      {!isSelectingAnswer &&
        lastWrongAnswers?.map((wrongAnswer) => (
          <QuizAnswerCard isIncorrect isSelected answer={wrongAnswer.answer} key={nanoid()} />
        ))}
      {!isSelectingAnswer && wasCorrectAnswer && (
        <div>
          <p className={correctTitle}>{t('quizzes.correct')}</p>
        </div>
      )}
      {!isSelectingAnswer && !wasCorrectAnswer && (
        <div>
          <p className={incorrectTitle}>{t('quizzes.incorrect')}</p>
        </div>
      )}

      {!isSelectingAnswer &&
        remainingQuestions?.map((remaining) => (
          <QuizAnswerCard isUnSelected answer={remaining.answer} key={nanoid()} />
        ))}

      {isSelectingAnswer &&
        currentQuestion?.possibleAnswers.map((answer) => {
          const isSelected = selected.includes(answer.id);

          return (
            <QuizAnswerCard
              key={nanoid()}
              isSelected={isSelected}
              isCardSelected={isSelected}
              answer={answer.answer}
              isUnSelected={!isSelected && selected.length !== 0}
              onClick={() => {
                toggleSelected(answer.id);
              }}
            />
          );
        })}
    </>
  );
}

export default QuizAnswer;
