import { PRIVACY_POLICY_PAGE } from './routes';
import { initializeGoogleStatistics } from '../helpers/cookieAcceptHelper';

export const COOKIE_CONSENT_KEY = 'cookie_consent';

export const COOKIE_CONFIG = {
  title: 'cookie.title',
  description: 'cookie.description',
  policy: {
    text: 'cookie.policy.text',
    link: PRIVACY_POLICY_PAGE,
  },
  checkBoxes: [
    {
      text: 'cookie.checkbox.necessary',
      value: 'Necessary',
      checked: true,
      disabled: true,
    },
    {
      text: 'cookie.checkbox.preferences',
      value: 'Preferences',
      checked: true,
      disabled: false,
    },
    {
      text: 'cookie.checkbox.statistics',
      value: 'Statistics',
      checked: true,
      disabled: false,
    },
    {
      text: 'cookie.checkbox.marketing',
      value: 'Marketing',
      checked: true,
      disabled: false,
    },
  ],
  acceptButton: {
    text: 'cookie.button.accept',
    callback: initializeGoogleStatistics,
  },
};
