import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import useGeneral from './useGeneral';
import { getCourseDatesApi } from '../api/videosApi';
import {
  SET_COURSE,
  SET_IS_REGISTER_TO_EVENT_MODAL_OPEN,
  SET_IS_LOADING,
  SET_IS_REGISTRATION_SUCCESS,
  SET_IS_CONFIRM_TERMINATE_BOOKING_MODAL_OPEN,
} from 'constants/actionTypes';
import useYupValidationResolver from './useYupValidationResolver';
import { getDateScheduleSchema } from '../helpers/validationSchemaHelper';
import {
  DATE_SCHEDULE_DEFAULT_VALUES,
  TRAINING_PAGE_DATE_BOOK_TYPE,
  TRAINING_PAGE_DATE_INTERESTED_TYPE,
  TRAINING_PAGE_DATE_RESERVE_TYPE,
} from '../constants/general';
import { bookCourseApi, interestedInCourseApi, reserveCourseApi } from '../api/courseApi';

function useRegisterToEvent() {
  const { showToasts } = useGeneral();
  const { course, isRegisterToEvent, isRegistrationSuccess, user } = useSelector((store) => store.defaultReducer);

  const [selectedDate, setSelectedDate] = useState({});
  const dispatch = useDispatch();

  const resolver = useYupValidationResolver(getDateScheduleSchema());
  const { register, handleSubmit, errors, reset } = useForm({ resolver, defaultValues: DATE_SCHEDULE_DEFAULT_VALUES });

  async function getCourse(courseId) {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data } = await getCourseDatesApi(courseId);
      dispatch({ type: SET_COURSE, payload: data });

      dispatch({ type: SET_IS_REGISTER_TO_EVENT_MODAL_OPEN, payload: true });
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function courseActions(callback, id, payload) {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      await callback(id, payload);
      dispatch({ type: SET_IS_REGISTRATION_SUCCESS, payload: true });
      reset();
      setSelectedDate({});
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  function closeRegisterToEvent() {
    dispatch({ type: SET_IS_REGISTER_TO_EVENT_MODAL_OPEN, payload: false });
  }

  function resetSucces() {
    dispatch({ type: SET_IS_REGISTRATION_SUCCESS, payload: false });
  }

  function onSubmit(data) {
    const { code, subscribedToNewsletter } = data;
    const { type, id, courseId } = selectedDate;

    switch (type) {
      case TRAINING_PAGE_DATE_BOOK_TYPE: {
        courseActions(bookCourseApi, id, { code, subscribedToNewsletter });

        break;
      }
      case TRAINING_PAGE_DATE_RESERVE_TYPE: {
        courseActions(reserveCourseApi, id, { code, subscribedToNewsletter });

        break;
      }
      case TRAINING_PAGE_DATE_INTERESTED_TYPE: {
        courseActions(interestedInCourseApi, courseId, code);

        break;
      }
      default: {
        return;
      }
    }
  }

  async function showTerminateBookingModal(course) {
    dispatch({
      type: SET_IS_CONFIRM_TERMINATE_BOOKING_MODAL_OPEN,
      payload: {
        isOpen: true,
        courseId: course.id,
        type: course.bookingStatus,
        date: course.videos[0].dataForLive.modalDate,
      },
    });
  }

  async function showTerminateBookingModalFirstvideo(course, courseId) {
    dispatch({
      type: SET_IS_CONFIRM_TERMINATE_BOOKING_MODAL_OPEN,
      payload: {
        isOpen: true,
        courseId: courseId,
        type: course.bookingStatus,
        date: course.dataForLive.modalDate,
      },
    });
  }

  return {
    getCourse,
    course,
    isRegisterToEvent,
    isRegistrationSuccess,
    closeRegisterToEvent,
    selectedDate,
    register,
    handleSubmit: handleSubmit(onSubmit),
    setSelectedDate,
    errors,
    showTerminateBookingModal,
    showTerminateBookingModalFirstvideo,
    resetSucces,
    subscribedToNewsletter: user.subscribedToNewsletter,
  };
}

export default useRegisterToEvent;
