import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import useVideo from './useVideo';
import useYupValidationResolver from './useYupValidationResolver';
import { getAskExpertValidationSchema } from '../helpers/validationSchemaHelper';
import { ASK_EXPERT_DEFAULT_VALUES } from '../constants/login';
import { askExpertApi } from '../api/videosApi';
import useGeneral from './useGeneral';
import {
  COACH_EXPERT_ASK_ENDPOINT,
  COACHING_LESSON_PAGE,
  CLIP_EXPERT_ASK_ENDPOINT,
  CLIPS_LESSONS_PAGE,
  VIDEO_EXPERT_ASK_ENDPOINT,
} from '../constants/routes';

function useVideoDetailsAskExpert(isCoachingVideo) {
  const { video } = useVideo();
  const { showToasts } = useGeneral();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const resolver = useYupValidationResolver(getAskExpertValidationSchema());

  const { register, handleSubmit, errors, reset } = useForm({ resolver, defaultValues: ASK_EXPERT_DEFAULT_VALUES });

  async function handleSubmitForm(formData) {
    const { question } = formData;
    const { id, coachingId } = video;
    try {
      const videoId = coachingId && pathname.includes(COACHING_LESSON_PAGE) ? coachingId : id;
      let path = VIDEO_EXPERT_ASK_ENDPOINT;
      let data = { question, videoId: id };
      if (isCoachingVideo) {
        if (pathname.includes('klip')) path = CLIP_EXPERT_ASK_ENDPOINT.replace(':klipId', videoId);
        else path = COACH_EXPERT_ASK_ENDPOINT.replace(':coachId', videoId);
        data = { question };
      }

      await askExpertApi(path, data);
      toast.success(t('video.details.page.info.ask.expert.question.asked.successfully'));
      reset();
    } catch (error) {
      showToasts(error);
    }
  }

  return { video, register, handleSubmit: handleSubmit(handleSubmitForm), errors };
}

export default useVideoDetailsAskExpert;
