import React, { useState } from 'react';
import Slider from 'react-slick';
import { nanoid } from 'nanoid';

import { CAROUSEL_SETTINGS_BIG, CAROUSER_SETTINGS } from '../constants/carousel';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import VideoCard from './VideoCard';
import style from '../assets/scss/components/VideoCarousel.module.scss';

function VideoCarousel(props) {
  const { items, isZoomMeet, usedOnProfilePage, usedOnCompanyPage, course, usedOnDashboard } = props;
  const { wrapper, iconWrapper, iconRight, iconLeft, sliderWrapper, sliderWrapperProfile } = convertKeysToCamelCase(
    style,
  );
  const [slider, setSlider] = useState(null);

  if (!items.length) {
    return null;
  }
  const itemsLength = items.length > 2;

  return (
    <div className={`${wrapper} dashboard-carousel-wrapper ${itemsLength && 'dashboard-carousel-padding-wrapper'}`}>
      {itemsLength && (
        <span className={`${iconWrapper} ${iconLeft}`} onClick={slider?.slickPrev}>
          <i className="icon icon-right-open-big" />
        </span>
      )}
      {itemsLength && (
        <span className={`${iconWrapper} ${iconRight}`} onClick={slider?.slickNext}>
          <i className="icon icon-right-open-big" />
        </span>
      )}
      <Slider
        {...{ ...(CAROUSER_SETTINGS[items.length] || CAROUSEL_SETTINGS_BIG) }}
        ref={(reactSlider) => setSlider(reactSlider)}
        className={usedOnProfilePage ? sliderWrapperProfile : sliderWrapper}
      >
        {items.map((item) => (
          <VideoCard
            {...item}
            usedOnDashboard={usedOnDashboard}
            usedOnCompanyPage={usedOnCompanyPage}
            isVertical={true}
            key={nanoid()}
            isZoomMeet={isZoomMeet}
            course={course}
            dashboardCourse={item.courseObject}
          />
        ))}
      </Slider>
    </div>
  );
}

export default VideoCarousel;
