import React from 'react';

import Select from 'react-dropdown-select';
import { useTranslation } from 'react-i18next';
import style from 'assets/scss/components/common/inputs/MultiSelect.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';

function MultiSelect({
  itemsList,
  placeholder,
  labelField = 'name',
  valueField = 'id',
  onDropDownClose,
  onChange,
  selectedValues,
}) {
  const { multiselect, checkboxWrapper } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  const contentRenderer = ({ props, state }) => {
    return state.values.length === 0 ? (
      <p>{placeholder}</p>
    ) : (
      <p>
        {t('general.multiselect.placeholder', {
          selected: state.values.length,
          length: props.options.length,
          name: placeholder,
        })}
      </p>
    );
  };

  const itemRenderer = ({ item, props, methods }) => (
    <div key={item[props.valueField]} onClick={() => methods.addItem(item)}>
      <div className={checkboxWrapper}>
        <input type="checkbox" checked={methods.isSelected(item)} readOnly />
        {item[props.labelField]}
      </div>
    </div>
  );

  return (
    <Select
      multi
      dropdownPosition="bottom"
      values={selectedValues}
      itemRenderer={itemRenderer}
      options={itemsList}
      contentRenderer={contentRenderer}
      labelField={labelField}
      valueField={valueField}
      onChange={onChange}
      onDropdownClose={onDropDownClose}
      className={multiselect}
    />
  );
}

export default MultiSelect;
