import { SET_IS_COOKIE_BAR_OPEN } from 'constants/actionTypes';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import { COOKIE_CONFIG, COOKIE_CONSENT_KEY } from '../constants/cookie';

function useCookie() {
  const { isCookieBarOpen } = useSelector((store) => store.defaultReducer);
  const dispatch = useDispatch();
  const { title, description, policy, checkBoxes, acceptButton } = COOKIE_CONFIG;
  const { text: acceptButtonText, callback } = acceptButton;

  const [cookies, setCookie, removeCookie] = useCookies();
  const checkboxes = cookies[COOKIE_CONSENT_KEY] || checkBoxes.map((checkBox) => checkBox.value);

  const [checkedValues, setCheckedValues] = useState(checkboxes);

  function handleInputChange(value) {
    let filteredValues = checkedValues.filter((item) => item !== value);
    if (filteredValues.length === checkedValues.length) {
      filteredValues.push(value);
    }

    setCheckedValues(filteredValues);
  }

  function handleAccept() {
    dispatch({ type: SET_IS_COOKIE_BAR_OPEN, payload: false });
    removeCookie(COOKIE_CONSENT_KEY);
    const nextYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    setCookie(COOKIE_CONSENT_KEY, JSON.stringify(checkedValues), { expires: nextYear });
    if (callback) {
      return callback(checkedValues);
    }
  }

  function handleModifyCookies() {
    dispatch({ type: SET_IS_COOKIE_BAR_OPEN, payload: !isCookieBarOpen });
  }

  const useEffectLocally = () => {
    if (!cookies[COOKIE_CONSENT_KEY]) {
      dispatch({ type: SET_IS_COOKIE_BAR_OPEN, payload: true });

      return;
    }

    callback(checkedValues);
  };

  useEffect(useEffectLocally, []);

  return {
    policy,
    title,
    isCookieBarOpen,
    checkBoxes,
    checkedValues,
    description,
    acceptButtonText,
    handleAccept,
    handleInputChange,
    handleModifyCookies,
  };
}

export default useCookie;
