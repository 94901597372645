import React from 'react';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import VideoTabs from './VideoTabs';
import VideoDetailsAccordion from './VideoDetailsAccordion';
import VideoDetailsPartner from './VideoDetailsPartner';
import VideoDetailsCourses from './VideoDetailsCourses';
import style from 'assets/scss/components/VideoDetailsInfo.module.scss';
import useVideo from '../hooks/useVideo';
import VideoDetailsAskExpert from './VideoDetailsAskExpert';

function VideoDetailsInfo() {
  const { video } = useVideo();
  const { wrapper, accordionWrapper, actionsWrapper, tabsWrapper, partnerWrapper } = convertKeysToCamelCase(style);

  return (
    <div className={wrapper}>
      <div className={accordionWrapper}>
        {video?.otherVideos && <VideoDetailsAccordion title="otherVideos" content={<VideoDetailsCourses />} />}
        {!!video?.meetingStatus && <VideoDetailsAccordion title="askExpert" content={<VideoDetailsAskExpert />} />}
        <VideoDetailsAccordion title="presentedBy" content={<VideoDetailsPartner />} />
      </div>
      <div className={actionsWrapper}>
        <div className={tabsWrapper}>
          <VideoTabs />
        </div>
        <div className={partnerWrapper}>
          <VideoDetailsAskExpert />
          <VideoDetailsPartner />
        </div>
      </div>
    </div>
  );
}

export default VideoDetailsInfo;
