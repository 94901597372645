import {
  COACHING_MODULES_ENDPOINT,
  COACHING_LESSONS_ENDPOINT,
  VIDEO_ENDPOINT,
  VIDEO_EXPERT_TALKS_ENDPOINT,
  VIDEO_PAGE_EXPERT_TALKS,
  COACHING_MODULE_PAGE,
  COACHING_LESSON_PAGE,
  CLIPS_MODULE_PAGE,
  CLIPS_LESSON_PAGE,
  CLIPS_MODULES_ENDPOINT,
  CLIPS_LESSONS_ENDPOINT,
} from '../constants/routes';

export function getVideoEndpoint(pathname) {
  let endpoint = VIDEO_ENDPOINT;
  if (pathname.includes(VIDEO_PAGE_EXPERT_TALKS.replace(':id', ''))) {
    endpoint = VIDEO_EXPERT_TALKS_ENDPOINT;
  }

  if (pathname.includes(COACHING_MODULE_PAGE)) {
    endpoint = COACHING_MODULES_ENDPOINT;
  }

  if (pathname.includes(COACHING_LESSON_PAGE)) {
    endpoint = COACHING_LESSONS_ENDPOINT;
  }

  if (pathname.includes(CLIPS_MODULE_PAGE)) {
    endpoint = CLIPS_MODULES_ENDPOINT;
  }

  if (pathname.includes(CLIPS_LESSON_PAGE)) {
    endpoint = CLIPS_LESSONS_ENDPOINT;
  }

  return endpoint;
}
