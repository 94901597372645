import React from 'react';
import PropTypes from 'prop-types';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from 'assets/scss/components/pages/HomePage.module.scss';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';

const HomeDetailSection = ({ title, description, image, buttonText, isInversed, buttonUrl }) => {
  const { detailSection } = convertKeysToCamelCase(style);

  return (
    <section className={detailSection}>
      <Container className="container-fluid">
        <Row>
          <Col xs={12} md={7} order={{ xs: 1, md: isInversed ? 2 : 1 }}>
            <h2 className="headline2">{title}</h2>
            <p className="copy">{description}</p>
            <Link to={buttonUrl}>
              <button className="primary">{buttonText}</button>
            </Link>
          </Col>
          <Col xs={12} md={5} order={{ xs: 2, md: isInversed ? 1 : 2 }}>
            <img src={image} alt={title} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

HomeDetailSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  isInversed: PropTypes.bool,
  buttonUrl: PropTypes.string,
};

export default HomeDetailSection;
