import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/SignInForm.module.scss';
import useSignIn from '../hooks/useSignIn';

function SignInForm() {
  const { t } = useTranslation();
  const { form, formLabel, formError, formForgotPassword, submitButton } = convertKeysToCamelCase(style);
  const { handleSubmitForm, register, errors, forgotPassword, setForgotPassword } = useSignIn();

  return (
    <form className={form} onSubmit={handleSubmitForm}>
      <div className="">
        <div className={formLabel}>{t('register.form.email.label')} *</div>
        <input name="email" ref={register} />
        {errors?.email && <div className={formError}>{t(`validation.${errors?.email?.message}`)}</div>}

        {!forgotPassword && (
          <>
            <div className={formLabel}>{t('register.form.password.label')} *</div>
            <input name="password" ref={register} type="password" />
            {errors?.password && <div className={formError}>{t(`validation.${errors?.password?.message}`)}</div>}

            <p className={formForgotPassword} onClick={() => setForgotPassword(true)}>
              {t('logout.forgot.password.label')}
            </p>
          </>
        )}
        <button className={submitButton} type="submit">
          {t(`general.buttons.${forgotPassword ? 'forgotPassword' : 'signIn'}`)}
        </button>
      </div>
    </form>
  );
}

export default SignInForm;
