import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import { PAYMENT_METHODS } from '../constants/payment';
import style from '../assets/scss/components/PaymentMethod.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { SET_PREFERRED_PAYMENT_METHOD } from 'constants/actionTypes';

function PaymentMethod() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { wrapper, card, radio, icon, cardChecked, title, cardWrapper } = convertKeysToCamelCase(style);
  const { preferredPaymentMethod } = useSelector((store) => store.defaultReducer);
  const [selected, setSelected] = useState(preferredPaymentMethod);

  return (
    <div className={wrapper}>
      <h2 className={title}>{t('payment.method.title')}</h2>
      <div className={cardWrapper}>
        {PAYMENT_METHODS.map((radioData) => {
          const { type, img, titleKey } = radioData;
          const isSelected = selected === type;

          return (
            <div
              className={`${card} ${isSelected && cardChecked}`}
              onClick={() => {
                setSelected(type);
                dispatch({ type: SET_PREFERRED_PAYMENT_METHOD, payload: type });
              }}
              key={nanoid()}
            >
              <div className={radio}>
                <input
                  type="radio"
                  name="radio"
                  id={'invoice'}
                  checked={isSelected}
                  onChange={() => {
                    setSelected(type);
                    dispatch({ type: SET_PREFERRED_PAYMENT_METHOD, payload: type });
                  }}
                />
                <label htmlFor="invoice">{t(titleKey)}</label>
              </div>
              <img src={img} className={icon} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default PaymentMethod;
