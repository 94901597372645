export function convertKeysToCamelCase(styleObject) {
  const newStyleObject = {};
  Object.keys(styleObject).forEach((key) => {
    let newKey = key.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', ''));
    newKey = newKey.replace('-', '');

    newStyleObject[newKey] = styleObject[key];
  });

  return newStyleObject;
}

export function getSelectedFilters(filters, filterType, selectedFilters) {
  const newFilters = JSON.parse(JSON.stringify(selectedFilters));

  if (filters.length === 0 && newFilters[filterType]) {
    delete newFilters[filterType];

    return newFilters;
  }

  return { ...selectedFilters, [filterType]: filters };
}

export function getQuerySelectedFilters(selectedFilters) {
  return (
    Object.values(selectedFilters)
      .flatMap((filter) => filter)
      .map((child) => child.id)
      .join(',') || ''
  );
}

export function getOtherCourseVideos(videos) {
  if (!videos) {
    return [];
  }

  return videos.map((video) => ({ value: video.id, label: video.title }));
}

function getTime(secondsElapsed) {
  const time = Math.floor(secondsElapsed);
  const hours = Math.floor(time / (60 * 60));
  const divisorForMinutes = time % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);
  const divisorForSeconds = divisorForMinutes % 60;
  const seconds = Math.ceil(divisorForSeconds);

  return {
    hours: isNaN(hours) ? '0' : hours,
    minutes: isNaN(minutes) ? '0' : minutes,
    seconds: isNaN(seconds) ? '0' : seconds,
  };
}

export function getCurrentTimeForCreate(secondsElapsed) {
  if (!secondsElapsed) {
    return '';
  }

  const { hours, minutes, seconds } = getTime(secondsElapsed);
  const hour = hours < 10 ? '0' + hours : hours;
  const minute = minutes < 10 ? '0' + minutes : minutes;
  const second = seconds < 10 ? '0' + seconds : seconds;

  return `${hour}:${minute}:${second}`;
}

export function getCurrentTime(secondsElapsed) {
  const { hours, minutes, seconds } = getTime(secondsElapsed);
  const second = seconds < 10 ? '0' + seconds : seconds;

  let currentTime = `${minutes}:${second}`;
  if (hours) {
    currentTime = `${hours}:${currentTime}`;
  }

  return currentTime;
}

export function getSecondsFromString(timestamp) {
  return timestamp.split(':').reduce((acc, time) => 60 * acc + +time);
}

export function getQueryString(apiPath, params) {
  let path = apiPath;
  if (params) {
    const formattedParams = {};
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        return;
      }

      formattedParams[key] = params[key].value.toString();
    });

    const queryString = new URLSearchParams(formattedParams).toString();
    path = `${path}?${queryString}`;
  }

  return path;
}

export function getResponseErrors(errorData) {
  const { errors } = errorData;
  if (errors) {
    const errorsValues = Object.values(errors);

    return [].concat(...errorsValues);
  }

  return [errorData.message];
}
