import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useVideoDetailsAskExpert from '../hooks/useVideoDetailsAskExpert';
import style from 'assets/scss/components/VideoDetailsAskExpert.module.scss';
import { VIDEO_TYPE_VIMEO_LIVE } from 'constants/video';

function VideoDetailsAskExpert() {
  const { t } = useTranslation();
  const { wrapper, form, formTextarea } = convertKeysToCamelCase(style);
  const { video, register, handleSubmit, errors } = useVideoDetailsAskExpert();
  const { meetingStatus, type } = video;
  if (!meetingStatus && type !== VIDEO_TYPE_VIMEO_LIVE) {
    return null;
  }

  return (
    <div className={wrapper}>
      <h4>{t('video.details.page.info.accordion.askExpert')}</h4>
      <form onSubmit={handleSubmit} className={form}>
        <textarea
          className={formTextarea}
          name="question"
          ref={register}
          placeholder={t('video.details.page.info.ask.expert.textarea.placeholder')}
        />
        {errors?.question && <span className="errors">{t(`validation.${errors?.question?.message}`)}</span>}
        <span>
          <button className="primary" type="submit">
            {t('video.details.page.info.ask.expert.button')}
          </button>
        </span>
      </form>
    </div>
  );
}

export default VideoDetailsAskExpert;
