import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import useYupValidationResolver from './useYupValidationResolver';
import { getNotesValidationSchema } from '../helpers/validationSchemaHelper';
import { VIDEO_TABS_NOTES_DEFAULT_VALUES } from '../constants/video';
import { createVideoNoteApi } from '../api/videosApi';
import { getCurrentTime, getCurrentTimeForCreate } from '../helpers/baseHelper';
import { UPDATE_VIDEO_NOTES } from '../constants/actionTypes';
import { transformNotes } from '../transformers/baseTransformer';
import useGeneral from './useGeneral';
import {
  COACHING_LESSON_PAGE,
  COACHING_MODULE_PAGE,
  COACHING_NOTE_CREATE_ENDPOINT,
  CLIPS_LESSON_PAGE,
  CLIPS_MODULE_PAGE,
  NOTE_CREATE_ENDPOINT,
} from '../constants/routes';

function useVideoTabNotes() {
  const { t } = useTranslation();
  const { showToasts } = useGeneral();
  const { currentVideoTime, video } = useSelector((store) => store.defaultReducer);
  const { id, notes } = video;
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const resolver = useYupValidationResolver(getNotesValidationSchema());
  const { register, handleSubmit, errors, reset } = useForm({
    resolver,
    defaultValues: VIDEO_TABS_NOTES_DEFAULT_VALUES,
  });

  async function createVideoNote(data) {
    const { note } = data;
    const path =
      pathname.includes(COACHING_LESSON_PAGE) || pathname.includes(CLIPS_LESSON_PAGE)
        ? COACHING_NOTE_CREATE_ENDPOINT
        : NOTE_CREATE_ENDPOINT;

    try {
      const requestData = {
        videoId: id,
        content: note,
        timestamp: getCurrentTimeForCreate(currentVideoTime || '00:00:00'),
      };

      const { data } = await createVideoNoteApi(requestData, path);
      if (data) {
        dispatch({ type: UPDATE_VIDEO_NOTES, payload: data });
        toast.success(t('video.tabs.notes.note.created.success'));
      }
    } catch (error) {
      showToasts(error);
    }
  }

  function onSubmit(data) {
    createVideoNote(data);
    reset();
  }

  return {
    notes: transformNotes(notes || []),
    hideAddNote: pathname.includes(COACHING_MODULE_PAGE) || pathname.includes(CLIPS_MODULE_PAGE),
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    currentTime: getCurrentTime(currentVideoTime),
  };
}

export default useVideoTabNotes;
