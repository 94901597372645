import { WINDOW_WIDTH_LG, WINDOW_WIDTH_MD, WINDOW_WIDTH_SM } from './general';

export const CAROUSEL_DEFAULT_SETTINGS = {
  dots: false,
  arrows: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: WINDOW_WIDTH_MD,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
};

export const CAROUSEL_SETTINGS_BIG = {
  ...CAROUSEL_DEFAULT_SETTINGS,
  slidesToShow: 2,
  centerMode: false,
  responsive: [
    ...CAROUSEL_DEFAULT_SETTINGS.responsive,
    {
      breakpoint: WINDOW_WIDTH_LG,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
      },
    },
  ],
};

export const CAROUSEL_SETTINGS_COACHINGS_DEFAULT = {
  ...CAROUSEL_DEFAULT_SETTINGS,
  slidesToShow: 2,
  slidesToScroll: 1,
  centerMode: false,
  dots: false,
  responsive: [
    ...CAROUSEL_DEFAULT_SETTINGS.responsive,
    {
      breakpoint: WINDOW_WIDTH_LG,
      settings: {
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};

export const CAROUSEL_SETTINGS_COACHINGS_MODULES = {
  ...CAROUSEL_DEFAULT_SETTINGS,
  infinite: true,
  autoplay: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  centerMode: false,
  dots: true,
  responsive: [
    {
      breakpoint: WINDOW_WIDTH_LG,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const CAROUSEL_SETTINGS_COACHINGS_SMALL = {
  ...CAROUSEL_DEFAULT_SETTINGS,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  dots: false,
  responsive: [
    ...CAROUSEL_DEFAULT_SETTINGS.responsive,
    {
      breakpoint: WINDOW_WIDTH_LG,
      settings: {
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};

export const CAROUSEL_SETTINGS_COACHINGS = {
  1: CAROUSEL_SETTINGS_COACHINGS_SMALL,
  2: CAROUSEL_SETTINGS_COACHINGS_DEFAULT,
};

export const CAROUSEL_SETTINGS_SMALL = {
  ...CAROUSEL_DEFAULT_SETTINGS,
  slidesToShow: 2,
};

export const CAROUSER_SETTINGS = {
  1: CAROUSEL_DEFAULT_SETTINGS,
  2: CAROUSEL_SETTINGS_SMALL,
};

export const CAROUSEL_MY_COURSES = {
  ...CAROUSEL_DEFAULT_SETTINGS,
  slidesToShow: 1,
  dots: true,
  infinite: false,

  responsive: [
    {
      breakpoint: WINDOW_WIDTH_SM,
    },
    { breakpoint: 2000, settings: 'unslick' },
  ],
};
