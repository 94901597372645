import React from 'react';

import QuizComponent from 'components/QuizComponent';

function QuizPage() {
  return (
    <main className="main grey">
      <section className="small max-width">
        <QuizComponent />
      </section>
    </main>
  );
}

export default QuizPage;
