import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import useLogin from './useLogin';
import useYupValidationResolver from './useYupValidationResolver';
import { getRegisterValidationSchema } from '../helpers/validationSchemaHelper';
import { REGISTER_FORM_DEFAULT_VALUES } from '../constants/login';
import { useDispatch } from 'react-redux';
import { SET_STATE } from '../constants/actionTypes';
import { TOKEN_COURSE_CODE } from '../constants/login';

function useRegister(withRedirect = false) {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isNewsletterChecked, setIsNewsletterChecked] = useState(true);
  const [selectedGender, setSelectedGender] = useState([]);
  const dispatch = useDispatch();

  const resolver = useYupValidationResolver(getRegisterValidationSchema());
  const { register, handleSubmit, errors, reset, control, setValue } = useForm({
    resolver,
    defaultValues: REGISTER_FORM_DEFAULT_VALUES,
  });
  const { submitRegister } = useLogin();

  function submitRegisterForm(data) {
    if (!isTermsChecked) {
      return;
    }

    reset();
    setSelectedGender([]);
    setValue('gender', []);
    setValue('newsletter', true);

    const coachingCode = sessionStorage.getItem(TOKEN_COURSE_CODE);
    if (coachingCode) {
      data.coachingCode = coachingCode;
    }

    submitRegister(data, withRedirect);
  }

  function handleLegalNotice() {
    reset();
    dispatch({
      type: SET_STATE,
      payload: { isLoginModalOpen: false, isBurgerMenuOpen: false, isLogoutModalOpen: false, isLoading: false },
    });
  }

  return {
    submitRegisterForm,
    isTermsChecked,
    setIsTermsChecked,
    isNewsletterChecked,
    setIsNewsletterChecked,
    selectedGender,
    control,
    setSelectedGender,
    register,
    handleSubmit,
    handleLegalNotice,
    errors,
  };
}

export default useRegister;
