import axios from '../api/axiosConfig';
import { CONTACT_FORM_ENDPOINT, SOCIAL_MEDIA_ENDPOINT } from '../constants/routes';

export function contactFormApi(data) {
  return axios().post(CONTACT_FORM_ENDPOINT, data);
}

export function socialMediaApi() {
  return axios().get(SOCIAL_MEDIA_ENDPOINT);
}
