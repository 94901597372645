import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useVideo from './useVideo';
import useGeneral from './useGeneral';
import { getVideoEndpoint } from '../helpers/videoHelper';
import {
  COACHING_LESSONS_PAGE,
  COACHING_MODULE_DETAILS_PAGE,
  CLIPS_LESSONS_PAGE,
  CLIPS_MODULE_DETAILS_PAGE,
} from '../constants/routes';

function useCourseVideoDetailsItem(props) {
  const { isLesson, paramId, id, progress, selectedModuleId } = props;
  const { video, getVideo } = useVideo();
  const { pathname, search } = useLocation();
  const { handleRedirect } = useGeneral();
  const { currentVideoTime } = useSelector((store) => store.defaultReducer);

  const isCurrent =
    !search.includes('quizId') &&
    (isLesson
      ? Number(paramId) === id
      : selectedModuleId
      ? Number(selectedModuleId) == id
      : video.modules.length > 0 && video.modules[0].id === id);

  const [videoProgress, setVideoProgress] = useState(progress || 0);

  function handleLessonClick(videoId, isLesson) {
    if (!isLesson) {
      if (pathname.includes('klips')) handleRedirect(CLIPS_MODULE_DETAILS_PAGE.replace(':moduleId', videoId));
      else handleRedirect(COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', videoId));

      return;
    }

    const endpoint = getVideoEndpoint(pathname);

    if (pathname.includes('klips')) handleRedirect(CLIPS_LESSONS_PAGE.replace(':lessonId', videoId));
    else handleRedirect(COACHING_LESSONS_PAGE.replace(':lessonId', videoId));
    getVideo(videoId, endpoint);
  }

  useEffect(() => {
    return () => {
      setVideoProgress(0);
    };
  }, []);

  useEffect(() => {
    if (isLesson && currentVideoTime && videoProgress !== 100 && isCurrent) {
      setVideoProgress((currentVideoTime * 100) / video?.duration);
    }
  }, [currentVideoTime]);

  return {
    isCurrent,
    videoProgress: videoProgress <= 100 ? Math.round(videoProgress) : 100,
    videoId: id,
    firstGradient: videoProgress && 100 !== videoProgress ? `#55C167 ${videoProgress}%` : 'transparent 100%',
    secondGradient: videoProgress && 100 !== videoProgress ? `transparent ${videoProgress}%` : `#55C167 0%`,
    handleLessonClick,
  };
}

export default useCourseVideoDetailsItem;
