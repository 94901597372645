export const SET_IS_LOGIN_MODAL_OPEN = 'SET_IS_LOGIN_MODAL_OPEN';
export const SET_IS_REGISTER_TO_EVENT_MODAL_OPEN = 'SET_IS_REGISTER_TO_EVENT_MODAL_OPEN';
export const SET_IS_CONFIRM_TERMINATE_BOOKING_MODAL_OPEN = 'SET_IS_CONFIRM_TERMINATE_BOOKING_MODAL_OPEN';
export const SET_MY_COURSES = 'SET_MY_COURSES';
export const SET_COURSE = 'SET_COURSE';
export const SET_IS_BURGER_MENU_OPEN = 'SET_IS_BURGER_MENU_OPEN';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS';
export const SET_STATE = 'SET_STATE';
export const SET_IS_LOGOUT_MODAL_OPEN = 'SET_IS_LOGOUT_MODAL_OPEN';
export const SET_IS_PROFILE_DESTROY_MODAL_OPEN = 'SET_IS_PROFILE_DESTROY_MODAL_OPEN';
export const SET_USER = 'SET_USER';
export const SET_VIDEO = 'SET_VIDEO';
export const SET_CURRENT_VIDEO_TIME = 'SET_CURRENT_VIDEO_TIME';
export const SET_VIDEO_DURATION = 'SET_VIDEO_DURATION';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_PROFILE_DROPDOWN_OPEN = 'SET_IS_PROFILE_DROPDOWN_OPEN';
export const SET_SELECTED_PROFILE_ITEM = 'SET_SELECTED_PROFILE_ITEM';
export const SET_START_VIDEO = 'SET_START_VIDEO';
export const SET_IS_COOKIE_BAR_OPEN = 'SET_IS_COOKIE_BAR_OPEN';
export const SET_IS_REGISTRATION_SUCCESS = 'SET_IS_REGISTRATION_SUCCESS';

export const RESET_STATE_TO_DEFAULT = 'RESET_STATE_TO_DEFAULT';

export const UPDATE_VIDEO_NOTES = 'UPDATE_VIDEO_NOTES';

export const SET_COURSES = 'SET_COURSES';

export const SHOW_CANCEL_PAYMENT_MODAL = 'SHOW_CANCEL_PAYMENT_MODAL';

export const SET_PREFERRED_PAYMENT_METHOD = 'SET_PREFERRED_PAYMENT_METHOD';

export const OPEN_MODAL_WITH_REDIRECT = 'OPEN_MODAL_WITH_REDIRECT';
export const OPEN_MODAL_WITHOUT_REDIRECT = 'OPEN_MODAL_WITHOUT_REDIRECT';
