import React from 'react';
import { useTranslation } from 'react-i18next';
import style from 'assets/scss/components/pages/ErrorPage.module.scss';

function ErrorPage() {
  const { wrapper } = style;
  const { t } = useTranslation();

  return (
    <main className="main">
      <div className={wrapper}>
        <h1>{t('error.page.title')}</h1>
        <p>{t('error.page.subtitle')}</p>
      </div>
    </main>
  );
}

export default ErrorPage;
