import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getBookableClipsDetailsApi } from '../api/clipsApi';
import { getBookableCoachesDetailsApi, getBookableModuleDetailsApi } from '../api/coachesApi';
import { SET_IS_LOADING } from '../constants/actionTypes';
import useGeneral from './useGeneral';

function useDetails(id, type, isModule) {
  const [details, setDetails] = useState();
  const { showToasts } = useGeneral();
  const dispatch = useDispatch();

  useEffect(() => {
    GetBookableDetails(id);
  }, []);

  async function GetBookableDetails(id) {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });

      const { data } =
        type === 'clips'
          ? await getBookableClipsDetailsApi(id)
          : isModule
          ? await getBookableModuleDetailsApi(id)
          : await getBookableCoachesDetailsApi(id);

      setDetails(data);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  return { details };
}

export default useDetails;
