import { MY_PROFILE_INITIALS } from 'constants/general';
import { PROFILE_ITEMS } from 'constants/profile';

export function getAvatarLetters(profile) {
  const { firstName, lastName } = profile;

  return firstName && lastName ? firstName.charAt(0) + lastName.charAt(0) : MY_PROFILE_INITIALS;
}

export function getDropdownValuesByProfileItems(t) {
  return JSON.parse(JSON.stringify(PROFILE_ITEMS)).map((item) => {
    item.label = t(item.label);

    return item;
  });
}

export function setSelectedItems(itemSelected, listItems) {
  const items = listItems.map((item) => {
    const { value } = item;
    if (value === itemSelected.value) {
      item.selected = true;
    } else {
      item.selected = false;
    }

    return item;
  });

  return items;
}
