import React from 'react';
import { useTranslation } from 'react-i18next';

import style from 'assets/scss/components/WelcomeCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { MY_PROFILE_PAGE } from '../constants/routes';
import useGeneral from '../hooks/useGeneral';

function WelcomeCard() {
  const { t } = useTranslation();
  const { firstName } = useGeneral();
  const { wrapper, cardTitle } = convertKeysToCamelCase(style);

  return (
    <article className={wrapper}>
      <span className={cardTitle}>{t('dashboard.welcome.card.title', { name: firstName })}</span>
      <a href={MY_PROFILE_PAGE}>{t('dashboard.welcome.card.profile.link')}</a>
    </article>
  );
}

export default WelcomeCard;
