import React from 'react';
import { useTranslation } from 'react-i18next';

import Video from '../Video';
import Image from '../Image';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import CoachingVideoTabs from '../CoachingVideoTabs';
import CourseVideoDetailsAskExpert from '../CourseVideoDetailsAskExpert';
import CourseVideoDetails from '../CourseVideosDetails';
import useCoachingVideo from '../../hooks/useCoachingVideo';
import useVideo from '../../hooks/useVideo';
import Breadcrumbs from './Breadcrumbs';
import {
  COACHING_BREADCRUMBS,
  COACHING_LESSONS_BREADCRUMBS,
  CLIPS_BREADCRUMBS,
  CLIPS_LESSONS_BREADCRUMBS,
} from '../../constants/video';
import CourseVideoDetailsUpcomingTutoriums from '../CourseVideoDetailsUpcomingTutoriums';
import style from '../../assets/scss/components/CoachingVideo.module.scss';
import useGeneral from '../../hooks/useGeneral';
import { COACHING_QUIZ_PAGE } from '../../constants/routes';
import { transformCoachingVideoStrategy } from 'transformers/baseTransformer';
import CourseVideoDetailsPartner from 'components/CourseVideoDetailsPartner';
import VideoDetailsAccordion from 'components/VideoDetailsAccordion';
import VideoDetailsPartner from 'components/VideoDetailsPartner';

function CoachingVideo({ type }) {
  const { video } = useVideo(type);
  const { handleRedirect } = useGeneral();
  const {
    title,
    modules,
    otherVideos,
    shortDescription,
    moduleId,
    upcomingTutoriums,
    quiz,
    topicId,
    topicTitle,
    moduleTitle,
    introductionText,
    moduleHasQuiz,
    isWholeCoachingPurchased,
    partner,
  } = video;
  const { isLesson, paramId, handleUpcomingTutorium, params } = useCoachingVideo();
  const { t } = useTranslation();
  const {
    titleWrapper,
    titleCourse,
    titleLesson,
    videoSection,
    videoSectionShortDescription,
    videoWrapper,
    videoTabs,
    videoDetails,
    quizWrapper,
    quizButton,
    videoTitle,
    partnerWrapper,
    accordionWrapper,
    actionsWrapper,
    wrapper,
    tabsWrapper,
  } = convertKeysToCamelCase(style);

  if (!video) {
    return null;
  }
  const { quizId, quizTitle } = params;
  const lessonId = otherVideos?.map((otherVideo) => otherVideo.id).indexOf(Number(paramId)) + 1;
  const [selectedModuleId, setSelectedModuleId] = React.useState(topicId);

  const breadcrumbsItemsType = () => {
    switch (type) {
      case 'clips':
        return isLesson ? CLIPS_LESSONS_BREADCRUMBS : CLIPS_BREADCRUMBS;
      case 'coachings':
        return isLesson ? COACHING_LESSONS_BREADCRUMBS : COACHING_BREADCRUMBS;
    }
  };

  return (
    <>
      <section className="small max-width">
        <Breadcrumbs
          items={breadcrumbsItemsType()}
          topicId={topicId}
          moduleId={moduleId}
          title={isLesson ? topicTitle : title}
          moduleTitle={moduleTitle}
        />
      </section>
      <section className="small max-width">
        <div className={titleWrapper}>
          <p className={titleCourse}>{isLesson ? topicTitle : title}</p>
          {isLesson && (
            <p className={titleLesson}>
              {quizId
                ? t('video.coaching.details.page.quiz.title')
                : t(`video.${type}.details.page.lesson.number`, { number: lessonId })}
            </p>
          )}
        </div>
      </section>
      <section className={`small no-padding-md ${videoSection} max-width`}>
        <div className={videoWrapper}>
          <h4 className={videoTitle}>{quizTitle || introductionText || title}</h4>
          {quizId && (
            <div className={quizWrapper}>
              <span>{t('video.coaching.details.page.quiz.page.description')}</span>
              <span className={quizButton}>
                <button
                  onClick={() => handleRedirect(COACHING_QUIZ_PAGE.replace(':quizId', quizId))}
                  className="secondary-red"
                >
                  {t('video.coaching.details.page.quiz.button')}
                </button>
              </span>
            </div>
          )}

          {!quizId && (
            <>
              <div>{type === 'clips' && !isLesson ? <Image /> : <Video />}</div>
              <div className={videoTabs}>
                <CoachingVideoTabs
                  isLesson={isLesson}
                  isWholeCoachingPurchased={isWholeCoachingPurchased}
                  type={type}
                />
              </div>
            </>
          )}
        </div>
        <div className={videoDetails}>
          <CourseVideoDetails
            topicId={topicId}
            items={isLesson ? otherVideos : modules}
            type={type}
            isLesson={isLesson}
            paramId={paramId}
            moduleHasQuiz={moduleHasQuiz}
            quiz={quiz}
            quizId={quizId}
            isWholeCoachingPurchased={isWholeCoachingPurchased}
            selectedModuleId={selectedModuleId}
            setSelectedModuleId={setSelectedModuleId}
          />
          {partner && <CourseVideoDetailsPartner />}
          <CourseVideoDetailsUpcomingTutoriums
            upcomingTutoriums={upcomingTutoriums}
            handleUpcomingTutorium={handleUpcomingTutorium}
          />
          <CourseVideoDetailsAskExpert />
        </div>
      </section>
    </>
  );
}

export default CoachingVideo;
