import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import usePayment from 'hooks/usePayment';
import style from '../assets/scss/components/PaymentInformation.module.scss';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { paymentBillingInformationMapper, paymentPersonalInformationMapper } from 'transformers/paymentMapper';
import { BILLING_DATA_KEY, PROFILE_DATA_KEY } from 'constants/payment';
import useHandlePurchase from 'hooks/useHandlePurchase';
import { PAYPAL_BUYER_COUNTRY, PAYPAL_CLIENT_ID, PAYPAL_CURRENCY } from 'constants/envVars';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function PaymentInformation(props) {
  const { t } = useTranslation();
  const { tax, price, coachingDetails, hideCancelModal } = props;
  const { container, wrapper, paymentRow, paymentWrapper, line, summe, forwardButton, title } = convertKeysToCamelCase(
    style,
  );
  const { total, taxCalculator } = usePayment();
  const taxPrice = taxCalculator(price, tax);
  const totalPrice = total(price, tax);

  const params = useParams();
  const moduleId = params.moduleId;

  const { preferredPaymentMethod } = useSelector((store) => store.defaultReducer);

  const [handlePurchase, handleInvoice] = useHandlePurchase(!!moduleId);

  function handlePayPalResponse(purchaseData) {
    hideCancelModal();
    handlePurchase(purchaseData);
  }

  function handlePUI() {
    let invoiceData = {
      itemDetails: coachingDetails,
      userData: {
        ...paymentPersonalInformationMapper(JSON.parse(localStorage.getItem(PROFILE_DATA_KEY))),
        ...paymentBillingInformationMapper(JSON.parse(localStorage.getItem(BILLING_DATA_KEY))),
      },
    };

    hideCancelModal();
    handleInvoice(invoiceData);
  }

  return (
    <div className={container}>
      <noscript>
        <img src="https://c.paypal.com/v1/r/d/b/ns?f=ASDdfgdgf345645sdfgdfgsdfA&s=flowid_provided_to_you&js=0&r=1" />
      </noscript>

      <h2 className={title}>{t('payment.informations.cost')}</h2>
      <div className={wrapper}>
        <div className={paymentWrapper}>
          <div className={paymentRow}>
            <p>{t('payment.informations.coaching')}</p>
            <p>{`${parseFloat(price).toFixed(2)} ${t('euro')}`}</p>
          </div>
          <div className={`${paymentRow}`}>
            <p>{t('payment.informations.tax', { tax })}</p>
            <p>{`${taxPrice} ${t('euro')}`}</p>
          </div>
        </div>
        <div className={line} />
        <div className={paymentWrapper}>
          <div className={`${paymentRow} ${summe}`}>
            <p className={summe}>{t('payment.informations.total')}</p>
            <p className={summe}>{`${totalPrice} ${t('euro')}`}</p>
          </div>

          {preferredPaymentMethod === 'invoice' && (
            <div className={paymentRow}>
              <button className={`primary ${forwardButton}`} onClick={(e) => handlePUI()}>
                Jetzt bezahlen
              </button>
            </div>
          )}

          <PayPalScriptProvider
            options={{
              'client-id': PAYPAL_CLIENT_ID,
              currency: PAYPAL_CURRENCY,
              // 'buyer-country': 'DE',
            }}
          >
            {preferredPaymentMethod == 'paypal' && (
              <PayPalButtons
                fundingSource="paypal"
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: totalPrice,
                        },
                      },
                    ],
                  });
                }}
                onApprove={(data, actions) => {
                  actions.order.capture().then((details) => {
                    let purchaseData = {
                      paypalData: data,
                      itemDetails: coachingDetails,
                      userData: {
                        ...paymentPersonalInformationMapper(JSON.parse(localStorage.getItem(PROFILE_DATA_KEY))),
                        ...paymentBillingInformationMapper(JSON.parse(localStorage.getItem(BILLING_DATA_KEY))),
                      },
                    };
                    handlePayPalResponse(purchaseData);
                  });
                }}
              />
            )}
            {preferredPaymentMethod == 'creditCard' && (
              <PayPalButtons
                fundingSource="card"
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          value: totalPrice,
                        },
                      },
                    ],
                  });
                }}
                onApprove={(data, actions) => {
                  actions.order.capture().then((details) => {
                    let purchaseData = {
                      paypalData: data,
                      itemDetails: coachingDetails,
                      userData: {
                        ...paymentPersonalInformationMapper(JSON.parse(localStorage.getItem(PROFILE_DATA_KEY))),
                        ...paymentBillingInformationMapper(JSON.parse(localStorage.getItem(BILLING_DATA_KEY))),
                      },
                    };
                    handlePayPalResponse(purchaseData);
                  });
                }}
              />
            )}
          </PayPalScriptProvider>
        </div>
      </div>
    </div>
  );
}

export default PaymentInformation;
