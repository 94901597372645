import axios from '../api/axiosConfig';

import {
  COACHES_ALL,
  COACHES_BOOKABLE_BY_CODE,
  COACHES_BOOKABLE_DETAIL,
  PAYPAL_PURCHASE,
  PAYPAL_INVOICE,
  COACHES_BOOKABLE_MODULE_DETAIL,
  PAYPAL_MODULE_INVOICE,
  PAYPAL_MODULE_PURCHASE,
  MODULE_BOOKABLE_BY_CODE,
} from 'constants/routes';

export function getBookableCoachesApi() {
  return axios().get(COACHES_ALL);
}

export function getBookableCoachesDetailsApi(id) {
  return axios().get(COACHES_BOOKABLE_DETAIL.replace(':coachId', id));
}

export function getBookableModuleDetailsApi(id) {
  return axios().get(COACHES_BOOKABLE_MODULE_DETAIL.replace(':moduleId', id));
}

export function bookByCodeApi(code, coachingId) {
  return axios().post(COACHES_BOOKABLE_BY_CODE.replace(':coachingId', coachingId), { code });
}

export function bookModuleByCodeApi(code, moduleId) {
  return axios().post(MODULE_BOOKABLE_BY_CODE.replace(':moduleId', moduleId), { code });
}

export function registerCoachingPurchase(data) {
  return axios().post(PAYPAL_PURCHASE, data);
}

export function registerModulePurchase(data) {
  return axios().post(PAYPAL_MODULE_PURCHASE, data);
}

export function registerCoachingInvoice(data) {
  return axios().post(PAYPAL_INVOICE, data);
}

export function registerModuleInvoice(data) {
  return axios().post(PAYPAL_MODULE_INVOICE, data);
}
