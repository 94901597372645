import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import DOMPurify from 'dompurify';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/VideoTabs.module.scss';
import {
  VIDEO_TAB_DOWNLOADS,
  VIDEO_TAB_FAQ,
  VIDEO_TAB_NOTES,
  VIDEO_TAB_OVERVIEW,
  VIDEO_TABS,
  VIDEO_TABS_ZOOM,
} from '../constants/video';
import useVideoTabs from '../hooks/useVideoTabs';
import VideoTabNotes from './VideoTabNotes';
import VideoTabDownloads from './VideoTabDownloads';

function VideoTabs() {
  const { t } = useTranslation();
  const { wrapper, tabs, tabsActive, tabsContent } = convertKeysToCamelCase(style);
  const { video, selectedIndex, setSelectedIndex } = useVideoTabs();
  const { courseDetails, courseFaq, vimeoSrc, downloads, type } = video;

  const isOverview = VIDEO_TAB_OVERVIEW === selectedIndex;

  return (
    <article className={wrapper}>
      <div className={tabs}>
        {(vimeoSrc ? VIDEO_TABS : VIDEO_TABS_ZOOM).map((tab) => (
          <span
            key={nanoid()}
            className={tab === selectedIndex ? tabsActive : ''}
            onClick={() => setSelectedIndex(tab)}
          >
            {t(`video.details.page.tabs.${tab}`)}
          </span>
        ))}
      </div>
      {(isOverview || VIDEO_TAB_FAQ === selectedIndex) && (
        <div className={tabsContent}>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(isOverview ? courseDetails : courseFaq) }} />
        </div>
      )}

      {VIDEO_TAB_NOTES === selectedIndex && <VideoTabNotes videoType={type} />}
      {VIDEO_TAB_DOWNLOADS === selectedIndex && <VideoTabDownloads downloads={downloads} />}
    </article>
  );
}

export default VideoTabs;
