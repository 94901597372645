import axios from '../api/axiosConfig';
import {
  COURSE_FILTERS_ENDPOINT,
  COURSE_VIDEOS_ENDPOINT,
  CURRENT_VIDEO_ENDPOINT,
  INTERESTED_IN_ENDPOINT,
  PREVIOUSLY_WATCHED_ENDPOINT,
  VIDEO_ENDPOINT,
  VIDEO_FILE_DOWNLOAD,
  COURSE_DATES_ENDPOINT,
  COACHING_VIDEO_PROGRESS_ENDPOINT,
  COACHING_BOOK_UPCOMING_TUTORIUM,
  PURCHASED_COACHING_ENDPOINT,
  PURCHASED_CLIPS_ENDPOINT,
  CLIPS_VIDEO_PROGRESS_ENDPOINT,
} from '../constants/routes';
import { getQueryString } from '../helpers/baseHelper';

export function getVideoApi(id, endpoint) {
  return axios().get((endpoint || VIDEO_ENDPOINT).replace(':id', id));
}

export function createVideoNoteApi(data, path) {
  return axios().post(path, data);
}

export function askExpertApi(path, data) {
  return axios().post(path, data);
}

export function downloadVideoFileApi(hash) {
  const path = `${VIDEO_FILE_DOWNLOAD}?hash=${hash}`;

  return axios().get(path, { responseType: 'blob' });
}

export function getCourseFiltersApi() {
  return axios().get(COURSE_FILTERS_ENDPOINT);
}

export function getCourseVideosApi(params) {
  let path = COURSE_VIDEOS_ENDPOINT;
  if (params) {
    path = getQueryString(COURSE_VIDEOS_ENDPOINT, params);
  }
  return axios().get(path);
}

export function currentVideoApi() {
  return axios().get(CURRENT_VIDEO_ENDPOINT);
}

export function interestedInApi() {
  return axios().get(INTERESTED_IN_ENDPOINT);
}

export function previouslyWatchedApi() {
  return axios().get(PREVIOUSLY_WATCHED_ENDPOINT);
}

export function getPurchasedCoachingsApi() {
  return axios().get(PURCHASED_COACHING_ENDPOINT);
}

export function getPurchasedClipsApi() {
  return axios().get(PURCHASED_CLIPS_ENDPOINT);
}

export function getCourseDatesApi(courseId) {
  return axios().get(COURSE_DATES_ENDPOINT.replace(':id', courseId));
}

export function updateVideoProgressApi(id, progress) {
  return axios().post(COACHING_VIDEO_PROGRESS_ENDPOINT.replace(':id', id), { watched: progress });
}

export function updateVideoClipProgressApi(id, progress) {
  return axios().post(CLIPS_VIDEO_PROGRESS_ENDPOINT.replace(':id', id), { watched: progress });
}

export function bookUpcomingTutoriumApi(id) {
  return axios().post(COACHING_BOOK_UPCOMING_TUTORIUM.replace(':tutoriumId', id));
}
