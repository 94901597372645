import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import style from 'assets/scss/components/ExclusiveServiceCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { ReactComponent as BubblesImage } from 'assets/images/bubbles.svg';

function ExclusiveServiceCard(props) {
  const { items, title, isCoaching } = props;
  const { t } = useTranslation();
  const {
    wrapper,
    wrapperCoaching,
    services,
    servicesItem,
    servicesItemImage,
    servicesItemTitle,
    servicesItemCoaching,
    servicesItemCoachingImage,
  } = convertKeysToCamelCase(style);

  return (
    <section className="primary">
      <article className={`${wrapper} max-width ${isCoaching && wrapperCoaching}`}>
        <h2>{title || t('for.nurses.exclusive.service.title')}</h2>
        <div className={services}>
          {items &&
            items.map((item) => {
              const { title, description, image } = item;

              return (
                <div className={`${servicesItem} ${isCoaching && servicesItemCoaching}`} key={nanoid()}>
                  <div className={`${servicesItemImage} ${isCoaching && servicesItemCoachingImage}`}>
                    {image ? <img src={image} /> : <BubblesImage />}
                  </div>
                  <div>
                    <p className={servicesItemTitle}>{title}</p>
                    <p>{description}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </article>
    </section>
  );
}

export default ExclusiveServiceCard;
