import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container } from 'react-grid-system';

import useGeneral from 'hooks/useGeneral';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from 'assets/scss/components/CoachingModuleEnded.module.scss';
import quizEndingHappy from '../../../assets/images/quiz-ending-happy.svg';
import { topicPageByType, moduleDetailsPageByType } from 'helpers/redirectHelper';

function CoachingModuleEnded({ type = 'coachings' }) {
  const { t } = useTranslation();

  const { handleRedirect } = useGeneral();
  const { container, backButton, imageRow } = convertKeysToCamelCase(style);

  return (
    <main className="main grey">
      <section className="small max-width">
        <div
          onClick={() => {
            handleRedirect(moduleDetailsPageByType(type));
          }}
          className={backButton}
        >
          &#8249; <p>{t('quizzes.backToModule')}</p>
        </div>
        <Container className={`container-fluid ${container}`}>
          <Col>
            <h1>{t('quizzes.ending.succesful.title')}</h1>
            <p>{t('module.ended.success.description')}</p>
            <button
              className={`secondary`}
              type="button"
              onClick={() => {
                handleRedirect(topicPageByType(type));
              }}
            >
              {t(`header.links.${type}`)}
            </button>
          </Col>

          <Col>
            <div className={imageRow}>
              <img src={quizEndingHappy} />
            </div>
          </Col>
        </Container>
      </section>
    </main>
  );
}

export default CoachingModuleEnded;
