import React, { useState } from 'react';
import { Prompt, useParams } from 'react-router';

import StepIndicator from 'components/StepIndicator';
import BackButton from 'components/BackButton';
import PaymentDescriptionComponent from 'components/PaymentDescriptionComponent';
import PaymentInformation from 'components/PaymentInformation';
import BillingPersonalInformation from 'components/BillingPersonalInformation';
import CancelPaymentModal from 'components/CancelPaymentModal';
import useCoachingDetails from 'hooks/useCoachingDetails';
import { COACHING_PAYMENT_SECOND_STEP } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_CANCEL_PAYMENT_MODAL } from 'constants/actionTypes';

function CoachingPaymentThirdPage() {
  const params = useParams();
  const coachId = params.coachId;
  const moduleId = params.moduleId;
  const [isModalVisible, setModalVisible] = useState(false);
  const [prevLocation, setPrevLocation] = useState();
  const { details: coachingDetails } = useCoachingDetails(moduleId || coachId, !!moduleId);

  const dispatch = useDispatch();
  const { showCancelPaymentModal } = useSelector((store) => store.defaultReducer);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };
  if (!coachingDetails) {
    return null;
  }
  const { vat, price } = coachingDetails;

  function hideCancelModal() {
    dispatch({ type: SHOW_CANCEL_PAYMENT_MODAL, payload: false });
  }

  return (
    <main className="main grey max-width">
      <Prompt
        when={showCancelPaymentModal}
        message={(location) => {
          if (!isModalVisible) {
            toggleModal();
            setPrevLocation(location);
          }
          return isModalVisible;
        }}
      />
      <section className="small">
        <BackButton to={COACHING_PAYMENT_SECOND_STEP} onClick={toggleModal} />
      </section>
      <section className="small">
        <StepIndicator currentStep={3} />
      </section>
      <section className="small">
        <PaymentDescriptionComponent
          rightComponent={
            <PaymentInformation
              hideCancelModal={hideCancelModal}
              price={price}
              tax={vat}
              coachingDetails={coachingDetails}
            />
          }
          header={coachingDetails.title}
          description={coachingDetails.paymentDescription}
        />
      </section>
      <section className="small">
        <BillingPersonalInformation />
      </section>
      <CancelPaymentModal
        isVisible={isModalVisible && showCancelPaymentModal}
        onClose={toggleModal}
        location={prevLocation}
      />
    </main>
  );
}

export default CoachingPaymentThirdPage;
