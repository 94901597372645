import React from 'react';
import { useTranslation } from 'react-i18next';

import useVideoDetailsInfoCard from '../hooks/useVideoDetailsInfoCard';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from 'assets/scss/components/VideoDetailsPartner.module.scss';

function VideoDetailsPartner({ type = 'video' }) {
  const { t } = useTranslation();
  const { wrapper } = convertKeysToCamelCase(style);
  const { video, handleSeePartnerVideos } = useVideoDetailsInfoCard();
  const {
    partner: { logo, logo2 },
  } = video;

  return (
    <div className={wrapper}>
      <h4>{t('video.details.page.info.accordion.presentedBy')}</h4>
      <img src={logo} alt="img" />
      {logo2 && <img src={logo2} alt="img" />}
      <span>
        <button className={type === 'clips' ? 'primary-small' : 'primary'} onClick={handleSeePartnerVideos}>
          {t('video.details.page.info.presentedBy.button')}
        </button>
      </span>
    </div>
  );
}

export default VideoDetailsPartner;
