import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import style from 'assets/scss/components/BurgerMenu.module.scss';
import { REGISTER_TYPE, SIGN_IN_TYPE } from '../../constants/login';
import CustomBurgerButton from '../CustomBurgerButton';
import useBurgerMenu from '../../hooks/useBurgerMenu';
import { MobileNav } from './MobileNav';

const BurgerMenu = () => {
  const {
    wrapper,
    menu,
    menuLogin,
    menuButton,
    menuButtonLogin,
    menuLinks,
    headerLinks,
    headerButtonsRegister,
  } = convertKeysToCamelCase(style);
  const {
    isBurgerMenuOpen,
    isAuthenticated,
    isLoginModalOpen,
    buttonIsHidden,
    handleModalOpen,
    handleBurgerMenuOpen,
  } = useBurgerMenu();
  const { t } = useTranslation();

  return (
    <div className={wrapper}>
      <Menu
        isOpen={isBurgerMenuOpen}
        right
        className={`${menu} ${isLoginModalOpen && menuLogin}`}
        onStateChange={(state) => handleBurgerMenuOpen(state.isOpen)}
        customBurgerIcon={false}
        styles={{
          bmMenu: { paddingTop: 20, paddingLeft: 32, paddingRight: 32, paddingBottom: 40, height: '80vh' },
          bmMenuWrap: { background: 'white' },
        }}
      >
        <div className={menuLinks}>
          <MobileNav />
        </div>
        {!isAuthenticated && (
          <span className={headerLinks}>
            <button className="secondary" onClick={() => handleModalOpen(true, SIGN_IN_TYPE)}>
              {t('general.buttons.signIn')}
            </button>
            <button className={`primary ${headerButtonsRegister}`} onClick={() => handleModalOpen(true, REGISTER_TYPE)}>
              {t('general.buttons.register')}
            </button>
          </span>
        )}
      </Menu>
      <div className={`${menuButton} ${buttonIsHidden && menuButtonLogin}`}>
        <CustomBurgerButton
          isOpen={isBurgerMenuOpen}
          isLoginModalOpen={isLoginModalOpen}
          onClick={() => handleBurgerMenuOpen(!isBurgerMenuOpen)}
        />
      </div>
    </div>
  );
};

export default BurgerMenu;
