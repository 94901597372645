import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SET_IS_LOADING } from '../constants/actionTypes';
import { COOKIE_PAGE, ERROR_PAGE } from '../constants/routes';
import { FOOTER_PAGES } from '../constants/general';
import { getPagesApi } from '../api/publicPagesApi';
import useGeneral from './useGeneral';

function useExtraPages() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { showToasts } = useGeneral();
  const [pageData, setPageData] = useState();
  const { pathname } = location;

  async function getPageData(path) {
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const { data } = await getPagesApi(path);

      setPageData(data);
    } catch (error) {
      showToasts(error);
    } finally {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  useEffect(() => {
    const path = pathname.split('/')?.pop();
    if (!FOOTER_PAGES.includes(pathname)) {
      history.push(ERROR_PAGE);

      return;
    }

    getPageData(path);
  }, [pathname]);

  return { pageData, isCookiePage: COOKIE_PAGE === pathname };
}

export default useExtraPages;
