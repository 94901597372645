import React from 'react';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from 'assets/scss/components/CustomBurgerButton.module.scss';

const CustomBurgerButton = (props) => {
  const { onClick, isOpen, isLoginModalOpen } = props;
  const { wrapper, wrapperLogin, wrapperSimple, wrapperOpen } = convertKeysToCamelCase(style);

  return (
    <div
      className={`${wrapper} ${isOpen && wrapperOpen} ${isLoginModalOpen ? wrapperLogin : wrapperSimple}`}
      onClick={onClick}
    >
      <span />
      <span />
      <span />
    </div>
  );
};

export default CustomBurgerButton;
