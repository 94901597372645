import React from 'react';
import { useTranslation } from 'react-i18next';

import usePayment from 'hooks/usePayment';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from '../assets/scss/components/BillingDetailsForm.module.scss';

function PaymentPersonalDataForm() {
  const {
    wrapper,
    inputField,
    plzOrtContainer,
    title,
    requiredInfo,
    errorText,
    button,
    plzContainer,
    ortContainer,
    toggle,
    toggleText,
    billingAddressText,
  } = convertKeysToCamelCase(style);
  const { t } = useTranslation();
  const {
    billingRegister,
    handleBillingSubmit,
    submitBillingRegister,
    billingErrors,
    setIsBillingChecked,
    isBillingChecked,
  } = usePayment();

  return (
    <div className={wrapper}>
      <h2 className={title}>{t('payment.billing.title')}</h2>
      <p className={billingAddressText}>{t('payment.billing.description')}</p>
      <form onSubmit={handleBillingSubmit(submitBillingRegister)}>
        <div className="row">
          <div className="col-12 col-md-8">
            <div className={toggle}>
              <label className="switch">
                <input
                  name="billing"
                  type="checkbox"
                  onChange={() => setIsBillingChecked(!isBillingChecked)}
                  checked={isBillingChecked}
                  value={isBillingChecked}
                />{' '}
                <span className="slider round"></span>
              </label>
              {!isBillingChecked && (
                <p className={toggleText}>Die Rechnungsadresse stimmt mit den persönlichen Daten überein</p>
              )}
              {isBillingChecked && <p className={toggleText}>Geben Sie hier Ihre abweichende Rechnungsadresse ein:</p>}
            </div>

            {isBillingChecked && (
              <div className="row">
                <div className="col-12 col-md-6 ">
                  <div>{t('payment.billing.information.firstName')}</div>
                  <input
                    name="billingFirstName"
                    type="text"
                    className={inputField}
                    placeholder={t('payment.billing.information.firstName.placeholder')}
                    ref={billingRegister}
                  />
                  <div className={errorText}>
                    {billingErrors?.billingFirstName && t(`validation.${billingErrors?.billingFirstName?.message}`)}
                  </div>
                </div>
                <div className="col-12 col-md-6 ">
                  <div>{t('payment.billing.information.lastName')}</div>
                  <input
                    name="billingLastName"
                    className={inputField}
                    type="text"
                    placeholder={t('payment.billing.information.lastName.placeholder')}
                    ref={billingRegister}
                  />
                  <div className={errorText}>
                    {billingErrors?.billingLastName && t(`validation.${billingErrors?.billingLastName?.message}`)}
                  </div>
                </div>
                <div className="col-12 col-md-6 ">
                  <div>{t('payment.billing.information.clinic')}</div>
                  <input
                    name="billingSubject"
                    type="text"
                    className={inputField}
                    placeholder={t('payment.billing.information.clinic.placeholder')}
                    ref={billingRegister}
                  />
                  <div className={errorText}>
                    {billingErrors?.billingSubject && t(`validation.${billingErrors?.billingSubject?.message}`)}
                  </div>
                </div>

                <div className="col-12 col-md-6 ">
                  <div>{t('payment.billing.information.techDept')}</div>
                  <input
                    name="billingEFNNumber"
                    className={inputField}
                    type="text"
                    placeholder={t('payment.billing.information.techDept.placeholder')}
                    ref={billingRegister}
                  />
                  <div className={errorText}>
                    {billingErrors?.billingEFNNumber && t(`validation.${billingErrors?.billingEFNNumber?.message}`)}
                  </div>
                </div>
                <div className="col-12 col-md-6 ">
                  <div>{t('payment.billing.information.email')}</div>
                  <input
                    name="billingEmailAddress"
                    type="email"
                    className={inputField}
                    placeholder={t('payment.billing.information.email.placeholder')}
                    ref={billingRegister}
                  />
                  <div className={errorText}>
                    {billingErrors?.billingEmailAddress &&
                      t(`validation.${billingErrors?.billingEmailAddress?.message}`)}
                  </div>
                </div>
                <div className="col-12 col-md-6 ">
                  <div>{t('payment.billing.information.phone')}</div>
                  <input
                    name="billingPhoneNumber"
                    className={inputField}
                    type="tel"
                    placeholder={t('payment.billing.information.phone.placeholder')}
                    ref={billingRegister}
                  />
                  <div className={errorText}>
                    {billingErrors?.billingPhoneNumber && t(`validation.${billingErrors?.billingPhoneNumber?.message}`)}
                  </div>
                </div>
                <div className="col-12 col-md-6 ">
                  <div>{t('payment.billing.information.address')}</div>
                  <input
                    name="billingStreet"
                    type="text"
                    className={inputField}
                    placeholder={t('payment.billing.information.address.placeholder')}
                    ref={billingRegister}
                  />
                  <div className={errorText}>
                    {billingErrors?.billingStreet && t(`validation.${billingErrors?.billingStreet?.message}`)}
                  </div>
                </div>
                <div className="col-12 col-md-6 ">
                  <div className={plzOrtContainer}>
                    <div className={plzContainer}>
                      <div>{t('payment.billing.information.postalCode')}</div>
                      <input
                        name="billingPostalCode"
                        className={inputField}
                        type="text"
                        placeholder={t('payment.billing.information.postalCode.placeholder')}
                        ref={billingRegister}
                      />
                      <div className={errorText}>
                        {billingErrors?.billingPostalCode &&
                          t(`validation.${billingErrors?.billingPostalCode?.message}`)}
                      </div>
                    </div>
                    <div className={ortContainer}>
                      <div>{t('payment.billing.information.city')}</div>
                      <input
                        name="billingCity"
                        className={inputField}
                        type="text"
                        placeholder={t('payment.billing.information.city.placeholder')}
                        ref={billingRegister}
                      />
                      <div className={errorText}>
                        {billingErrors?.billingCity && t(`validation.${billingErrors?.billingCity?.message}`)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <button className={`secondary-red ${button}`} type="submit">
          {t('payment.step2.forward')}
        </button>
        {!isBillingChecked && (
          <div className={requiredInfo}>
            *<p>{t('payment.requiredInfo')}</p>
          </div>
        )}
      </form>
    </div>
  );
}

export default PaymentPersonalDataForm;
