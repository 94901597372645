import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/ConfirmTerminateBooking.module.scss';
import Modal from './Modal';
import useTerminateBooking from 'hooks/useTerminateBooking';
import { useSelector } from 'react-redux';

function ConfirmTerminateBooking(props) {
  const { isModalOpen, handleClose } = props;
  const { t } = useTranslation();
  const { wrapper, marginTop2, marginBottom2, marginLeft2 } = convertKeysToCamelCase(style);
  const { terminateBooking, closeTerminateBookingModal } = useTerminateBooking();
  const { terminateBookingData } = useSelector((store) => store.defaultReducer);

  if (!isModalOpen) {
    return null;
  }

  const date = terminateBookingData.date;

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleClose}
      content={
        <div className={wrapper}>
          {terminateBookingData.type === 'booked' && (
            <span>
              <h1 className={marginBottom2}>{t('confirmTerminateBookingModal.terminateBooking')}</h1>
              <h4 className={marginTop2}>{t('confirmTerminateBookingModal.confirmText.booking', { date })}</h4>
            </span>
          )}
          {terminateBookingData.type === 'waiting' && (
            <span>
              <h1 className={marginBottom2}>{t('confirmTerminateBookingModal.terminateBooking')}</h1>
              <h4 className={marginTop2}>{t('confirmTerminateBookingModal.confirmText.waitingList', { date })}</h4>
            </span>
          )}
          <div className={marginTop2}>
            <span>
              <button onClick={terminateBooking} className="secondary">
                {t('confirmTerminateBookingModal.terminate')}
              </button>
            </span>
            <span className={marginLeft2}>
              <button onClick={closeTerminateBookingModal} className="primary">
                {t('confirmTerminateBookingModal.cancel')}
              </button>
            </span>
          </div>
        </div>
      }
    />
  );
}

export default ConfirmTerminateBooking;
