import React, { Fragment } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import { Container, Row, Col } from 'react-grid-system';
import { transformCourses, transformPartnerBannerFile, transformPartnerMaterials } from 'transformers/baseTransformer';

import PartnerMedia from 'components/Partner/PartnerMedia';
import PartnerCourseSection from 'components/Partner/PartnerCourseSection';
import PartnerBannerSection from 'components/Partner/PartnerBannerSection';
import PartnerDownloadSection from 'components/Partner/PartnerDownloadSection';
import PartnerDescription from 'components/Partner/PartnerDescription';
import VideoCard from '../VideoCard';
import useIsAuthenticated from 'hooks/useIsAuthenticated';

function PartnerPageDetails(props) {
  const { t } = useTranslation();
  const { isUserLoggedIn } = useIsAuthenticated();

  const {
    logo,
    logo2,
    name,
    courses,
    secondaryTitle,
    introText,
    media,
    mediaType,
    mediaCaption,
    textSection1,
    bannerTitle,
    bannerImage,
    bannerContent,
    bannerFile,
    bannerFileName,
    bannerFileDescription,
    textSection2,
    materialsTitle,
    materialsDescription,
    materials,
    pitchTitle,
    pitchDescription,
    pitchImage,
    angeboten,
    talks,
  } = props;

  const modifiedMaterials = transformPartnerMaterials(materials);
  const download = transformPartnerBannerFile(bannerFileDescription, bannerFileName, bannerFile);
  const transformedCourses = transformCourses(courses);

  return (
    <Fragment key={nanoid()}>
      <div className="partners-page-container">
        <div className="container-fluid">
          <div className="small-container">
            <PartnerDescription
              logo={logo}
              logo2={logo2}
              name={name}
              secondaryTitle={secondaryTitle}
              introText={introText}
            />

            <PartnerMedia media={media} mediaType={mediaType} mediaCaption={mediaCaption} />

            {textSection1 && (
              <Container>
                <Row className="mt50 mb50">
                  <p className="copy" dangerouslySetInnerHTML={{ __html: textSection1 }}></p>
                </Row>
              </Container>
            )}

            <PartnerCourseSection courses={transformedCourses} />
          </div>
        </div>

        {angeboten && [...angeboten.live, ...angeboten.onDemand].length > 0 && (
          <div className="partners-page-container">
            <div className="container-fluid mt120 mb140">
              <div className="container mb20 no-padding">
                <h3>{t('sponsor.detail.page.angebot.title')}</h3>
              </div>
              <Container className="container-fluid">
                <Row>
                  {[...angeboten.live].map((item) => (
                    <Col xs={12} md={4} className="mb20">
                      {/* <VideoCard
                        title={item.title}
                        thumbnail={item.logo}
                        generalInformation={item.shortDescription}
                        id={item.id}
                        type={item.angeboteType}
                        isVertical
                        key={nanoid()}
                      /> */}
                      <VideoCard
                        id={item.firstVideo ? item.firstVideo.id : item.id}
                        title={item.title}
                        generalInformation={item.availability}
                        thumbnail={item.logo}
                        label={item.label}
                        bookingStatus={item.bookingStatus}
                        course={item.firstVideo}
                        courseId={item.id}
                        courseIsLive={1}
                        buttonClass="primary"
                        isSecondary
                        isVertical
                        hasLiveLabel
                        hasFirstVideo
                        showRegisterButton={!isUserLoggedIn}
                        // onButtonClick={() => handleVideoRedirect(item.id, true)}
                      />
                    </Col>
                  ))}
                  {[...angeboten.onDemand].map((item) => (
                    <Col xs={12} md={4} className="mb20">
                      <VideoCard
                        id={item.firstVideo ? item.firstVideo.id : item.id}
                        title={item.title}
                        generalInformation={item.shortDescription}
                        thumbnail={item.logo}
                        label={item.label}
                        buttonClass="secondary"
                        isSecondary
                        isVertical
                        showRegisterButton={!isUserLoggedIn}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
            </div>
          </div>
        )}
      </div>

      {talks && [...talks.live, ...talks.onDemand].length > 0 && (
        <div className="partners-page-container">
          <div className="container-fluid mt120 mb140">
            <div className="container mb20 no-padding">
              <h3>{t('sponsor.detail.page.talks.title')}</h3>
            </div>
            <Container className="container-fluid">
              <Row>
                {[...talks.live].map((item) => (
                  <Col xs={12} md={4} className="mb20">
                    <VideoCard
                      id={item.firstVideo ? item.firstVideo.id : item.id}
                      title={item.title}
                      generalInformation={item.availability}
                      thumbnail={item.logo}
                      label={item.label}
                      bookingStatus={item.bookingStatus}
                      course={item.firstVideo}
                      courseId={item.id}
                      courseIsLive={1}
                      buttonClass="primary"
                      isSecondary
                      isVertical
                      hasLiveLabel
                      hasFirstVideo
                      showRegisterButton={!isUserLoggedIn}
                    />
                  </Col>
                ))}
                {[...talks.onDemand].map((item) => (
                  <Col xs={12} md={4} className="mb20">
                    <VideoCard
                      id={item.firstVideo ? item.firstVideo.id : item.id}
                      title={item.title}
                      generalInformation={item.shortDescription}
                      thumbnail={item.logo}
                      label={item.label}
                      buttonClass="secondary"
                      isSecondary
                      isVertical
                      showRegisterButton={!isUserLoggedIn}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      )}

      <div className="partners-page-container">
        <div className="full-grey-background">
          <div className="container">
            <PartnerBannerSection
              bannerTitle={bannerTitle}
              bannerContent={bannerContent}
              bannerImage={bannerImage}
              bannerFile={bannerFile}
              download={download}
            />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <section>
          <div className="partners-page-container">
            <div className="small-container">
              {textSection2 && (
                <Row className="mt80">
                  <p className="copy" dangerouslySetInnerHTML={{ __html: textSection2 }}></p>
                </Row>
              )}
            </div>
          </div>
        </section>

        <div className="partners-page-container">
          <div className="mt140">
            <div className="container">
              <PartnerBannerSection
                bannerTitle={pitchTitle}
                bannerContent={pitchDescription}
                bannerImage={pitchImage}
              />
            </div>
          </div>
        </div>

        <section>
          <div className="partners-page-container">
            <PartnerDownloadSection
              materialsTitle={materialsTitle}
              materialsDescription={materialsDescription}
              materialsToDownload={modifiedMaterials}
            />
          </div>
        </section>
      </div>
    </Fragment>
  );
}

export default PartnerPageDetails;
