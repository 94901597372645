export function filterRemainingQuestions(answers, wrongAnswers, goodAnswers) {
  return answers
    ?.filter((possibleAnswer) => {
      return !wrongAnswers.find((wrongAnswer) => {
        return possibleAnswer.id === wrongAnswer.id;
      });
    })
    ?.filter((possibleAnswer) => {
      return !goodAnswers.find((correctAnswer) => {
        return possibleAnswer.id === correctAnswer.id;
      });
    });
}
