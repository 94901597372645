import React from 'react';
import * as ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { MODAL_STYLE_BLURY } from '../constants/modal';
import style from '../assets/scss/components/CancelPaymentModal.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import useGeneral from '../hooks/useGeneral';

function CancelPaymentModal(props) {
  const { isVisible, onClose, location } = props;
  const { wrapper, margin, button, buttonContainer, row } = convertKeysToCamelCase(style);
  const { handleRedirect } = useGeneral();
  const { t } = useTranslation();

  return (
    <ReactModal isOpen={isVisible} style={MODAL_STYLE_BLURY}>
      <div className={wrapper}>
        <h2 className={margin}>{t('payment.cancel.modal.title')}</h2>
        <p className={margin}>{t('payment.cancel.modal.description')}</p>
        <div className={`row ${row} ${margin}`}>
          <div className={`col-12 col-sm-6 ${buttonContainer}`}>
            <button className={`secondary-red ${button}`} onClick={onClose}>
              {t('payment.cancel.modal.continue')}
            </button>
          </div>
          <div className={`col-12 col-sm-6 ${buttonContainer}`}>
            <button
              className={`secondary ${button}`}
              onClick={() => {
                handleRedirect(location);
              }}
            >
              {t('payment.cancel.modal.leave')}
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default CancelPaymentModal;
