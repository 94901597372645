import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { OPEN_MODAL_WITHOUT_REDIRECT, OPEN_MODAL_WITH_REDIRECT, SET_STATE } from '../constants/actionTypes';
import { HEADER_LINKS_AUTHENTICATED, HEADER_LINKS_NOT_AUTHENTICATED, MY_PROFILE_INITIALS } from '../constants/general';

function useHeader() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { pathname } = location;
  const { isLoginModalOpen, loginModalType, token, user, isProfileDropdownOpen } = useSelector(
    (store) => store.defaultReducer,
  );
  const { firstName, lastName } = user;

  const handleModalOpen = (isOpen, type, withRedirect = false) => {
    if (withRedirect) {
      dispatch({ type: OPEN_MODAL_WITH_REDIRECT });
    } else {
      dispatch({ type: OPEN_MODAL_WITHOUT_REDIRECT });
    }

    dispatch({
      type: SET_STATE,
      payload: { isBurgerMenuOpen: false, isLoginModalOpen: isOpen, loginModalType: type || '' },
    });
  };

  return {
    pathname,
    isAuthenticated: token,
    isLoginModalOpen,
    loginModalType,
    handleModalOpen,
    initials: firstName && lastName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : MY_PROFILE_INITIALS,
    headerLinks: token ? HEADER_LINKS_AUTHENTICATED : HEADER_LINKS_NOT_AUTHENTICATED,
    avatar: user?.avatar,
    isProfileDropdownOpen,
  };
}

export default useHeader;
