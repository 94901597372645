import awardImage from '../assets/images/award.svg';
import clockImage from '../assets/images/clock-history.svg';
import patchImage from '../assets/images/patch-check.svg';
import feeImage from '../assets/images/kurgebuehr.svg';

export const CoachingBookingComponentData = (props) => {
  const { certificate, requirements, duration, courseFee } = props;
  const elements = [
    { name: 'certificate', image: patchImage, description: certificate },
    { name: 'duration', image: clockImage, description: duration },
    { name: 'requirements', image: awardImage, description: requirements },
    { name: 'courseFee', image: feeImage, description: courseFee },
  ];

  return elements;
};
