import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SET_IS_LOADING } from '../constants/actionTypes';
import {
  currentVideoApi,
  getPurchasedCoachingsApi,
  getPurchasedClipsApi,
  interestedInApi,
  previouslyWatchedApi,
} from '../api/videosApi';
import { transformDashboardVideos, transformDPreviouslyWatchedVideos } from '../transformers/baseTransformer';
import useGeneral from './useGeneral';

function useDashboardPage() {
  const [interestedInVideos, setInterestedInVideos] = useState([]);
  const [previouslyWatchedVideos, setPreviouslyWatchedVideos] = useState([]);
  const [purchasedCoachings, setPurchasedCoachings] = useState([]);
  const [purchasedClips, setpurchasedClips] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);
  const dispatch = useDispatch();
  const { showToasts } = useGeneral();

  async function getInterestedByVideos() {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data } = await interestedInApi();
      const transformedVideos = transformDashboardVideos(data);

      setInterestedInVideos(transformedVideos);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function getPreviouslyWatchedVideos() {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data } = await previouslyWatchedApi();
      const transformedVideos = transformDPreviouslyWatchedVideos(data);

      setPreviouslyWatchedVideos(transformedVideos);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function getPurchasedCoachings() {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data } = await getPurchasedCoachingsApi();

      setPurchasedCoachings(data);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function getpurchasedClips() {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data } = await getPurchasedClipsApi();

      setpurchasedClips(data);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function getCurrentVideo() {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data } = await currentVideoApi();
      if (Array.isArray(data) && !data.length) {
        return;
      }

      setCurrentVideo(data);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  useEffect(() => {
    getCurrentVideo();
    getInterestedByVideos();
    getPreviouslyWatchedVideos();
    getPurchasedCoachings();
    getpurchasedClips();
  }, []);

  return { interestedInVideos, previouslyWatchedVideos, currentVideo, purchasedCoachings, purchasedClips };
}

export default useDashboardPage;
