import React from 'react';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from 'assets/scss/components/CourseDateCard.module.scss';

function CourseDateCard(props) {
  const { isSelected, isWaiting, title, description, handleClick, isAlreadyInterestedIn } = props;
  const {
    wrapper,
    wrapperWaiting,
    wrapperSelected,
    labelWrapper,
    labelWrapperSelected,
    eventWrapper,
    eventDate,
    eventHours,
    eventDateSelected,
    wrapperNoPointerEvents,
    dot,
  } = convertKeysToCamelCase(style);
  const interestedInTitle = 'Du interessierst dich bereits für diesen Kurs';

  return (
    <div
      className={`${wrapper} ${isWaiting && wrapperWaiting} ${isSelected && wrapperSelected} ${
        isAlreadyInterestedIn && wrapperNoPointerEvents
      }`}
      onClick={handleClick}
    >
      <span className={`${labelWrapper} ${isSelected && labelWrapperSelected}`}>
        <label>
          <span className={dot} />
        </label>
      </span>
      <span className={eventWrapper}>
        <span className={`${eventDate} ${isSelected && eventDateSelected}`}>
          {!isAlreadyInterestedIn && title}
          {isAlreadyInterestedIn && interestedInTitle}
        </span>
        <span className={eventHours}>{!isAlreadyInterestedIn && description}</span>
      </span>
    </div>
  );
}

export default CourseDateCard;
