import React, { Fragment } from 'react';
import { nanoid } from 'nanoid';

import useVideoDetailsInfoCard from '../hooks/useVideoDetailsInfoCard';
import style from 'assets/scss/components/VideoDetailsCourses.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';

function VideoDetailsCourses() {
  const { video, getVideo } = useVideoDetailsInfoCard();
  const { otherVideos } = video;
  const { wrapper, courseWrapper } = convertKeysToCamelCase(style);
  if (!otherVideos) {
    return null;
  }

  return (
    <div className={wrapper}>
      {otherVideos.map((video) => {
        const { id, thumbnail, title } = video;

        return (
          <Fragment key={nanoid()}>
            <div className={courseWrapper} onClick={() => getVideo(id)}>
              <figure>
                <img src={thumbnail} alt="img" />
              </figure>
              <span>{title}</span>
            </div>
            <hr />
          </Fragment>
        );
      })}
    </div>
  );
}

export default VideoDetailsCourses;
