import {
  registerCoachingInvoice,
  registerCoachingPurchase,
  registerModuleInvoice,
  registerModulePurchase,
} from 'api/coachesApi';
import { SET_IS_LOADING, SHOW_CANCEL_PAYMENT_MODAL } from 'constants/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import useGeneral from './useGeneral';
import { toast } from 'react-toastify';
import { COACHING_PAYMENT_FOURTH_STEP, MODULE_PAYMENT_FOURTH_STEP } from 'constants/routes';

export default function useHandlePurchase(isModule = false) {
  const dispatch = useDispatch();
  const { handleRedirect, showToasts } = useGeneral();

  const REDIRECT_ROUTE = isModule ? MODULE_PAYMENT_FOURTH_STEP : COACHING_PAYMENT_FOURTH_STEP;

  async function handlePurchase(purchaseData) {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      const { data } = isModule
        ? await registerModulePurchase(purchaseData)
        : await registerCoachingPurchase(purchaseData);

      handleRedirect(REDIRECT_ROUTE);
      toast.success(data?.message);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({ type: SHOW_CANCEL_PAYMENT_MODAL, payload: true });
    }
  }

  async function handleInvoice(purchaseData) {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      const { data } = isModule
        ? await registerModuleInvoice(purchaseData)
        : await registerCoachingInvoice(purchaseData);
      handleRedirect(REDIRECT_ROUTE);
      toast.success(data?.message);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({ type: SHOW_CANCEL_PAYMENT_MODAL, payload: true });
    }
  }

  return [handlePurchase, handleInvoice];
}
