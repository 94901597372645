import React from 'react';
import ReactPlayer from 'react-player';

import style from 'assets/scss/components/Video.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useVideoDetails from '../hooks/useVIdeoDetails';

function Video() {
  const { wrapper, reactPlayer } = convertKeysToCamelCase(style);
  const { video, onDuration, onProgress, handleVideoRef, onPlay, onPause, onEnded } = useVideoDetails();
  const { vimeoSrc, presentationVideo } = video;
  if (!video || (!vimeoSrc && !presentationVideo)) {
    return null;
  }

  return (
    <article className={wrapper}>
      <ReactPlayer
        className={reactPlayer}
        url={vimeoSrc || presentationVideo}
        muted={false}
        controls={true}
        width="100%"
        height="100%"
        onDuration={onDuration}
        onProgress={onProgress}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
        onSeek={onPause} // this is needed for removing the extra process call on seek
        ref={handleVideoRef}
      />
    </article>
  );
}

export default Video;
