import { PAYMENT_METHODS } from 'constants/payment';

export const initialVideo = {
  id: 0,
  title: '',
  duration: null,
  vimeoSrc: '',
  zoomId: '',
  courseDetails: '',
  meetingStatus: 0,
  courseFaq: '',
  downloads: '',
  notes: '',
  otherVideos: [],
  partner: {
    id: null,
    logo: '',
    title: '',
  },
};

export const initialState = {
  isLoginModalOpen: false,
  terminateBookingData: {
    isOpen: false,
    courseId: null,
    type: '',
    date: '',
  },
  myCourses: [],
  isRegisterToEvent: false,
  isRegistrationSuccess: false,
  isBurgerMenuOpen: false,
  isLogoutModalOpen: false,
  isLoading: false,
  loginModalType: '',
  startVideo: {
    startVideoAt: 0,
    change: 0,
  },
  filters: null,
  selectedFilters: {},
  currentVideoTime: 0,
  token: '',
  user: {},
  userProfile: {},
  userAvatar: undefined,
  video: initialVideo,
  isProfileDropdownOpen: false,
  selectedItemType: {},
  isCookieBarOpen: false,
  courses: [],
  showCancelPaymentModal: true,
  preferredPaymentMethod: PAYMENT_METHODS[0].type,
  modalWithRedirect: false,
};
