import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentLayout from 'components/layout/ContentLayout';
import ContactFormCard from 'components/ContactFormCard';
import { Container } from 'react-grid-system';

function ContactPage() {
  const { t } = useTranslation();

  return (
    <ContentLayout title={t('contact.page.title')}>
      <section className="bg-secondary full-width">
        <Container className="container-fluid">
          <ContactFormCard />
        </Container>
      </section>
    </ContentLayout>
  );
}

export default ContactPage;
