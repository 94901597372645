import React from 'react';
import PropTypes from 'prop-types';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from 'assets/scss/components/pages/HomePage.module.scss';
import { Container, Row, Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

const HomeTeamSection = ({ teamMembers }) => {
  const { t } = useTranslation();
  const { teamSection, teamSectionItem, teamSectionName, teamSectionRole } = convertKeysToCamelCase(style);
  return (
    <section className={teamSection}>
      <h2 className="headline2">{t('home.team.title')}</h2>
      <Container className="container-fluid">
        <Row>
          {teamMembers &&
            teamMembers.map(({ name, id, avatar, role }) => (
              <Col xs={12} sm={6} md={3} key={id} className={teamSectionItem}>
                <img src={avatar} alt={name} />
                <p className={teamSectionName}>{name}</p>
                <p className={teamSectionRole}>{role || t('general.labels.team')}</p>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
};

HomeTeamSection.propTypes = {
  teamMembers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default HomeTeamSection;
