import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import checkCircle from '../../../assets/images/check-circle-2.svg';
import style from '../../../assets/scss/components/CoachingCourseContent.module.scss';

function CoachingCourseContent(props) {
  const { texts } = props;
  const { title, row, icon, wrapper } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  return (
    <div className={wrapper}>
      <h2 className={title}>{t('coachings.details.contains.main.title')}</h2>
      {texts.map((text) => (
        <div className={row} key={nanoid()}>
          <img src={checkCircle} alt="image" className={icon} />
          <h4 className="headline4">{text}</h4>
        </div>
      ))}
    </div>
  );
}

export default CoachingCourseContent;
