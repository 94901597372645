import React from 'react';
import { useTranslation } from 'react-i18next';

import PaymentEndButtons from 'components/PaymentEndButtons';
import StepIndicator from 'components/StepIndicator';
import PaymentBaseCard from 'components/PaymentBaseCard';
import style from '../../assets/scss/components/pages/CoachingPaymentFourthPage.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';

function CoachingPaymentFourthPage() {
  const { indicatorContainer } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  return (
    <main className="main grey max-width">
      <section className={`small ${indicatorContainer}`}>
        <StepIndicator currentStep={4} />
      </section>
      <section className="small small-height">
        <PaymentBaseCard title={t('payment.success.title')} content={t('payment.success.description_temporary')} />
      </section>
      <section className="small">
        <PaymentEndButtons />
      </section>
    </main>
  );
}

export default CoachingPaymentFourthPage;
