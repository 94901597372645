import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';
import { useLocation } from 'react-router';
import { getVideoEndpoint } from '../helpers/videoHelper';

import useVideo from './useVideo';
import { SET_CURRENT_VIDEO_TIME, SET_VIDEO_DURATION } from '../constants/actionTypes';
import { updateVideoClipProgressApi, updateVideoProgressApi } from '../api/videosApi';
import useGeneral from './useGeneral';
import {
  COACHING_LESSON_PAGE,
  COACHING_LESSONS_PAGE,
  COACHING_MODULE_ENDED_PAGE,
  CLIPS_LESSON_PAGE,
  CLIPS_LESSONS_PAGE,
  CLIPS_MODULE_ENDED_PAGE,
} from '../constants/routes';
import useCourseVideoDetails from './useCourseVideoDetails';
import { getVideoApi } from '../api/videosApi';

function useVideoDetails() {
  const { video, currentVideoTime, startVideo, handleStartVideoAt, getVideo } = useVideo();
  const { handleQuiz, isQuizEnabled } = useCourseVideoDetails();
  const { showToasts, handleRedirect } = useGeneral();
  const { pathname } = useLocation();
  const { startVideoAt, change } = startVideo;
  const { duration } = video;
  const [time, setTime] = useState('');

  const dispatch = useDispatch();
  const videoPlayer = useRef();
  const videoPlayerProgress = useRef();
  const isLesson = pathname.includes(COACHING_LESSON_PAGE) || pathname.includes(CLIPS_LESSON_PAGE);
  const isClips = pathname.includes(CLIPS_LESSON_PAGE) ? true : false;

  function onDuration(duration) {
    dispatch({ type: SET_VIDEO_DURATION, payload: duration });
  }

  function handleVideoRef(player) {
    videoPlayer.current = player;
  }

  async function updateVideoProgress(duration) {
    try {
      if (isClips) await updateVideoClipProgressApi(video.id, Math.round(duration || currentVideoTime));
      else await updateVideoProgressApi(video.id, Math.round(duration || currentVideoTime));
    } catch (error) {
      showToasts(error);
    }
  }

  function onProgress(progress) {
    const { played } = progress;
    if (!duration) {
      return;
    }

    const secondsElapsed = played * duration;
    if (secondsElapsed !== currentVideoTime) {
      dispatch({ type: SET_CURRENT_VIDEO_TIME, payload: secondsElapsed });
    }
  }

  function onPause() {
    if (videoPlayerProgress.current) {
      clearInterval(videoPlayerProgress.current);
    }
  }

  function onPlay() {
    if (isLesson) {
      videoPlayerProgress.current = setInterval(() => {
        setTime(nanoid());
      }, 10000);
    }
  }

  async function onEnded() {
    if (isLesson) {
      updateVideoProgress(duration);
      const currentVideoIndex = video.otherVideos.findIndex((otherVideo) => otherVideo.id === video.id);
      if (currentVideoIndex < video.otherVideos.length - 1) {
        const nextVideoId = video.otherVideos[currentVideoIndex + 1].id;

        const endpoint = getVideoEndpoint(pathname);
        isClips
          ? handleRedirect(CLIPS_LESSONS_PAGE.replace(':lessonId', nextVideoId))
          : handleRedirect(COACHING_LESSONS_PAGE.replace(':lessonId', nextVideoId));
        getVideo(nextVideoId, endpoint);
        // this was the last video redirecting to quiz now
      } else {
        if (!video.moduleHasQuiz && video.nextLessonId) {
          // the module didn't have a quiz. Redirecting to next module.
          const { data } = await getVideoApi(video.coachingId, 'coaching/booked/:id');
          const { modules } = data;
          const nextModule = modules.find((module) => {
            if (module.videoToPlay && module.videoToPlay !== null && module.videoToPlay.id === video.nextLessonId) {
              return module;
            }
          });
          if (nextModule && !nextModule.canWatchModule) {
            if (isClips) handleRedirect(CLIPS_MODULE_ENDED_PAGE.replace(':moduleId', video.coachingId));
            else handleRedirect(COACHING_MODULE_ENDED_PAGE.replace(':moduleId', video.coachingId));
            return;
          }
          const endpoint = getVideoEndpoint(pathname);
          if (isClips) handleRedirect(CLIPS_LESSONS_PAGE.replace(':lessonId', video.nextLessonId));
          else handleRedirect(COACHING_LESSONS_PAGE.replace(':lessonId', video.nextLessonId));
          getVideo(video.nextLessonId, endpoint);
          return;
        }
        if (isQuizEnabled) {
          handleQuiz(video.quiz);
        }
      }
    }
  }

  useEffect(() => {
    if (time && isLesson) {
      updateVideoProgress();
    }
  }, [time]);

  useEffect(() => {
    if (videoPlayer && videoPlayer.current && change) {
      videoPlayer.current.seekTo(startVideoAt);
    }

    return () => {
      videoPlayer.current = undefined;
    };
  }, [startVideoAt, change]);

  useEffect(() => {
    const { id, otherVideos } = video;
    if (!duration || !otherVideos || !otherVideos.length) {
      return;
    }

    const currentLesson = otherVideos.find((item) => item.id === id);
    if (currentLesson && currentLesson.progress) {
      const currentTime = (duration * currentLesson.progress) / 100;

      handleStartVideoAt(currentTime, true);
    }
  }, [duration]);

  useEffect(() => {
    return () => {
      if (videoPlayerProgress.current) {
        clearInterval(videoPlayerProgress.current);
      }
    };
  }, []);

  return { video, onDuration, onProgress, handleVideoRef, onPlay, onPause, onEnded };
}

export default useVideoDetails;
