import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoWhite } from '../../assets/images/logo-white.svg';
import { ReactComponent as LogoWhiteSmall } from '../../assets/images/logo_text--white.svg';
import ResetPasswordForm from '../ResetPasswordForm';
import useGeneral from '../../hooks/useGeneral';
import { HOME_PAGE } from '../../constants/routes';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import style from 'assets/scss/components/pages/ResetPasswordPage.module.scss';
import Modal from '../Modal';

function ResetPasswordPage() {
  const { wrapper, logoWrapper, header, backButton, logoWrapperLarge, logoWrapperSmall } = convertKeysToCamelCase(
    style,
  );
  const { t } = useTranslation();
  const { handleRedirect } = useGeneral();

  return (
    <main className="main">
      <Modal
        isOpen={true}
        content={
          <article className={wrapper}>
            <div className={header}>
              <div className={logoWrapper}>
                <LogoWhite className={logoWrapperLarge} onClick={() => handleRedirect(HOME_PAGE)} />
                <LogoWhiteSmall className={logoWrapperSmall} onClick={() => handleRedirect(HOME_PAGE)} />
              </div>
            </div>

            <ResetPasswordForm />

            <span onClick={() => handleRedirect(HOME_PAGE)} className={backButton}>
              {t('general.buttons.backToWebPage')}
            </span>
          </article>
        }
      />
    </main>
  );
}

export default ResetPasswordPage;
