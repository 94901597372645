import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import useVideo from './useVideo';
import { PARTNER_PAGE_SINGULAR } from '../constants/routes';

function useVideoDetailsInfoCard() {
  const [activeAccordions, setActiveAccordions] = useState([]);
  const { video, getVideo } = useVideo();
  const history = useHistory();

  function handleSeePartnerVideos() {
    history.push(PARTNER_PAGE_SINGULAR.replace(':id', video.partner.id));
    return;
  }

  function handleActiveAccordions(title) {
    const newItems = [...activeAccordions];
    const index = newItems.indexOf(title);
    if (index > -1) {
      newItems.splice(index, 1);
      setActiveAccordions(newItems);

      return newItems;
    }

    newItems.push(title);
    setActiveAccordions(newItems);
  }

  return { video, activeAccordions, handleActiveAccordions, handleSeePartnerVideos, getVideo };
}

export default useVideoDetailsInfoCard;
