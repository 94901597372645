import React from 'react';

import style from 'assets/scss/components/Image.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useVideoDetails from '../hooks/useVIdeoDetails';

function Image() {
  const { wrapper } = convertKeysToCamelCase(style);
  const { video } = useVideoDetails();
  const { presentationImage } = video;
  if (!video || !presentationImage) {
    return null;
  }

  return (
    <figure className={wrapper}>
      <img src={presentationImage} alt="presentationImage" />
    </figure>
  );
}

export default Image;
