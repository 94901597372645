import React from 'react';
import { useTranslation } from 'react-i18next';
import style from '../../../assets/scss/components/ContactSection.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import useGeneral from 'hooks/useGeneral';
import { Container } from 'react-grid-system';
import cBubbles from '../../../assets/images/c_bubble.svg';
import { FULL_CONTACT_PAGE } from 'constants/routes';

function ContactSection() {
  const { wrapper, title, description, center, image } = convertKeysToCamelCase(style);
  const { t } = useTranslation();
  const { handleRedirect } = useGeneral();

  return (
    <div className={wrapper}>
      <img src={cBubbles} alt="care&coach" className={image} />
      <Container className={center}>
        <h1 className={title}>{t('contact.section.title')}</h1>
        <p className={`highlight ${description}`}>{t('contact.section.description')}</p>
        <button className={`primary`} onClick={() => handleRedirect(FULL_CONTACT_PAGE)}>
          {t('contact.section.button')}
        </button>
      </Container>
    </div>
  );
}

export default ContactSection;
