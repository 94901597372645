import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-dropdown-select';
import { nanoid } from 'nanoid';
import { Container, Row, Col } from 'react-grid-system';

import style from 'assets/scss/components/MyProfile.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import useMyProfile from '../hooks/useMyProfile';
import editImg from '../assets/images/edit.svg';
import { PROFILE_ITEM_TYPES } from 'constants/profile';
import MyProfileCard from './MyProfileCard';
import MyCourseCard from './MyCourseCard';
import BaseCard from './BaseCard';
import useProfileDropdown from 'hooks/useProfileDropdown';
import { useSelector } from 'react-redux';
import ProfileDestroyModal from './ProfileDestroyModal';

function MyProfile() {
  const { isProfileDestroyModalOpen } = useSelector((store) => store.defaultReducer);

  const { t } = useTranslation();
  const {
    initials,
    userAvatar,
    onUploadPhoto,
    listItems,
    handleSelectItem,
    selectedItemType,
    setDisabledInfoCard,
    enabledInfoCard,
    handleProfileDestroyModal,
    handleProfileDestroy,
  } = useMyProfile();
  const {
    wrapper,
    avatar,
    avatarImage,
    avatarEdit,
    profileItems,
    profileItemsSelected,
    dropdownProfileItems,
    wrapperBaseCard,
  } = convertKeysToCamelCase(style);

  const { handleLogout } = useProfileDropdown();

  return (
    <Container className="container-fluid">
      <div className={dropdownProfileItems}>
        <Select
          values={[selectedItemType]}
          options={listItems}
          onChange={(values) => handleSelectItem(values[0])}
          searchable={false}
          placeholder={selectedItemType.label}
        />
      </div>
      {enabledInfoCard && selectedItemType.value === PROFILE_ITEM_TYPES.myProfile && (
        <div className={`${wrapperBaseCard} grey max-width`}>
          <i className="icon icon-close" onClick={() => setDisabledInfoCard(false)} />
          <BaseCard title={t('profile.page.title')} content={t('profile.page.description')} />
        </div>
      )}
      <article className={wrapper}>
        <Row>
          <Col xs={12} md={3}>
            <div className={avatar}>
              <div className={avatarImage}>
                {!userAvatar && <span>{initials}</span>}
                {userAvatar && <img src={userAvatar} alt="avatar" />}
              </div>
              <div className={avatarEdit}>
                <label htmlFor="upload-photo">
                  <img htmlFor="img" src={editImg} alt="edit" />
                </label>
                <input
                  type="file"
                  id="upload-photo"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={(event) => onUploadPhoto(event.target.files)}
                />
              </div>
            </div>
            {listItems.map((item) => {
              const { label, selected } = item;

              return (
                <div
                  className={`${profileItems} ${selected && profileItemsSelected}`}
                  key={nanoid()}
                  onClick={() => handleSelectItem(item)}
                >
                  <span>{label}</span>
                </div>
              );
            })}
            <div className={`${profileItems}`} key={nanoid()}>
              <span onClick={() => handleLogout()}>{t('profile.logout')}</span>
            </div>
            {/* <div className={profileItems} key={nanoid()}>
              <button className={`secondary-red`} onClick={() => handleProfileDestroyModal()}>
                {t('profile.destroy')}
              </button>
            </div> */}
          </Col>
          <Col xs={12} md={9}>
            {selectedItemType.value === PROFILE_ITEM_TYPES.myProfile && <MyProfileCard />}
            {selectedItemType.value === PROFILE_ITEM_TYPES.myCourse && <MyCourseCard />}
          </Col>
        </Row>
      </article>
      {isProfileDestroyModalOpen && (
        <ProfileDestroyModal
          isModalOpen={isProfileDestroyModalOpen}
          handleClose={() => handleProfileDestroyModal(false)}
          handleProfileDestroy={handleProfileDestroy}
        />
      )}
    </Container>
  );
}

export default MyProfile;
