import React from 'react';
import { useTranslation } from 'react-i18next';
import style from 'assets/scss/components/pages/RegistrationConfirmPage.module.scss';
import useHeader from '../../hooks/useHeader';
import { SIGN_IN_TYPE } from 'constants/login';

function RegistrationConfirmedPage() {
  const { wrapper } = style;
  const { t } = useTranslation();
  const { handleModalOpen } = useHeader();

  return (
    <main className="main">
      <div className={wrapper}>
        <h2>{t('register.confirmed.thankyou')}</h2>

        <br></br>

        <p>{t('register.confirmed.lookingforward')}</p>
        <p>{t('register.confirmed.questions')}</p>
        <br />
        <button className="secondary-red" onClick={() => handleModalOpen(true, SIGN_IN_TYPE)}>
          {t('general.buttons.signIn')}
        </button>
      </div>
    </main>
  );
}

export default RegistrationConfirmedPage;
