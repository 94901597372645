import React from 'react';

import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { PARTNER_PAGE_SINGULAR } from 'constants/routes';
import { convertKeysToCamelCase } from 'helpers/baseHelper';

import PartnerCard from './PartnerCard';

import style from '../../assets/scss/components/pages/Cooperations.module.scss';

const PartnerSection = ({ partners, type }) => {
  const { t } = useTranslation();
  const { cooperationsSection, subtitle } = convertKeysToCamelCase(style);

  return (
    <>
      {partners && partners.length > 0 && (
        <div key={type} className={cooperationsSection}>
          <h2 className={`headline2 ${subtitle}`}>{t(`partners.page.subtitle.${type}`)}</h2>
          <Row>
            {partners.map((item) => {
              return (
                <Col xs={12} md={4} key={item.id}>
                  <PartnerCard
                    name={item.name}
                    shortDescription={item.shortDescription}
                    logo={item.logo}
                    to={PARTNER_PAGE_SINGULAR.replace(':id', item.id)}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </>
  );
};

export default PartnerSection;
