import React from 'react';

import useZoomVideo from '../hooks/useZoomVIdeo';
import style from 'assets/scss/components/VideoZoom.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';

function VideoZoom() {
  const { zoomId, leaveUrl, userName, isVisible, meetingStatus, thumbnail } = useZoomVideo();
  const { iframeWrapper, imageWrapper } = convertKeysToCamelCase(style);

  if (!isVisible) {
    return null;
  }

  if (!meetingStatus) {
    return (
      <figure className={imageWrapper}>
        <img src={thumbnail} alt="img" />
      </figure>
    );
  }

  return (
    <div className={iframeWrapper}>
      <iframe
        id="zoom-meet-video"
        src={`https://backend.carencoach.web-staging.eu/zoom/start?zoomId=${zoomId}&leaveUrl=${leaveUrl}&userName=${userName}`}
        frameBorder="0"
      />
    </div>
  );
}

export default VideoZoom;
