import React from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import CourseDateCard from './CourseDateCard';
import {
  TRAINING_PAGE_DATE_BOOK_TYPE,
  TRAINING_PAGE_DATE_INTERESTED_TYPE,
  TRAINING_PAGE_DATE_RESERVE_TYPE,
} from '../constants/general';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from 'assets/scss/components/CourseDateCards.module.scss';

function CourseDateCards(props) {
  const { courseId, dates, selectedDate, setSelectedDate, isAlreadyInterestedIn } = props;
  const { wrapper, coursesWrapper, interestedWrapper } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  return (
    <div className={wrapper}>
      <div className={coursesWrapper}>
        {dates.map((date) => {
          const { id, canBeBooked, canBeBookedForWaiting, startHour, endHour, date: eventDate } = date;
          const isSelected = selectedDate && String(id) === String(selectedDate?.id);
          if (!canBeBooked && !canBeBookedForWaiting) {
            return null;
          }

          return (
            <CourseDateCard
              isWaiting={canBeBookedForWaiting}
              isSelected={isSelected}
              description={t('training.page.courseDates.date.hours', { startHour, endHour })}
              title={
                canBeBookedForWaiting ? t('training.page.courseDates.date.waitingList', { date: eventDate }) : eventDate
              }
              handleClick={() =>
                setSelectedDate({
                  id,
                  type: canBeBookedForWaiting ? TRAINING_PAGE_DATE_RESERVE_TYPE : TRAINING_PAGE_DATE_BOOK_TYPE,
                })
              }
              key={nanoid()}
            />
          );
        })}
      </div>
      <div className={interestedWrapper}>
        <CourseDateCard
          isSelected={courseId && selectedDate?.type === TRAINING_PAGE_DATE_INTERESTED_TYPE}
          title={t('training.page.courseDates.date.interestedIn.title')}
          description={t('training.page.courseDates.date.interestedIn.description')}
          handleClick={() =>
            !isAlreadyInterestedIn ? setSelectedDate({ courseId, type: TRAINING_PAGE_DATE_INTERESTED_TYPE }) : ''
          }
          isAlreadyInterestedIn={isAlreadyInterestedIn}
        />
      </div>
    </div>
  );
}

export default CourseDateCards;
