import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import useYupValidationResolver from './useYupValidationResolver';
import { getProfileFormValidationSchema, getCredentialsFormValidationSchema } from '../helpers/validationSchemaHelper';
import { CREDENTIALS_FORM_DEFAULT_VALUES, PROFILE_FORM_DEFAULT_VALUES } from 'constants/profile';
import {
  updateCredentialsApi,
  getUserProfileApi,
  updateUserProfileApi,
  updateAvatarApi,
  getCoursesApi,
  getCoachingsApi,
  getClipsApi,
  downloadCompressedFileApi,
  destroyProfileApi,
} from 'api/profileApi';
import { getAvatarLetters, getDropdownValuesByProfileItems, setSelectedItems } from 'helpers/profile';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  SET_IS_CONFIRM_TERMINATE_BOOKING_MODAL_OPEN,
  SET_IS_LOADING,
  SET_IS_PROFILE_DESTROY_MODAL_OPEN,
  SET_MY_COURSES,
  SET_SELECTED_PROFILE_ITEM,
  SET_STATE,
  SET_USER,
} from 'constants/actionTypes';
import { VIDEO_PAGE_EXPERT_TALKS, VIDEO_PAGE, COACHING_MODULE_DETAILS_PAGE, HOME_PAGE } from '../constants/routes';
import useGeneral from './useGeneral';
import fileDownload from 'js-file-download';
import { COURSE_TYPE_COUCHING } from '../constants/coaching';

function useMyProfile(stopExectution = false) {
  const { t } = useTranslation();
  const [initials, setInitials] = useState();
  const [coachings, setCoachings] = useState([]);
  const [clips, setClips] = useState([]);
  const [enabledInfoCard, setDisabledInfoCard] = useState(true);
  const [listItems, setItems] = useState(getDropdownValuesByProfileItems(t));
  const resolverProfile = useYupValidationResolver(getProfileFormValidationSchema());

  const {
    register: profileRegister,
    handleSubmit: handleProfileSubmit,
    errors: errorsProfile,
    reset: resetProfile,
  } = useForm({ resolver: resolverProfile, defaultValues: PROFILE_FORM_DEFAULT_VALUES });

  const dispatch = useDispatch();
  const { user, selectedItemType, myCourses } = useSelector((store) => store.defaultReducer);

  const resolverCredentials = useYupValidationResolver(getCredentialsFormValidationSchema());
  const {
    register: credentialsRegister,
    handleSubmit: handleCredentialsSubmit,
    errors: errorsCredentials,
    reset: resetCredentials,
  } = useForm({ resolver: resolverCredentials, defaultValues: CREDENTIALS_FORM_DEFAULT_VALUES });

  const history = useHistory();

  const { showToasts } = useGeneral();

  async function submitProfileForm(formData) {
    try {
      const { data } = await updateUserProfileApi(formData);
      dispatch({ type: SET_USER, payload: data });
      toast.success(t('profile.update.profile.success.message'));
      if (!data.avatar) {
        const initials = getAvatarLetters(data);
        setInitials(initials);
      }
    } catch (error) {
      showToasts(error);
    }
  }

  async function submitCredentialsForm(formData) {
    try {
      await updateCredentialsApi(formData);
      toast.success(t('profile.update.email.success.message'));
    } catch (error) {
      showToasts(error);
    } finally {
      resetCredentials();
    }
  }

  async function getUserProfile() {
    try {
      const { data } = await getUserProfileApi();
      dispatch({ type: SET_USER, payload: data });
      resetProfile(data);
      if (!data.avatar) {
        const initials = getAvatarLetters(data);
        setInitials(initials);
      }
    } catch (error) {
      showToasts(error);
    }
  }

  async function onUploadPhoto(files) {
    if (!files) {
      return;
    }
    try {
      const { data } = await updateAvatarApi(files);
      dispatch({ type: SET_USER, payload: { ...user, avatar: data?.avatar } });
    } catch (error) {
      showToasts(error);
    }
  }

  async function getCourses() {
    try {
      const { data } = await getCoursesApi();
      const coursesWithVideos = data;
      dispatch({ type: SET_MY_COURSES, payload: coursesWithVideos });
    } catch (error) {
      showToasts(error);
    }
  }

  async function getCoachings() {
    try {
      const { data } = await getCoachingsApi();
      setCoachings(data);
    } catch (error) {
      showToasts(error);
    }
  }

  async function getClips() {
    try {
      const { data } = await getClipsApi();
      setClips(data);
    } catch (error) {
      showToasts(error);
    }
  }

  function handleSelectItem(itemSelected) {
    const items = setSelectedItems(itemSelected, listItems);
    dispatch({ type: SET_SELECTED_PROFILE_ITEM, payload: itemSelected });
    setItems(items);
  }

  function handleLastWatchedVideo(course) {
    if (COURSE_TYPE_COUCHING === course?.entity) {
      history.push(COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', course.id));

      return;
    }

    const { lastWatchedVideo, canPlayVideo } = course;
    if (lastWatchedVideo && canPlayVideo) {
      if (lastWatchedVideo.type === 'zoom') {
        history.push(VIDEO_PAGE_EXPERT_TALKS.replace(':id', lastWatchedVideo.id));
      } else {
        history.push(VIDEO_PAGE.replace(':id', lastWatchedVideo.id));
      }
    }
  }

  async function showTerminateBookingModal(course) {
    dispatch({
      type: SET_IS_CONFIRM_TERMINATE_BOOKING_MODAL_OPEN,
      payload: { isOpen: true, courseId: course.id, type: course.courseType, date: course.modalDate },
    });
  }

  async function handleCompressedFileDownload(course) {
    if (!course) {
      return;
    }

    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data } = await downloadCompressedFileApi(course.id);
      if (data.type !== 'application/zip') {
        showToasts(data);
        return;
      }
      fileDownload(data, course.title, 'application/zip');
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({
        type: SET_STATE,
        payload: { isLoginModalOpen: false, isBurgerMenuOpen: false, isLogoutModalOpen: false, isLoading: false },
      });
    }
  }

  function handleProfileDestroyModal(showModal = true) {
    dispatch({ type: SET_IS_PROFILE_DESTROY_MODAL_OPEN, payload: showModal });
  }

  async function handleProfileDestroy() {
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      await destroyProfileApi();

      toast.success(t('profile.destroy.success'));
      dispatch({ type: SET_STATE, payload: { user: {}, token: '' } });
      history.push(HOME_PAGE);
    } catch (error) {
      showToasts(error);
    } finally {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      dispatch({
        type: SET_STATE,
        payload: {
          isLoginModalOpen: false,
          isBurgerMenuOpen: false,
          isLogoutModalOpen: false,
          isLoading: false,
          isProfileDestroyModalOpen: false,
        },
      });
    }
  }

  useEffect(() => {
    if (!stopExectution) {
      getUserProfile();
      getCourses();
      getCoachings();
      getClips();
      handleSelectItem(!selectedItemType?.value ? listItems[0] : selectedItemType);
    }
  }, [stopExectution]);

  return {
    submitCredentialsForm,
    submitProfileForm,
    credentialsRegister,
    profileRegister,
    handleCredentialsSubmit,
    handleProfileSubmit,
    errorsCredentials,
    errorsProfile,
    resetCredentials,
    resetProfile,
    initials,
    userAvatar: user?.avatar,
    onUploadPhoto,
    listItems,
    handleSelectItem,
    selectedItemType,
    enabledInfoCard,
    setDisabledInfoCard,
    courses: myCourses,
    coachings,
    clips,
    handleLastWatchedVideo,
    showTerminateBookingModal,
    handleCompressedFileDownload,
    getCourses,
    handleProfileDestroyModal,
    handleProfileDestroy,
  };
}

export default useMyProfile;
