import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useVideoDetailsAskExpert from '../hooks/useVideoDetailsAskExpert';
import style from 'assets/scss/components/VideoDetailsAskExpert.module.scss';

function CourseVideoDetailsAskExpert() {
  const { t } = useTranslation();
  const { wrapper, form, formTextarea } = convertKeysToCamelCase(style);
  const { register, handleSubmit, errors } = useVideoDetailsAskExpert(true);

  return (
    <div className={wrapper}>
      <h3>{t('video.coaching.details.page.ask')}</h3>
      <form onSubmit={handleSubmit} className={form}>
        <textarea
          className={formTextarea}
          name="question"
          ref={register}
          placeholder={t('video.coaching.details.page.ask.placeholder')}
        />
        {errors?.question && <span className="errors">{t(`validation.${errors?.question?.message}`)}</span>}
        <span>
          <button className="secondary-red" type="submit">
            {t('video.details.page.info.ask.expert.button')}
          </button>
        </span>
      </form>
    </div>
  );
}

export default CourseVideoDetailsAskExpert;
