import React from 'react';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from '../assets/scss/components/PaymentDescriptionComponent.module.scss';

function PaymentDescriptionComponent(props) {
  const { rightComponent, header, description } = props;
  const { container, text, title, wrapper } = convertKeysToCamelCase(style);

  return (
    <div className={container}>
      <div className={wrapper}>
        <div className={text}>
          <h2 className={title}>{header}</h2>
          <p>{description}</p>
        </div>
        {rightComponent && <div>{rightComponent}</div>}
      </div>
    </div>
  );
}

export default PaymentDescriptionComponent;
