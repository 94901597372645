import * as ReactGA from 'react-ga';
import { APP_ENDPOINT, GOOGLE_ANALYTICS_ENDPOINT } from '../constants/envVars';

export function initGA() {
  ReactGA.initialize(GOOGLE_ANALYTICS_ENDPOINT);
  sendPage(window.location.pathname);
}

export function sendPage(name) {
  ReactGA.set({ page: name });
  ReactGA.pageview(APP_ENDPOINT + name);
}
