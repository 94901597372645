import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getPartnersApi } from 'api/partners';
import { SET_IS_LOADING } from 'constants/actionTypes';
import useGeneral from './useGeneral';

function usePartnersByType() {
  const [partners, setPartners] = useState();
  const dispatch = useDispatch();
  const { showToasts } = useGeneral();

  async function getPartners() {
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const { data } = await getPartnersApi();
      setPartners(data);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  useEffect(() => {
    if (!partners) {
      getPartners();
    }
  }, []);

  return { partners };
}

export default usePartnersByType;
