import React from 'react';
import { useForm } from 'react-hook-form';

import useLogin from './useLogin';
import useYupValidationResolver from './useYupValidationResolver';
import { getResetPasswordValidationSchema } from '../helpers/validationSchemaHelper';
import { RESET_PASSWORD_DEFAULT_VALUES } from '../constants/login';

function useResetPassword() {
  const resolver = useYupValidationResolver(getResetPasswordValidationSchema());

  const { register, handleSubmit, errors, reset } = useForm({ resolver, defaultValues: RESET_PASSWORD_DEFAULT_VALUES });
  const { submitResetPassword } = useLogin();

  function handleSubmitForm(data) {
    reset();
    submitResetPassword(data);
  }

  return { handleSubmitForm: handleSubmit(handleSubmitForm), register, errors };
}

export default useResetPassword;
