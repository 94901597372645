import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TOKEN_DATA_KEY, USER_DATA_KEY } from '../constants/login';
import { SET_STATE } from '../constants/actionTypes';

function useAuth() {
  const { token, user } = useSelector((store) => store.defaultReducer);
  const dispatch = useDispatch();
  let mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      const storageUser = localStorage.getItem(USER_DATA_KEY);
      const storageToken = localStorage.getItem(TOKEN_DATA_KEY);
      if (storageToken) {
        dispatch({ type: SET_STATE, payload: { user: JSON.parse(storageUser), token: storageToken } });
      }

      mounted.current = true;

      return;
    }

    if (token) {
      localStorage.setItem(USER_DATA_KEY, JSON.stringify(user));
      localStorage.setItem(TOKEN_DATA_KEY, token);

      return;
    }

    localStorage.removeItem(USER_DATA_KEY);
    localStorage.removeItem(TOKEN_DATA_KEY);
  }, [token, user]);
}

export default useAuth;
