import {
  COACHING_LESSONS_PAGE,
  COACHING_PAGE,
  CLIPS_LESSONS_PAGE,
  CLIPS_PAGE,
  CLIPS_MODULE_DETAILS_PAGE,
  COACHING_MODULE_DETAILS_PAGE,
} from 'constants/routes';

export function lessonsPageByTypeAndVideoId(type, videoId) {
  switch (type) {
    case 'clips':
      return CLIPS_LESSONS_PAGE.replace(':lessonId', videoId);
    case 'coachings':
      return COACHING_LESSONS_PAGE.replace(':lessonId', videoId);
    default:
      return COACHING_LESSONS_PAGE.replace(':lessonId', videoId);
  }
}

export function topicPageByType(type) {
  switch (type) {
    case 'clips':
      return CLIPS_PAGE;
    case 'coachings':
      return COACHING_PAGE;
    default:
      return COACHING_PAGE;
  }
}

export function moduleDetailsPageByType(type) {
  switch (type) {
    case 'clips':
      return CLIPS_MODULE_DETAILS_PAGE.replace(':moduleId', window.location.href.split('/').at(-2));
    case 'coachings':
      return COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', window.location.href.split('/').at(-2));
    default:
      return COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', window.location.href.split('/').at(-2));
  }
}

export function moduleDetailsPageByTypeAndTopicId(type, topicId) {
  switch (type) {
    case 'clips':
      return CLIPS_MODULE_DETAILS_PAGE.replace(':moduleId', topicId);
    case 'coachings':
      return COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', topicId);
    default:
      return COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', topicId);
  }
}
