import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import CoachingPageCollapse from './CoachingPageCollapse';
import style from '../../../assets/scss/components/CoachingDropDown.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';

function CoachingDropDown(props) {
  const { moduleDetails, topicId, type } = props;
  const { wrapper } = convertKeysToCamelCase(style);
  const { t } = useTranslation();

  return (
    <div className={wrapper}>
      <h2>{t('coachings.detail.structure')}</h2>
      {moduleDetails.map((module, index) => (
        <CoachingPageCollapse
          number={index + 1}
          header={module.title}
          moduleId={module.id}
          description={module.description}
          isPayable={Boolean(module.isPayable)}
          isPaidFor={Boolean(module.isPaidFor)}
          topicId={topicId}
          type={type}
          key={nanoid()}
        />
      ))}
    </div>
  );
}

export default CoachingDropDown;
