import React from 'react';
import { useTranslation } from 'react-i18next';

import style from 'assets/scss/components/FiltersSection.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useTrainingFilters from '../hooks/useTrainingFilters';
import MultiSelect from './common/inputs/MultiSelect';
import { Container, Row, Col } from 'react-grid-system';

function FiltersSection() {
  const { t } = useTranslation();
  const { filterSection, filterSectionRow, filterSectionCol } = convertKeysToCamelCase(style);
  const { filters, handleChange, selectedFilters } = useTrainingFilters();

  if (!filters) {
    return null;
  }

  return (
    <article className={filterSection}>
      <Container className="container-fluid">
        <h2>{t('training.page.filters.title')}</h2>
        <Row className={filterSectionRow}>
          {filters.map((filterCategory) => (
            <Col key={filterCategory.id} xs={12} sm={6} md={4} lg={3} className={filterSectionCol}>
              <MultiSelect
                itemsList={filterCategory.children}
                placeholder={filterCategory.name}
                onChange={(values) => handleChange(values, filterCategory.id)}
                selectedValues={selectedFilters[filterCategory.id] ?? []}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </article>
  );
}

export default FiltersSection;
