import React from 'react';
import { useTranslation } from 'react-i18next';
import style from '../../assets/scss/components/PartnerCard.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import useGeneral from 'hooks/useGeneral';

function PartnerCard(props) {
  const { shortDescription, logo, to } = props;
  const { wrapper, seeMoreButton, image, imageWrapper } = convertKeysToCamelCase(style);
  const { t } = useTranslation();
  const { handleRedirect } = useGeneral();

  return (
    <div className={wrapper} onClick={() => handleRedirect(to)}>
      <figure className={imageWrapper}>
        <img src={logo} alt="logo" className={image} />
      </figure>
      <div>
        <p className="copy">{shortDescription}</p>
        <p className={`${seeMoreButton} highlight`} onClick={() => handleRedirect(to)}>
          {t('view.partner')}
        </p>
      </div>
    </div>
  );
}

export default PartnerCard;
