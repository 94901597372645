import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CONTACT_PAGE, DASHBOARD_PAGE, HOME_PAGE } from '../constants/routes';

function useFooterLinks() {
  const history = useHistory();
  const { token, isLoading } = useSelector((store) => store.defaultReducer);
  const [scroll, setScroll] = useState(false);

  function handleFooterLinks(url) {
    if (CONTACT_PAGE === url) {
      history.push(token ? DASHBOARD_PAGE : HOME_PAGE);
      setScroll(true);

      return;
    }

    history.push(url);
  }

  useEffect(() => {
    if (isLoading || !scroll) {
      return;
    }

    setTimeout(() => {
      const [form] = document.getElementsByClassName('contact-form');
      if (form) {
        form.scrollIntoView({ behavior: 'smooth' });
        setScroll(false);
      }
    }, 1000);
  }, [isLoading, scroll]);

  return { handleFooterLinks };
}

export default useFooterLinks;
