import React from 'react';

import CoachingVideo from './CoachingVideo';
import useVideoPage from '../../hooks/useVideoPage';

function CoachingLessonPage({ type = 'coachings' }) {
  useVideoPage();

  return (
    <main className="main white">
      <CoachingVideo type={type} isLesson={true} />
    </main>
  );
}

export default CoachingLessonPage;
