import React from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import { COACHING_LESSON_PAGE, CLIPS_LESSON_PAGE } from '../constants/routes';
import { bookUpcomingTutoriumApi } from '../api/videosApi';
import useGeneral from './useGeneral';
import { getVideoEndpoint } from 'helpers/videoHelper';
import useVideo from './useVideo';

function useCoachingVideo() {
  const { pathname, search } = useLocation();
  const { showToasts } = useGeneral();
  const { getVideo } = useVideo();
  const urlParams = new URLSearchParams(search);

  async function handleUpcomingTutorium(id) {
    try {
      const data = await bookUpcomingTutoriumApi(id);
      let endpoint = getVideoEndpoint(pathname);
      getVideo(pathname.split('/').pop(), endpoint);
      toast.success(data?.data?.message);
    } catch (error) {
      showToasts(error);
    }
  }

  return {
    params: Object.fromEntries(urlParams),
    paramId: pathname.split('/').pop(),
    isLesson: pathname.includes(COACHING_LESSON_PAGE) || pathname.includes(CLIPS_LESSON_PAGE),
    handleUpcomingTutorium,
  };
}

export default useCoachingVideo;
