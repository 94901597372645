import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import { MY_PROFILE_PAGE, COACHING_PAGE } from 'constants/routes';
import style from '../assets/scss/components/PaymentEndButtons.module.scss';
import useGeneral from 'hooks/useGeneral';

function PaymentEndButtons() {
  const { wrapper } = convertKeysToCamelCase(style);
  const { handleRedirect } = useGeneral();
  const { t } = useTranslation();

  return (
    <div className={wrapper}>
      <button
        className="secondary-red"
        onClick={() => {
          handleRedirect(MY_PROFILE_PAGE);
        }}
      >
        {t('payment.end.button.to.courses')}
      </button>

      <button
        className="secondary"
        onClick={() => {
          handleRedirect(COACHING_PAGE);
        }}
      >
        {t('payment.end.button.to.purchase')}
      </button>
    </div>
  );
}

export default PaymentEndButtons;
