import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getBookableCoachesDetailsApi, getBookableModuleDetailsApi } from '../api/coachesApi';
import { SET_IS_LOADING } from '../constants/actionTypes';
import useGeneral from './useGeneral';

function useCoachingDetails(id, isModule) {
  const [coachingDetails, setCoachingDetails] = useState();
  const { showToasts } = useGeneral();
  const dispatch = useDispatch();

  useEffect(() => {
    GetBookableCoachingDetails(id);
  }, []);

  async function GetBookableCoachingDetails(id) {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      const { data } = isModule ? await getBookableModuleDetailsApi(id) : await getBookableCoachesDetailsApi(id);
      setCoachingDetails(data);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  return { details: coachingDetails };
}

export default useCoachingDetails;
