import React from 'react';

import VideoDetailsCard from '../VideoDetailsCard';
import VideoDetailsInfo from '../VideoDetailsInfoCard';
import Video from '../Video';
import VideoZoom from '../VideoZoom';
import useVideoPage from '../../hooks/useVideoPage';
import useVideo from '../../hooks/useVideo';
import VideoDetailsCourses from '../VideoDetailsCourses';
import { useTranslation } from 'react-i18next';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import style from '../../assets/scss/components/VideoDetailsInfo.module.scss';

function VideoPage() {
  useVideoPage();
  const { video } = useVideo();
  const { t } = useTranslation();
  const { wrapperTitleTop } = convertKeysToCamelCase(style);

  return (
    <main className="main white">
      <section className="small max-width">
        <div className={wrapperTitleTop}>
          {!!video?.zoomId && !!video?.meetingStatus && <span>{t('video.card.live.button.label')}</span>}
          <h4>{video.title}</h4>
        </div>
      </section>
      {/* <section className="small">
        <VideoDetailsCard />
      </section> */}
      <section className="small no-padding-md max-width">
        <div className="video-holder">
          <div className={`video ${video?.publiclyAvailable && 'public'}`}>
            <Video />
            <VideoZoom />
          </div>
          {(function () {
            if (video.otherVideos !== undefined && video.otherVideos.length > 0) {
              return (
                <div className="playlist">
                  <p>Weitere Videos zu {video.courseTitle}:</p>
                  <div className="playlist-contents">
                    <VideoDetailsCourses />
                  </div>
                </div>
              );
            }
          })()}
        </div>
      </section>
      {!video.publiclyAvailable && (
        <section className="small max-width">
          <VideoDetailsInfo />
        </section>
      )}
    </main>
  );
}

export default VideoPage;
