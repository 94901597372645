import React from 'react';
import { useTranslation } from 'react-i18next';
import style from 'assets/scss/components/pages/RegistrationConfirmPage.module.scss';

function NewsletterConfirmedPage() {
  const { wrapper } = style;
  const { t } = useTranslation();

  return (
    <main className="main">
      <div className={wrapper}>
        <h2>Vielen Dank für Ihre Anmeldung zum Newsletter der CARE & COACH Akademie!</h2>
        <br></br>
        <p>
          Sie erhalten von nun an regelmäßig Informationen zu aktuellen Schulungen, Coachings und Expertentalks auf der
          CARE & COACH Akademie.
        </p>
      </div>
    </main>
  );
}

export default NewsletterConfirmedPage;
