import React, { Fragment } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import style from 'assets/scss/components/StepIndicator.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import { MAX_STEP_INDICATOR } from 'constants/general';
import image from '../assets/images/check-circle-2.svg';

function StepIndicator(props) {
  const { currentStep } = props;
  const {
    wrapper,
    stepIndicator,
    active,
    stepIcon,
    indicatorLine,
    step,
    dotActive,
    indicatorSideLine,
    indicatorSideLineActive,
    dot,
    dotLeft,
    dotRight,
    icon,
  } = convertKeysToCamelCase(style);

  const { t } = useTranslation();

  return (
    <div className={wrapper}>
      <section className={stepIndicator}>
        <span className={`${dot} ${dotLeft} ${dotActive}`} />
        <div className={`${indicatorSideLine} ${indicatorSideLineActive}`} />

        {Array(MAX_STEP_INDICATOR)
          .fill(0)
          .map((_, realIndex) => {
            const index = realIndex + 1;

            return (
              <Fragment key={nanoid()}>
                <div className={`${step} ${index <= currentStep && active} `} key={nanoid()}>
                  {index < currentStep ? (
                    <img src={image} alt="image" className={icon} />
                  ) : (
                    <div className={stepIcon}>{index}</div>
                  )}
                  <p>{t('payment.stepIndicator.step' + index)}</p>
                </div>
                {MAX_STEP_INDICATOR > index && <div className={`${indicatorLine} ${index < currentStep && active}`} />}
              </Fragment>
            );
          })}
        <div className={`${currentStep == MAX_STEP_INDICATOR && indicatorSideLineActive} ${indicatorSideLine}`} />
        <span className={`${dot} ${dotRight} ${currentStep == MAX_STEP_INDICATOR && dotActive}`} />
      </section>
    </div>
  );
}

export default StepIndicator;
