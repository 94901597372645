import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/LogoutModal.module.scss';
import Modal from './Modal';

function LogoutModal(props) {
  const { isModalOpen, handleClose, handleLogout } = props;
  const { t } = useTranslation();
  const { wrapper, buttonsWrapper } = convertKeysToCamelCase(style);

  if (!isModalOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleClose}
      content={
        <div className={wrapper}>
          <h3>{t('logout.modal.title')}</h3>
          <div className={buttonsWrapper}>
            <button onClick={handleLogout} className="secondary-red">
              {t('logout.modal.yes')}
            </button>
            <button onClick={handleClose}>{t('logout.modal.no')}</button>
          </div>
        </div>
      }
    />
  );
}

export default LogoutModal;
