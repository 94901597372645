import React from 'react';
import { Container } from 'react-grid-system';
import PropTypes from 'prop-types';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from 'assets/scss/components/pages/HomePage.module.scss';
import PartnerMedia from 'components/Partner/PartnerMedia';

const HomeVideoSection = ({ title, description, video }) => {
  const { homeVideoSection } = convertKeysToCamelCase(style);

  return (
    <div className={homeVideoSection}>
      <Container className="container-fluid">
        <h2 className="headline2" style={{ marginBottom: '32px' }}>
          {title}
        </h2>
        <p className="copy" style={{ marginBottom: '32px' }}>
          {description}
        </p>
        <PartnerMedia mediaType="video" media={video} />
      </Container>
    </div>
  );
};

HomeVideoSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  video: PropTypes.string,
};

export default HomeVideoSection;
