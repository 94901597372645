import React from 'react';

import style from '../assets/scss/components/QuizAnswerCard.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';

function QuizAnswerCard(props) {
  const { isCorrect, isUnSelected, answer, isIncorrect, isSelected, isCardSelected, onClick } = props;
  const {
    radio,
    card,
    cardCorrect,
    cardIncorrect,
    cardSelected,
    cardUnselected,
    item,
    itemSelected,
    itemCorrect,
    itemIncorrect,
    description,
    radioContainer,
  } = convertKeysToCamelCase(style);

  return (
    <div
      onClick={onClick}
      className={`${card} ${isCorrect && cardCorrect} ${isIncorrect && cardIncorrect} ${
        isUnSelected && cardUnselected
      } ${isCardSelected && cardSelected}`}
    >
      <div className={radio}>
        <div className={radioContainer}>
          <span
            className={`${item} ${isSelected && itemSelected} ${isCorrect && itemCorrect} ${
              isIncorrect && itemIncorrect
            }`}
          />
        </div>
        <p className={description}>{answer}</p>
      </div>
    </div>
  );
}

export default QuizAnswerCard;
