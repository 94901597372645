import React from 'react';
import { Container, Row } from 'react-grid-system';

function PartnerDescription(props) {
  const { logo, logo2, name, secondaryTitle, introText } = props;

  return (
    <Container>
      <Row className="mt100">
        <figure>
          <img className="company-logo" src={logo} alt="img" />
          {logo2 && <img className="company-logo" src={logo2} alt="img" />}
        </figure>
      </Row>

      {name && (
        <Row>
          <h1 className="headline1 mt60">{name}</h1>
        </Row>
      )}

      {secondaryTitle && (
        <Row className="mt30">
          <p className="highlight" dangerouslySetInnerHTML={{ __html: secondaryTitle }} />
        </Row>
      )}

      {introText && (
        <Row className="mt30">
          <p className="copy" dangerouslySetInnerHTML={{ __html: introText }} />
        </Row>
      )}
    </Container>
  );
}

export default PartnerDescription;
