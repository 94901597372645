import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import useGeneral from './useGeneral';
import { PAYMENT_ENDPOINT } from '../constants/envVars';
import {
  getPaymentProfileFormValidationSchema,
  getPaymentBillingFormValidationSchema,
} from 'helpers/validationSchemaHelper';
import useYupValidationResolver from './useYupValidationResolver';
import { getUserProfileApi } from 'api/profileApi';
import {
  paymentPersonalInformationMapper,
  paymentBillingInformationMapper,
  personalInformationToBillingInformationMapper,
} from 'transformers/paymentMapper';
import {
  PROFILE_DATA_KEY,
  BILLING_DATA_KEY,
  PAYMENT_PROFILE_FORM_DEFAULT_VALUES,
  PAYMENT_BILLING_FORM_DEFAULT_VALUES,
} from 'constants/payment';
import { SET_IS_LOADING } from 'constants/actionTypes';
import {
  COACHING_MODULE_DETAILS_PAGE,
  COACHING_PAYMENT_SECOND_STEP,
  COACHING_PAYMENT_THIRD_STEP,
  MODULE_PAYMENT_SECOND_STEP,
  MODULE_PAYMENT_THIRD_STEP,
} from 'constants/routes';
import { bookByCodeApi, bookModuleByCodeApi } from '../api/coachesApi';
import { useParams } from 'react-router-dom';

function usePayment() {
  const { handleRedirect, showToasts } = useGeneral();
  const dispatch = useDispatch();
  const params = useParams();

  const coachId = params.coachId;
  const moduleId = params.moduleId;

  const [isBillingChecked, setIsBillingChecked] = useState(true);

  useEffect(() => {
    getCurrentProfileData();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const profileResolver = useYupValidationResolver(getPaymentProfileFormValidationSchema());
  const billingResolver = useYupValidationResolver(getPaymentBillingFormValidationSchema(!isBillingChecked));

  const {
    register: paymentProfileRegister,
    handleSubmit: handleProfileSubmit,
    errors: profileErrors,
    reset: resetProfile,
    setValue: setProfileValue,
  } = useForm({ defaultValues: PAYMENT_PROFILE_FORM_DEFAULT_VALUES, resolver: profileResolver });

  const {
    register: billingRegister,
    handleSubmit: handleBillingSubmit,
    errors: billingErrors,
    reset: resetBilling,
  } = useForm({ defaultValues: PAYMENT_BILLING_FORM_DEFAULT_VALUES, resolver: billingResolver });

  async function getCurrentProfileData() {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      const { data } = await getUserProfileApi();

      setProfileValue('personalFirstName', data.firstName);
      setProfileValue('personalLastName', data.lastName);
      setProfileValue('personalEmailAddress', data.email);
      setProfileValue('personalPostalCode', data.postalCode);
      setProfileValue('personalCity', data.city);
      setProfileValue('personalStreet', data.address);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  async function bookByCode(code) {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });

      const { data } = moduleId ? await bookModuleByCodeApi(code, moduleId) : await bookByCodeApi(code, coachId);
      handleRedirect(COACHING_MODULE_DETAILS_PAGE.replace(':moduleId', coachId || moduleId));
      if (data && data.message) {
        toast.success(data?.message);
      }
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  const submitProfileRegister = (form) => {
    const { moreInformation } = form;
    if (moreInformation) {
      bookByCode(moreInformation);

      return;
    }

    localStorage.setItem(PROFILE_DATA_KEY, JSON.stringify(form));
    if (moduleId) {
      handleRedirect(MODULE_PAYMENT_SECOND_STEP.replace(':moduleId', moduleId));
    } else {
      handleRedirect(COACHING_PAYMENT_SECOND_STEP.replace(':coachId', coachId));
    }
  };

  const submitBillingRegister = (form) => {
    resetBilling();
    if (isBillingChecked) {
      localStorage.setItem(BILLING_DATA_KEY, JSON.stringify(form));
    } else {
      const profile = personalInformationToBillingInformationMapper(profileData());
      localStorage.setItem(BILLING_DATA_KEY, JSON.stringify(profile));
    }
    if (moduleId) {
      handleRedirect(MODULE_PAYMENT_THIRD_STEP.replace(':moduleId', moduleId));
    } else {
      handleRedirect(COACHING_PAYMENT_THIRD_STEP.replace(':coachId', coachId));
    }
  };

  const profileData = () => {
    return JSON.parse(localStorage.getItem(PROFILE_DATA_KEY));
  };

  const billingData = () => {
    return JSON.parse(localStorage.getItem(BILLING_DATA_KEY));
  };

  const reditectToPayment = () => {
    const obj = {
      ...paymentPersonalInformationMapper(profileData()),
      ...paymentBillingInformationMapper(billingData()),
      coaching_id: coachId,
    };

    const newParams = new URLSearchParams(obj).toString();
    window.location.replace(`${PAYMENT_ENDPOINT}?${newParams}`);

    localStorage.removeItem(PROFILE_DATA_KEY);
    localStorage.removeItem(BILLING_DATA_KEY);
  };
  const taxCalculator = (price, tax) =>
    parseFloat(parseInt(price) - parseInt(price) / (1 + parseInt(tax) / 100)).toFixed(2);
  const total = (price, tax) => parseFloat(price).toFixed(2);

  return {
    profileRegister: paymentProfileRegister,
    handleProfileSubmit,
    profileErrors,
    resetProfile,
    billingRegister,
    handleBillingSubmit,
    billingErrors,
    resetBilling,
    submitProfileRegister,
    submitBillingRegister,
    profileData: profileData(),
    billingData: billingData(),
    reditectToPayment,
    coachId: coachId,
    taxCalculator,
    total,
    isBillingChecked,
    setIsBillingChecked,
  };
}

export default usePayment;
