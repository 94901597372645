import React from 'react';
import PropTypes from 'prop-types';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from 'assets/scss/components/common/sections/NewsletterSection.module.scss';
import { Container } from 'react-grid-system';
import useStayInformedCard from 'hooks/useStayInformedCard';
import { useTranslation } from 'react-i18next';
import leftImage from 'assets/images/newsletter-element-1.svg';
import rightImage from 'assets/images/newsletter-element-2.svg';

const NewsletterSection = ({ title, description }) => {
  const { t } = useTranslation();
  const {
    newsletterSection,
    emailLabel,
    newsletterSectionLeftImage,
    newsletterSectionRightImage,
  } = convertKeysToCamelCase(style);
  const { register, handleSubmit, errors } = useStayInformedCard();

  return (
    <section className={newsletterSection} id="newsletter">
      <Container className="container-fluid pos-relative">
        <img src={leftImage} alt="left" className={newsletterSectionLeftImage} />
        <img src={rightImage} alt="left" className={newsletterSectionRightImage} />
        <h2 className="headline2">{title}</h2>
        <p className="highlight">{description}</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label for="newsletter-email" className={emailLabel}>
              {t('home.newsletter.label')}
            </label>
            <div className="input-group">
              <input
                id="newsletter-email"
                className="main"
                ref={register}
                placeholder={t('home.newsletter.placeholder')}
                type="text"
                name="email"
              />
              <button className="primary" type="submit">
                {t('general.buttons.signIn')}
              </button>
            </div>

            {errors?.email && <span className="errors">{t(`validation.${errors?.email?.message}`)}</span>}
          </div>
        </form>
      </Container>
    </section>
  );
};

NewsletterSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default NewsletterSection;
