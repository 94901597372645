import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { SET_IS_LOADING } from '../constants/actionTypes';
import { publicPageApi } from '../api/publicPagesApi';
import useGeneral from './useGeneral';

function usePublicPages(endpoint) {
  const [pageData, setPageData] = useState(null);
  const { t } = useTranslation();
  const { showToasts } = useGeneral();
  const dispatch = useDispatch();

  async function getPageData() {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data } = await publicPageApi(endpoint);

      setPageData({ ...data });
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  useEffect(() => {
    getPageData();
  }, []);

  return { ...pageData };
}

export default usePublicPages;
