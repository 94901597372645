import React from 'react';
import { Link } from 'react-router-dom';

import useCookie from '../hooks/useCookie';
import style from 'assets/scss/components/CookieConsent.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

function CookieConsent() {
  const {
    title,
    policy,
    checkBoxes,
    checkedValues,
    description,
    isCookieBarOpen,
    acceptButtonText,
    handleAccept,
    handleInputChange,
  } = useCookie();

  const { text, link } = policy;
  const {
    cookieContainer,
    cookieWrapper,
    textWrapper,
    checkBoxesWrapper,
    consent,
    consentButtons,
    consentPrivacy,
  } = convertKeysToCamelCase(style);

  const { t } = useTranslation();

  if (!isCookieBarOpen) {
    return null;
  }

  return (
    <div className={cookieContainer}>
      <div className={cookieWrapper}>
        <div className={textWrapper}>
          <h2>{t(title)}</h2>
          <span>{t(description)}</span>
        </div>
        <div className={checkBoxesWrapper}>
          {checkBoxes.map((checkBox) => {
            const { text, value, disabled } = checkBox;

            return (
              <span key={nanoid()}>
                <input
                  className={`checkbox-${value}`}
                  type="checkbox"
                  disabled={disabled}
                  checked={!!checkedValues.find((item) => item === value)}
                  onChange={() => handleInputChange(value)}
                />
                <label htmlFor={`checkbox-${value}`}>{t(text)}</label>
              </span>
            );
          })}
        </div>
        <div className={consent}>
          <div className={consentPrivacy}>
            <Link to={link}>{t(text)}</Link>
          </div>
          <div className={consentButtons}>
            <button className="tertiary" onClick={handleAccept}>
              {t(acceptButtonText)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookieConsent;
