import VideoCarouselCard from 'components/VideoCarouselCard';
import { nanoid } from 'nanoid';
import React from 'react';

function PartnerCourseSection(props) {
  const { courses } = props;

  if (!courses || courses.length <= 0) {
    return null;
  }

  return (
    <div className="row mt180">
      <div className="col">
        <div className="carouse-section">
          {courses?.map((course) => {
            const { title, videos } = course;
            return <VideoCarouselCard usedOnCompanyPage={true} title={title} items={videos} key={nanoid()} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default PartnerCourseSection;
