import React from 'react';
import { useTranslation } from 'react-i18next';

import style from 'assets/scss/components/StayInformedCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useStayInformedCard from '../hooks/useStayInformedCard';

function StayInformedCard(props) {
  const { newsletterTitle, newsletterDescription } = props;
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useStayInformedCard();
  const {
    wrapper,
    detailsTitle,
    detailsContent,
    detailsEmail,
    detailsErrBig,
    detailsErrSmall,
  } = convertKeysToCamelCase(style);

  return (
    <article className={`max-width ${wrapper}`}>
      <h2 className={detailsTitle}>{newsletterTitle || t('stay.informed.title')}</h2>
      <p className={detailsContent}>{newsletterDescription || t('stay.informed.content')}</p>
      <span className={detailsErrBig}>
        {errors?.email && <span className="errors">{t(`validation.${errors?.email?.message}`)}</span>}
      </span>
      <form onSubmit={handleSubmit} className={detailsEmail}>
        <input ref={register} placeholder={t('stay.informed.input.placeholder')} type="text" name="email" />
        <span className={detailsErrSmall}>
          {errors?.email && <span className="errors">{t(`validation.${errors?.email?.message}`)}</span>}
        </span>
        <button className="primary" type="submit">
          {t('general.buttons.signIn')}
        </button>
      </form>
    </article>
  );
}

export default StayInformedCard;
