import axios from '../api/axiosConfig';
import {
  FORGOT_PASSWORD_ENDPOINT,
  LOGOUT_ENDPOINT,
  REGISTER_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  SIGN_IN_ENDPOINT,
} from '../constants/routes';

export function registerApi(data) {
  return axios().post(REGISTER_ENDPOINT, data);
}

export function signInApi(data) {
  return axios().post(SIGN_IN_ENDPOINT, data);
}

export function forgotPasswordApi(data) {
  return axios().post(FORGOT_PASSWORD_ENDPOINT, data);
}

export function resetPasswordApi(data) {
  return axios().post(RESET_PASSWORD_ENDPOINT, data);
}

export function logoutApi() {
  return axios().delete(LOGOUT_ENDPOINT);
}
