import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { DASHBOARD_PAGE } from '../constants/routes';
import { TOKEN_DATA_KEY } from '../constants/login';

function PublicRoute(props) {
  const { component: Component, ...rest } = props;
  const token = localStorage.getItem(TOKEN_DATA_KEY);

  return (
    <Route
      {...rest}
      render={(childProps) => (!token ? <Component {...childProps} /> : <Redirect to={DASHBOARD_PAGE} />)}
    />
  );
}

export default PublicRoute;
