import axios from 'axios';

import { API_ENDPOINT } from 'constants/envVars';
import { TOKEN_DATA_KEY } from '../constants/login';

function axiosDefault(reduxToken = '') {
  const token = reduxToken || localStorage.getItem(TOKEN_DATA_KEY);
  if (!token) {
    return axios.create({
      baseURL: API_ENDPOINT,
    });
  }

  return axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      common: {
        Authorization: `Bearer ${token}`,
      },
    },
  });
}

export default axiosDefault;
