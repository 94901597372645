import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from '../assets/scss/components/BackButton.module.scss';
import useGeneral from 'hooks/useGeneral';

function BackButton(props) {
  const { t } = useTranslation();
  const { to } = props;
  const { button } = convertKeysToCamelCase(style);
  const { handleRedirect } = useGeneral();

  return (
    <div
      onClick={() => {
        handleRedirect(to);
      }}
      className={button}
    >
      &#8249; <p>{`${t('back.button')}`}</p>
    </div>
  );
}

export default BackButton;
