import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useVideoDetailsInfoCard from '../hooks/useVideoDetailsInfoCard';
import style from 'assets/scss/components/CourseVideoDetailsAccordion.module.scss';

function CourseVideoDetailsAccordion(props) {
  const { t } = useTranslation();
  const { activeAccordions, handleActiveAccordions } = useVideoDetailsInfoCard();
  const {
    accordionWrapper,
    accordionContent,
    accordionContentShow,
    accordionTitle,
    accordionTitleActive,
  } = convertKeysToCamelCase(style);
  const { title, content } = props;
  const isActive = activeAccordions.includes(title);

  return (
    <div className={accordionWrapper}>
      <div
        className={`${accordionTitle} ${isActive && accordionTitleActive}`}
        onClick={() => handleActiveAccordions(title)}
      >
        <p>{t(`video.details.page.info.accordion.${title}`)}</p>
        <i className="icon icon-right-open-big" />
      </div>
      <div className={`${accordionContent} ${isActive && accordionContentShow}`}>{content}</div>
    </div>
  );
}

export default CourseVideoDetailsAccordion;
