import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/VideoTabNotes.module.scss';
import useVideoTabNotes from '../hooks/useVideoTabNotes';
import useVideo from '../hooks/useVideo';

function VideoTabNotes(props) {
  const { t } = useTranslation();
  const { wrapper, formWrapper, formAction, formError, noteItem, noteItemContent, formButton } = convertKeysToCamelCase(
    style,
  );
  const { notes, register, handleSubmit, errors, currentTime, hideAddNote } = useVideoTabNotes();
  const { handleStartVideoAt } = useVideo();
  const { videoType } = props;

  let addNotePlaceHolder = t('video.tabs.notes.input.placeholder', { currentTime });
  if (videoType == 'vimeo_live') {
    addNotePlaceHolder = t('video.tabs.notes_live.input.placeholder');
  }
  return (
    <div className={wrapper}>
      <h4>{t('video.tabs.notes.title')}</h4>
      {!hideAddNote && (
        <form onSubmit={handleSubmit} className={formWrapper}>
          <div className={formAction}>
            <input name="note" ref={register} placeholder={addNotePlaceHolder} />
            <button className={`primary ${formButton}`} type="submit">
              <i className="icon icon-close" />
            </button>
          </div>
          {errors?.note && <div className={formError}>{t(`validation.${errors?.note?.message}`)}</div>}
        </form>
      )}

      {notes.map((note) => {
        const { timestamp, content } = note;

        return (
          <div className={noteItem} key={nanoid()}>
            {!hideAddNote && timestamp && videoType != 'vimeo_live' && (
              <button className="primary" onClick={() => handleStartVideoAt(timestamp)}>
                {timestamp}
              </button>
            )}
            <span className={hideAddNote ? '' : noteItemContent}>{content}</span>
          </div>
        );
      })}
    </div>
  );
}

export default VideoTabNotes;
