import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGeneral from '../hooks/useGeneral';
import style from 'assets/scss/components/CourseVideosDetailsItem.module.scss';
import CourseVideosDetailsItemProgress from './CourseVideosDetailsItemProgress';
import useCourseVideoDetailsItem from '../hooks/useCourseVideoDetailsItem';
import { lessonsPageByTypeAndVideoId } from 'helpers/redirectHelper';

function CourseVideosDetailsItem(props) {
  const {
    id,
    number,
    title,
    progress,
    canWatchModule,
    videoToPlay,
    isLesson,
    paramId,
    itemIndex,
    isPaidFor,
    isWholeCoachingPurchased,
    selectedModuleId,
    setSelectedModuleId,
    type,
  } = props;
  const { t } = useTranslation();
  const { handleRedirect } = useGeneral();
  const {
    itemDetailsWrapper,
    itemDetailsWrapperSimple,
    itemWrapper,
    itemWrapperCurrent,
    itemWrapperNotavailable,
  } = convertKeysToCamelCase(style);

  const {
    isCurrent,
    firstGradient,
    secondGradient,
    videoId,
    videoProgress,
    handleLessonClick,
  } = useCourseVideoDetailsItem(props);

  return (
    <div
      className={`${itemWrapper} ${selectedModuleId === id && itemWrapperCurrent} ${
        !canWatchModule && !isLesson ? itemWrapperNotavailable : ''
      }`}
      onClick={() =>
        !isLesson && canWatchModule ? setSelectedModuleId(id) : isLesson ? handleLessonClick(id, true) : null
      }
    >
      <div className={`${itemDetailsWrapper} ${!isCurrent && itemDetailsWrapperSimple}`}>
        <CourseVideosDetailsItemProgress
          {...{
            isCurrent,
            itemIndex,
            canWatchModule,
            number,
            title,
            progress,
            isLesson,
            videoId,
            firstGradient,
            secondGradient,
            videoProgress,
            handleLessonClick,
            isPaidFor,
            isWholeCoachingPurchased,
            type,
          }}
        />
      </div>
      <div>
        {!isLesson && selectedModuleId === id && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleRedirect(lessonsPageByTypeAndVideoId(type, videoToPlay.id));
            }}
            className="secondary-red"
          >
            {t('video.coaching.details.page.module.button')}
          </button>
        )}
      </div>
    </div>
  );
}

export default CourseVideosDetailsItem;
