import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SET_IS_LOADING } from '../constants/actionTypes';
import useGeneral from './useGeneral';
import { overviewPageApi } from 'api/publicPagesApi';
import useTrainingFilters from './useTrainingFilters';
import { getQuerySelectedFilters } from 'helpers/baseHelper';

function useOverviewPage(page) {
  const [overviewPage, setOverviewPage] = useState([]);
  const isRegisterToEvent = useSelector((store) => store.defaultReducer.isRegisterToEvent);
  const { selectedFilters, getFilters } = useTrainingFilters();
  const { showToasts } = useGeneral();
  const dispatch = useDispatch();

  useEffect(() => {
    getOverviewPage(getQuerySelectedFilters(selectedFilters));
  }, [isRegisterToEvent, selectedFilters]);

  useEffect(() => {
    getFilters();
  }, []);

  async function getOverviewPage(filters) {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data } = await overviewPageApi(page, filters);
      setOverviewPage(data);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  return { overviewPage };
}

export default useOverviewPage;
