import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import style from '../assets/scss/components/QuizEndingButtons.module.scss';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import { DASHBOARD_PAGE, COACHING_LESSONS_PAGE } from '../constants/routes';
import useGeneral from '../hooks/useGeneral';
import { getVideoApi } from '../api/videosApi';

function QuizEndingButtons(props) {
  const { nextLessonId, isSuccesfull, onRetake, coachingId } = props;
  const { description } = convertKeysToCamelCase(style);
  const { t } = useTranslation();
  const { handleRedirect } = useGeneral();
  const [canViewNextModule, setCanViewNextModule] = useState(false);
  let descriptionText = '';
  let retakeQuiz = '';
  let endQuiz = '';
  let path = '';

  if (!nextLessonId) {
    descriptionText = 'succesful.lastQuiz.description';
    retakeQuiz = 'succesful.lastQuiz.retakeQuiz';
    endQuiz = 'succesful.lastQuiz.endCoaching';
    path = DASHBOARD_PAGE;
  }
  if (nextLessonId) {
    descriptionText = isSuccesfull ? 'succesful.description' : 'unsuccesful.description';
    retakeQuiz = isSuccesfull ? 'succesful.retakeQuiz' : 'unsuccesful.retakeQuiz';
    endQuiz = isSuccesfull ? 'succesful.nextModule' : '';
    path = isSuccesfull ? COACHING_LESSONS_PAGE.replace(':lessonId', nextLessonId) : '';
  }

  const checkNextModule = async () => {
    const { data } = await getVideoApi(coachingId, 'coaching/booked/:id');
    const { modules } = data;
    const nextModule = modules.find((module) => {
      if (module.videoToPlay && module.videoToPlay !== null && module.videoToPlay.id === nextLessonId) {
        return module;
      }
    });
    if (nextModule && nextModule.canWatchModule) {
      setCanViewNextModule(true);
      return;
    }
  };

  useEffect(() => {
    checkNextModule();
  }, [coachingId]);

  return (
    <>
      <p className={description}>{t(`quizzes.ending.${descriptionText}`)}</p>
      <div>
        <button className={`secondary${!isSuccesfull ? '-red' : ''}`} onClick={onRetake}>
          {t(`quizzes.ending.${retakeQuiz}`)}
        </button>
        {isSuccesfull && canViewNextModule && (
          <button
            className="secondary-red"
            onClick={() => {
              handleRedirect(path);
            }}
          >
            {t(`quizzes.ending.${endQuiz}`)}
          </button>
        )}
      </div>
    </>
  );
}

export default QuizEndingButtons;
