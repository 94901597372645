import { COMPANIES_ENDPOINT, COMPANY_DETAIL_ENDPOINT, COMPANY_ENDPOINT, PARTNERS_ENDPOINT } from 'constants/routes';
import axios from './axiosConfig';

export function getCompaniesApi(token) {
  return axios(token).get(COMPANIES_ENDPOINT);
}

export function getPartnerApi(companyId) {
  return axios().get(COMPANY_ENDPOINT.replace(':id', companyId));
}

export function getCompanyApi(companyId) {
  return axios().get(COMPANY_DETAIL_ENDPOINT.replace(':id', companyId));
}

export function getPartnersApi() {
  return axios().get(PARTNERS_ENDPOINT);
}
