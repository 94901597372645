import React from 'react';
import { useTranslation } from 'react-i18next';

import { REGISTER_TYPE, SIGN_IN_TYPE } from '../../constants/login';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import useHeader from 'hooks/useHeader';
import Modal from '../Modal';
import Login from '../Login';
import style from 'assets/scss/components/Header.module.scss';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as LogoSmall } from 'assets/images/logo_text.svg';
import { HOME_PAGE, ONCO_COACH_2025 } from '../../constants/routes';
import BurgerMenu from './BurgerMenu';
import useGeneral from '../../hooks/useGeneral';
import ProfileDropdown from '../ProfileDropdown';
import useProfileDropdown from 'hooks/useProfileDropdown';
import { Container } from 'react-grid-system';
import { HeaderNav } from './HeaderNav';
import useScrollDirection from '../../hooks/useScrollDirection';
import { useLocation } from 'react-router-dom';

function Header() {
  const { t } = useTranslation();
  const {
    headerWrapper,
    headerButtons,
    headerButtonsRegister,
    headerWrapperAuthenticated,
    headerWrapperNotAuthenticated,
    headerLogo,
    headerLogoLarge,
    headerLogoSmall,
    headerLinks: headerLinksStyle,
    profileInitials,
    shadower,
    headerDown,
  } = convertKeysToCamelCase(style);
  const { isAuthenticated, pathname, initials, isLoginModalOpen, handleModalOpen, avatar } = useHeader();
  const { handleProfileDropdownOpen } = useProfileDropdown();
  const { handleRedirect } = useGeneral();
  const { scrollDirection } = useScrollDirection();

  const location = useLocation();

  if (location.pathname === ONCO_COACH_2025) {
    return <></>;
  }

  return (
    <>
      <header
        className={`${headerWrapper} ${shadower} ${scrollDirection === 'down' && headerDown} ${
          isAuthenticated ? headerWrapperAuthenticated : headerWrapperNotAuthenticated
        }`}
      >
        <Container className={`container-fluid`}>
          <nav
            className={`${headerWrapper} ${scrollDirection === 'down' && headerDown} ${
              isAuthenticated ? headerWrapperAuthenticated : headerWrapperNotAuthenticated
            }`}
          >
            <div className={headerLogo} onClick={() => handleRedirect(HOME_PAGE)}>
              <Logo className={headerLogoLarge} />
              <LogoSmall className={headerLogoSmall} />
            </div>
            <div className={headerLinksStyle}>
              <HeaderNav />
            </div>
            {!isAuthenticated && (
              <span className={headerButtons}>
                <button className="secondary" onClick={() => handleModalOpen(true, SIGN_IN_TYPE)}>
                  {t('general.buttons.signIn')}
                </button>
                <button
                  className={`primary ${headerButtonsRegister}`}
                  onClick={() => handleModalOpen(true, REGISTER_TYPE)}
                >
                  {t('general.buttons.register')}
                </button>
              </span>
            )}

            {isAuthenticated && (
              <>
                <div className={profileInitials} onClick={handleProfileDropdownOpen}>
                  {avatar ? <img src={avatar} alt="avatar" /> : <p>{initials}</p>}
                </div>
                <ProfileDropdown />
              </>
            )}
            <BurgerMenu />
          </nav>
        </Container>
      </header>
      {isLoginModalOpen && (
        <Modal path={pathname} isOpen={isLoginModalOpen} onClose={() => handleModalOpen(false)} content={<Login />} />
      )}
    </>
  );
}

export default Header;
