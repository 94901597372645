import React from 'react';
import * as ReactModal from 'react-modal';

import { MODAL_STYLE } from '../constants/modal';

function Modal(props) {
  const { isOpen, onClose, content } = props;

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onClose} style={MODAL_STYLE}>
      {content}
    </ReactModal>
  );
}

ReactModal.setAppElement('body');

export default Modal;
