import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from '../assets/scss/components/QuizEndingComponent.module.scss';
import quizEndingHappy from '../assets/images/quiz-ending-happy.svg';
import quizEndingSad from '../assets/images/quiz-ending-sad.svg';

import QuizEndingButtons from './QuizEndingButtons';

function QuizEndingComponent(props) {
  const { isSuccesfull, goodAnswers, maxAnswers, onRetake, nextLessonId, coachingId } = props;
  const { t } = useTranslation();
  const { wrapper, questionCount, leftContainer } = convertKeysToCamelCase(style);

  return (
    <div className={wrapper}>
      <div className={leftContainer}>
        <h1>{isSuccesfull ? t('quizzes.ending.succesful.title') : t('quizzes.ending.unsuccesful.title')}</h1>
        <h3>
          {isSuccesfull
            ? t('quizzes.ending.succesful.questionCount', { goodAnswers, maxAnswers })
            : t('quizzes.ending.unsuccesful.questionCount', { goodAnswers, maxAnswers })}
        </h3>
        <QuizEndingButtons
          onRetake={onRetake}
          isSuccesfull={isSuccesfull}
          nextLessonId={nextLessonId}
          coachingId={coachingId}
        />
      </div>
      <img src={isSuccesfull ? quizEndingHappy : quizEndingSad} />
    </div>
  );
}

export default QuizEndingComponent;
