import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import DOMPurify from 'dompurify';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/VideoCourseDescription.module.scss';

function VideoCourseDescription(props) {
  const { t } = useTranslation();
  const { longDescription, description, coursePlan, type } = props;
  const {
    wrapper,
    courseTitle,
    courseWrapper,
    planModule,
    planTitle,
    planText,
    courseDescription,
  } = convertKeysToCamelCase(style);

  return (
    <div className={wrapper}>
      <div
        className={courseDescription}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(longDescription || description) }}
      />
      {coursePlan && (
        <div>
          <h3 className={courseTitle}>{t('video.details.page.tabs.courseOverview.coursePlan')}</h3>
          <div className={courseWrapper}>
            {coursePlan.map((plan, index) => {
              const { quizzesCount, title, videosCount, lessonTime } = plan;
              const [hours, minutes] = lessonTime.split(':');

              return (
                <div key={nanoid()}>
                  <p className={planModule}>
                    {t(`video.details.page.tabs.courseOverview.coursePlan.${type}.module`, { id: index + 1 })}
                  </p>
                  <p className={planTitle}>{title}</p>
                  <p className={planText}>
                    {type === 'clips' && videosCount !== 1
                      ? t(`video.details.page.tabs.courseOverview.coursePlan.${type}.lessons.plural`, {
                          count: videosCount,
                          time: `${hours}h ${minutes}m`,
                        })
                      : t(`video.details.page.tabs.courseOverview.coursePlan.${type}.lessons`, {
                          count: videosCount,
                          time: `${hours}h ${minutes}m`,
                        })}
                  </p>
                  {type !== 'clips' && (
                    <p className={planText}>
                      {t('video.details.page.tabs.courseOverview.coursePlan.quiz', { count: quizzesCount })}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoCourseDescription;
