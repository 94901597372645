import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { ReactComponent as LogoWhite } from 'assets/images/logo-white.svg';
import { ReactComponent as LogoWhiteSmall } from 'assets/images/logo_text--white.svg';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { LOGIN_TABS, REGISTER_TYPE, SIGN_IN_TYPE } from '../constants/login';
import RegisterForm from './RegisterForm';
import SignInForm from './SignInForm';
import useHeader from '../hooks/useHeader';
import useBurgerMenu from '../hooks/useBurgerMenu';
import CustomBurgerButton from './CustomBurgerButton';
import useGeneral from '../hooks/useGeneral';
import { HOME_PAGE } from '../constants/routes';
import style from '../assets/scss/components/Login.module.scss';

function Login() {
  const { t } = useTranslation();
  const {
    wrapper,
    wrapperPrimary,
    logoWrapper,
    logoWrapperLarge,
    logoWrapperSmall,
    menuButton,
    tabs,
    header,
    tabsActive,
    backButton,
  } = convertKeysToCamelCase(style);
  const { loginModalType, handleModalOpen } = useHeader();
  const { handleRedirect } = useGeneral();
  const { isBurgerMenuOpen, isLoginModalOpen, handleBurgerMenuOpen } = useBurgerMenu();

  return (
    <article className={`${wrapper} ${loginModalType === REGISTER_TYPE ? wrapperPrimary : ''}`}>
      <div className={header}>
        <div className={logoWrapper}>
          <LogoWhite className={logoWrapperLarge} onClick={() => handleRedirect(HOME_PAGE)} />
          <LogoWhiteSmall className={logoWrapperSmall} onClick={() => handleRedirect(HOME_PAGE)} />
        </div>

        <div className={tabs}>
          {LOGIN_TABS.map((tab) => (
            <span
              key={nanoid()}
              className={tab === loginModalType ? tabsActive : ''}
              onClick={() => handleModalOpen(true, tab)}
            >
              {t(`general.buttons.${tab}`)}
            </span>
          ))}
        </div>
      </div>

      {loginModalType === SIGN_IN_TYPE ? <SignInForm /> : <RegisterForm />}

      <span onClick={() => handleModalOpen(false, loginModalType)} className={backButton}>
        {t('general.buttons.backToWebPage')}
      </span>

      <div className={menuButton}>
        <CustomBurgerButton
          isOpen={isBurgerMenuOpen}
          isLoginModalOpen={isLoginModalOpen}
          onClick={() => handleBurgerMenuOpen(!isBurgerMenuOpen)}
        />
      </div>
    </article>
  );
}

export default Login;
