import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useVideo from './useVideo';
import { getVideoEndpoint } from '../helpers/videoHelper';
import { SET_VIDEO } from '../constants/actionTypes';
import { initialVideo } from '../redux/initialState';

function useVideoPage() {
  const { getVideo } = useVideo();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let endpoint = getVideoEndpoint(pathname);

    getVideo(pathname.split('/').pop(), endpoint);

    return () => {
      dispatch({ type: SET_VIDEO, payload: initialVideo });
    };
  }, []);
}

export default useVideoPage;
