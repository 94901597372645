import React from 'react';

import StepIndicator from 'components/StepIndicator';
import PaymentPersonalDataForm from 'components/PaymentPersonalDataForm';
import BackButton from 'components/BackButton';
import PaymentBaseCard from 'components/PaymentBaseCard';
import { COACHING_PAGE } from 'constants/routes';
import useCoachingDetails from 'hooks/useCoachingDetails';
import { useParams } from 'react-router-dom';

function CoachingPaymentFirstPage() {
  const params = useParams();
  const coachId = params.coachId;
  const moduleId = params.moduleId;
  const { details: coachingDetails } = useCoachingDetails(moduleId || coachId, !!moduleId);

  if (!coachingDetails) {
    return null;
  }

  return (
    <main className="main grey max-width">
      <section className="small">
        <BackButton to={COACHING_PAGE} />
      </section>
      <section className="small">
        <StepIndicator currentStep={1} />
      </section>
      <section className="small">
        <PaymentBaseCard title={coachingDetails.title} content={coachingDetails.paymentDescription} />
      </section>
      <section className="small">
        <PaymentPersonalDataForm />
      </section>
    </main>
  );
}

export default CoachingPaymentFirstPage;
