import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useRegisterToEvent from '../hooks/useRegisterToEvent';
import CourseDateCards from './CourseDateCards';
import style from '../assets/scss/components/RegisterToEventForm.module.scss';

function RegisterToEventForm() {
  const {
    course,
    register,
    handleSubmit,
    errors,
    selectedDate,
    setSelectedDate,
    subscribedToNewsletter,
  } = useRegisterToEvent();

  const { t } = useTranslation();
  const {
    formError,
    buttonsWrapper,
    courseScheduleTitle,
    courseScheduleDescription,
    codeInput,
    codeTitle,
    mandatoryField,
    datesWrapper,
    noCoursesWrapper,
    checkboxAcceptance,
  } = convertKeysToCamelCase(style);

  if (!course || !course.dates.length) {
    return <h3 className={noCoursesWrapper}>{t('training.page.courseDates.noCourse')}</h3>;
  }
  const { id, dates, isAlreadyInterestedIn } = course;

  const [isNewsletterChecked, setIsNewsletterChecked] = React.useState(false);
  const [isAcceptanceChecked, setIsAcceptanceChecked] = React.useState(false);

  return (
    <form onSubmit={handleSubmit}>
      <h3 className={courseScheduleTitle}>{t('training.page.courseDates.schedule.title')}</h3>
      <p className={courseScheduleDescription}>{t('training.page.courseDates.schedule.description')}</p>

      <div>
        {!!dates.length && (
          <div className={datesWrapper}>
            <CourseDateCards
              dates={dates}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              courseId={id}
              isAlreadyInterestedIn={isAlreadyInterestedIn}
            />
            <input type="hidden" name="date" ref={register} value={selectedDate?.id || selectedDate?.courseId || ''} />
            {errors?.date && <label className={formError}>{t(`validation.${errors?.date?.message}`)}</label>}
          </div>
        )}
        <p className={codeTitle}>{t('training.page.courseDates.code.title')}</p>
        <span className={codeInput}>
          <span>
            <input name="code" placeholder={t('training.page.courseDates.code.placeholder')} ref={register} />
          </span>
          {errors?.code && <label className={formError}>{t(`validation.${errors?.code?.message}`)}</label>}
        </span>
        {!subscribedToNewsletter && (
          <div className={checkboxAcceptance}>
            <input
              id="subscribedToNewsletter"
              name="subscribedToNewsletter"
              type="checkbox"
              onChange={() => setIsNewsletterChecked(!isNewsletterChecked)}
              checked={isNewsletterChecked}
              value={isNewsletterChecked}
              ref={register}
            />
            <label for="subscribedToNewsletter">{t('training.page.courseDates.schedule.newsletter')}</label>
          </div>
        )}
        <div className={checkboxAcceptance}>
          <input
            id="acceptance"
            name="acceptance"
            type="checkbox"
            onChange={() => setIsAcceptanceChecked(!isAcceptanceChecked)}
            checked={isAcceptanceChecked}
            value={isAcceptanceChecked}
          />
          <label for="acceptance">{t('training.page.courseDates.schedule.accept')}</label>
        </div>

        <div className={buttonsWrapper}>
          <button className="primary" type="submit" disabled={!isAcceptanceChecked}>
            {t('training.page.courseDates.button')}
          </button>
          <p className={mandatoryField}>{t('training.page.courseDates.mandatory.field')}</p>
        </div>
      </div>
    </form>
  );
}

export default RegisterToEventForm;
