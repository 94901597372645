import React from 'react';

import style from 'assets/scss/components/PresentationOverview.module.scss';
import { convertKeysToCamelCase } from '../../../helpers/baseHelper';
import { useTranslation } from 'react-i18next';
import { REGISTER_TYPE } from 'constants/login';
import useHeader from 'hooks/useHeader';
import { Container, Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';

function PresentationOverview({
  title,
  description,
  thumbnail,
  topComponent,
  hideButton,
  buttonText,
  buttonClass,
  onButtonClick,
}) {
  const { t } = useTranslation();
  const { handleModalOpen } = useHeader();
  const { presentationWrapper, presentationWrapperDescription } = convertKeysToCamelCase(style);

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    handleModalOpen(true, REGISTER_TYPE, true);
  };

  return (
    <section className={presentationWrapper}>
      <Container className="container-fluid">
        <Row>
          <Col xs={12} md={6}>
            {topComponent}
            <h2 className="headline2">{title}</h2>
            <p className={`highlight ${presentationWrapperDescription}`}>{description}</p>
            {!hideButton && (
              <button className={buttonClass || 'primary'} onClick={handleButtonClick}>
                {buttonText || t('general.buttons.register_now')}
              </button>
            )}
          </Col>
          <Col xs={12} md={6}>
            <img src={thumbnail} alt="image" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

PresentationOverview.propTypes = {
  topComponent: PropTypes.element,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  hideButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonClass: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default PresentationOverview;
